import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { Table } from 'common/components';
import { time } from 'common/functions';
import { Action, State } from './CtLabelHistoryTable';
import Expand from './expand/Expand';
import './HistoryTable.scss';

const log_type = {
  1: '라벨이 생성 되었습니다',
  2: '라벨 정보가 수정 되었습니다',
};

interface Props {
  state: State;
  setState: Dispatch<Action>;
}
function HistoryTable({ state, setState }: Props) {
  const { data, header } = state;

  return (
    <section id="root-eddra-label-history">
      <Table data={data} header={header}>
        <Table.Head>{({ value }) => value.title}</Table.Head>
        <Table.Body>
          {({ index }) => {
            const rowData = data[index];
            return (
              <Table.Row key={index} styles={{ height: rowData.expand ? 'auto' : 56 }}>
                <Table.Tr
                  index={index}
                  style={{ height: 56, cursor: 'pointer' }}
                  onClick={() => setState({ type: 'expand', payload: index })}
                >
                  {({ value, property }) => {
                    if (property === 'label_name') return <b>{value}</b>;
                    if (property === 'log_type') return log_type[rowData[property]];
                    if (property === 'created_at') {
                      const epoch = new Date(value).getTime();
                      return time.timeFormatFromUTCEpoch(epoch, 2);
                    }
                    if (property === 'expand') {
                      return <i className={classNames('expand-icon', { expanded: rowData.expand })} />;
                    }
                    return value || '-';
                  }}
                </Table.Tr>
                {rowData.expand && <Expand rowData={rowData} />}
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
    </section>
  );
}

export default HistoryTable;
