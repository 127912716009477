import { M_Table } from 'common/components';
import { api, process } from 'common/functions';
import { useApiCancel } from 'common/hooks';
import { produce } from 'immer';
import { ContentContext } from 'layout/root/content/Content';
import type { Pagination } from 'models';
import React, { useContext, useEffect, useReducer } from 'react';
import PolicyTable from './PolicyTable';

const initPagination = { info: { current: 1, total: 1 }, format: { limit: 10, offset: 0 } };
export type Data = { id: number; key: string; expand: boolean };
export type Action =
  | { type: 'setData'; payload: Data[] }
  | { type: 'expand'; payload: number }
  | { type: 'pagination'; payload: Pagination };

export interface State {
  data: Data[];
  header: M_Table.header[];
  pagination: Pagination;
}

const initialState: State = {
  data: [],
  pagination: { ...initPagination },
  header: [
    { title: '약관 key', property: 'key', flex: 8 },
    { title: '상세보기', property: 'expand', align: 'center' },
  ],
};

// prettier-ignore
const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'setData': draft['data'] = action.payload; break;
      case 'expand': draft.data[action.payload]['expand'] = !draft.data[action.payload].expand; break;
      case 'pagination': draft['pagination'] = action.payload; break;
    }
  });
};

const CtPolicyTable: React.FC = () => {
  useApiCancel(['guides']);
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = () => {
    setLoading(true);
    process(
      api.reqData({ url: 'guides' }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { list } = response.data;
          const payload = list
            .map((el: Omit<Data, 'expand'>) => ({ ...el, expand: false }))
            .filter((el: Data) => el.key.includes('-pp-'));
          setState({ type: 'setData', payload });
          setState({ type: 'pagination', payload: api.setTotal(state.pagination, payload.length) });
        }
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return <PolicyTable state={state} setState={setState} loadHandler={loadHandler} />;
};

export default CtPolicyTable;
