import React from 'react';
import Loading from 'assets/Loading_40px.svg';
import './Loading.scss';

export const KycLoading: React.FC = (): JSX.Element => {
  return (
    <div className="kyc__loading">
      <div className="kyc__loading__back" />
      <div className="loading__content__wrap">
        <div className="loading__content">
          <img className="loading__dots" src={Loading} width="35px" alt="loading" />
          <p>잠시만 기다려주세요.</p>
          <p>요청하신 작업을 처리 중 입니다.</p>
        </div>
      </div>
    </div>
  );
};
