import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api, process } from 'common/functions';
import { Root } from 'models';
import { NavigateFunction } from 'react-router-dom';

type State = Root.Auth.state;
type Data = Root.Auth.data;

const initialState: State = { data: null };

export const authSlice = createSlice({
  name: 'root/auth/slice',
  initialState,
  reducers: {
    setData: (state, { payload }: PayloadAction<Data>) => {
      if (!state['data']) state['data'] = payload;
      const auth = localStorage.getItem('ROOT_TOKEN');
      if (!auth) localStorage.setItem('ROOT_TOKEN', JSON.stringify(payload));
    },
    init: (state) => {
      state['data'] = null;
    },
    unMount: (state) => {
      state['data'] = null;
    },
  },
});

const logout = createAsyncThunk('root/auth/logout', (payload: { navigate: NavigateFunction }, { dispatch }) => {
  localStorage.removeItem('ROOT_TOKEN');
  payload.navigate('/root');
  dispatch(authSlice.actions.unMount());
});

// 로그인 인증시 에러 메세지 케이스
const errorMessage = (response: { result_code: string; result_message: string }) => {
  // prettier-ignore
  switch (response.result_code) {
    case 'F301': return '아이디 혹은 비밀번호를 확인해 주세요.';
    case 'F302': return '비활성화된 아이디입니다.';
    case 'F304': return '비활성화된 아이디입니다.';
    case 'F303': return '비활성화된 고객사입니다.';
    case 'E580': return '데이터 베이스가 유효하지 않습니다.';
    default: return response.result_message === '접근 권한이 없음' ? '아이디 혹은 비밀번호를 확인해 주세요.' : '알수없는 에러'
  }
};

const login = createAsyncThunk(
  'root/auth/login',
  (payload: { root_name: string; password: string; navigate: NavigateFunction }, { dispatch }) => {
    const { navigate, root_name, password } = payload;
    if (!root_name) return '아이디를 입력하세요.';
    if (!password) return '비밀번호를 입력하세요';
    return process(
      api.reqData({ url: 'root/sign-in', data: payload }),
      api.post.request,
      api.fullFilled(({ response, error }) => {
        if (response) {
          dispatch(authSlice.actions.setData({ ...response.data })); // store 저장
          navigate('/root/client-management');
        }
        if (error) return errorMessage(error.data.api_response);
        return '';
      })
    );
  }
);

export const authActions = { ...authSlice.actions, login, logout };
