import { Alert } from 'common/components';
import { api, process } from 'common/functions';
import { modal } from 'layout';
import { cloneDeep } from 'lodash';
import React, { Dispatch, useState } from 'react';
import type { Action, State } from '../../CtReviewerTable';
import './Portal_Confirm.scss';

interface Props {
  closeHandler: () => void;
  state: State;
  index: number;
  setState: Dispatch<Action>;
  setFail: (value: null | { current_count: number; max_count: number }) => void;
}

const PortalConfirm: React.FC<Props> = ({ state, setState, index, closeHandler, setFail }) => {
  const { set } = Alert.Context();
  const [loading, setLoading] = useState(false);

  const { data } = state;
  const rowData = data[index];

  const submitHandler = () => {
    setLoading(true);
    process(
      api.reqData({ url: `reviewers/${rowData.id}/active`, data: { active: !rowData.active } }),
      api.put.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { api_response, current_count, max_count } = response.data;
          const { result_code } = api_response;
          if (result_code === 'N100') {
            const assign = cloneDeep(state.data);
            assign[index].active = !rowData.active;
            setState({ type: 'setData', payload: assign });
            set({ success: '활성 정보 수정이 완료되었습니다.' });
          }
          if (result_code === 'F607') {
            setFail({ current_count, max_count });
            closeHandler();
          }
        }
      })
    );
  };

  return (
    <modal.Container styles={{ width: 500 }} id="root-admin-page-modify-confirm">
      <modal.Header align="center">활성 정보 수정</modal.Header>
      <modal.Content>
        <div className="modify-info">
          <p className="name">
            <span>리뷰어</span>
            <span>{rowData.username}</span>
          </p>

          <p className="into">
            <span>{rowData.active ? '활성' : '비활성'}</span>
            <i />
            <span>{rowData.active ? '비활성' : '활성'}</span>
          </p>
        </div>

        <hr />

        <div className="description">
          <p>해당 계정의 활성 상태를 변경하시겠습니까?</p>
          <p>활성 여부를 전환할 경우 서비스에 바로 반영됩니다.</p>
        </div>
      </modal.Content>
      <modal.Footer loading={loading} closeHandler={closeHandler} submitHandler={submitHandler} />
    </modal.Container>
  );
};

export default PortalConfirm;
