import React from 'react';
import classNames from 'classnames';
import { ToolTip } from 'common/components';
import { NullishGuard } from 'hoc';
import type { FaceCheck, IdCard, Module } from '../../Expand';
import './Face.scss';

interface Props {
  content: FaceCheck;
  idCard: IdCard;
  module: Module | null;
  isExpired: boolean;
}

const Face: React.FC<Props> = ({ content, idCard, module, isExpired }) => {
  const { is_same_person, is_live, selfie_image } = content;
  return (
    <div id="reviewer-review-table-expand-content-face-check">
      <ul className="info-section">
        <li>
          <h4>
            얼굴 정보 유사도
            <ToolTip position="bottom left" content="신분증 사진과 셀피 사진을 비교하여 유사도 제공">
              <i />
            </ToolTip>
          </h4>
          <p className={classNames({ warning: !is_same_person })}>{is_same_person ? 'High' : 'Low'}</p>
        </li>

        <NullishGuard data={[module?.liveness]}>
          <li>
            <h4>
              얼굴 위변조 검사
              <ToolTip position="bottom left" content="실제 사람 얼굴인지 여부를 검사">
                <i />
              </ToolTip>
            </h4>
            <p className={classNames({ warning: !is_live })}>{is_live ? '통과' : '실패'}</p>
          </li>
        </NullishGuard>
      </ul>

      <section className="image-section">
        <div className="image-field">
          <p>신분증 사진</p>
          {isExpired ? (
            <div className="image-expired" />
          ) : (
            <img src={`data:image/jpeg;base64,${idCard.id_crop_image}`} alt="id-card" />
          )}
        </div>

        <div className="image-field">
          <p>검출 사진</p>
          {isExpired ? (
            <div className="image-expired" />
          ) : (
            <img src={`data:image/jpeg;base64,${selfie_image}`} alt="id-card" />
          )}
        </div>
      </section>
    </div>
  );
};

export default Face;
