import React from 'react';
import classNames from 'classnames';
import { useEddConext } from '../../../context/EddContext';
import './NationRisk.scss';

interface Props {
  use_ra: boolean;
}
function NationRisk({ use_ra }: Props) {
  const { edit, setState } = useEddConext();

  return (
    <article className="edd-setting-nation-risk">
      <div className="title" onClick={() => setState({ type: 'detailPage', payload: 'country' })}>
        <h4>국가 위험</h4>
        <div className="score">
          {use_ra && (
            <>
              <div>
                <span className={classNames({ hide: !edit })}>부여 점수</span>
                <span>100점</span>
              </div>
              <div>
                {edit && (
                  <>
                    <span>남은 점수</span>
                    <span>0점</span>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <i />
      </div>
    </article>
  );
}

export default NationRisk;
