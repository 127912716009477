import React, { useContext, createContext, useReducer, useEffect, Dispatch } from 'react';
import { produce } from 'immer';
import { Alert, Tree } from 'common/components';
import { api, process } from 'common/functions';
import { ContentContext } from 'layout/admin/content/Content';
import { getParseResp } from './controller';

/**
 * CustomFieldContext
 */
export const CustomFieldContext = createContext<{
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
  updateHandler: (index: number, rowData: CustomField) => void;
  configSubmitHandler: () => void;
  submitHandler: () => void;
} | null>(null);

export type CustomField = {
  id: number;
  order: number;
  name: string;
  type: 'text' | 'file' | 'tree' | 'ox' | 'addr' | '';
  description: string;
  required: boolean | null;
  active: boolean;
  isAppend: boolean;
  tree: Tree[] | null;
  // error: CFError;
  error: {
    type: 'text' | 'file' | 'tree' | 'ox' | 'addr' | '' | false;
    name: boolean;
    active: boolean;
    duplicate: boolean;
  };
};

type Configs = { use_custom_field: boolean };

export type Data = {
  customFields: CustomField[];
};

export type Action =
  | { type: 'configs'; payload: Configs }
  | { type: 'defaultConfigs'; payload: Configs }
  | { type: 'setData'; payload: Partial<Data> }
  | { type: 'setDefaultData'; payload: Partial<Data> }
  | { type: 'manual'; payload: boolean }
  | { type: 'drag'; payload: CustomField[] };

export interface State {
  configs: Configs;
  defaultConfigs: Configs;
  data: Data;
  defaultData: Data;
}
const initialState: State = {
  configs: { use_custom_field: false },
  defaultConfigs: { use_custom_field: false },
  data: {
    customFields: [],
  },
  defaultData: {
    customFields: [],
  },
};

const p = (url: string) => {
  return process(
    api.reqData({ url }),
    api.get.request,
    api.fullFilled(({ response }) => {
      if (response?.data.list) return response?.data.list;
      if (typeof response?.data.value === 'boolean') return response?.data.value;
      return undefined;
    })
  );
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'configs':
        draft['configs'] = action.payload;
        break;
      case 'defaultConfigs':
        draft['defaultConfigs'] = action.payload;
        break;
      case 'setData':
        draft['data'] = { ...draft.data, ...action.payload };
        break;
      case 'setDefaultData':
        draft['defaultData'] = { ...draft.defaultData, ...action.payload };
        break;
      case 'drag':
        draft['data']['customFields'] = action.payload.filter((item) => item);
        break;
    }
  });
};

export function CustomFieldProvider({ children }: { children: JSX.Element }) {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);
  const { set } = Alert.Context();

  const loadHandler = async () => {
    setLoading(true);
    const [use_custom_field, fieldList] = await Promise.all([p('configs/use_custom_field'), p('field/survey')]);

    fieldList.sort((a: CustomField, b: CustomField) => a.order - b.order);
    const { customFields } = getParseResp(fieldList);

    setState({ type: 'configs', payload: { use_custom_field } });
    setState({ type: 'defaultConfigs', payload: { use_custom_field } });
    setState({ type: 'setData', payload: { customFields } });
    setState({ type: 'setDefaultData', payload: { customFields } });

    setLoading(false);
  };

  const updateHandler = (index: number, rowData: CustomField) => {
    const newCustomFields = state.data.customFields.map((customField, idx) => {
      if (idx === index) return { ...rowData };
      return customField;
    });
    const activeCount = newCustomFields.filter((cf) => cf.active).length;
    if (!activeCount) {
      setState({ type: 'configs', payload: { use_custom_field: false } });
    }
    setState({ type: 'setData', payload: { customFields: newCustomFields } });
  };

  const configSubmitHandler = async () => {
    setLoading(true);

    await process(
      api.reqData({ url: 'configs', data: { use_custom_field: String(state.configs.use_custom_field) } }),
      api.put.request
    );
    loadHandler();
    set({ success: '추가 인증 서비스가 변경되었습니다.' });
  };

  const submitHandler = async () => {
    setLoading(true);

    const { customFields: surveyFields } = state.data;

    const orderedSurveyFields = surveyFields.map((f, i) => ({ ...f, order: i + 1 }));
    const add = orderedSurveyFields.filter((cf) => cf.isAppend);
    const update = orderedSurveyFields.filter((cf) => !cf.isAppend);

    const activeCount = surveyFields.filter((cf) => cf.active).length;

    if (!activeCount) {
      await process(api.reqData({ url: 'configs', data: { use_custom_field: 'false' } }), api.put.request);
    }
    process(
      api.reqData({ url: 'field/survey', data: { add, update } }),
      api.put.request,
      api.fullFilled(() => {
        loadHandler();
        set({ success: '추가 인증 서비스가 변경되었습니다.' });
      })
    );
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <CustomFieldContext.Provider
      value={{ state, setState, loadHandler, updateHandler, configSubmitHandler, submitHandler }}
    >
      {children}
    </CustomFieldContext.Provider>
  );
}

// context type 보호
export const useCustomContext = () => {
  const context = useContext(CustomFieldContext);
  if (!context) throw new Error('context is null');
  return context;
};
