import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { api, process } from 'common/functions';
import { Tree } from 'common/components';
import { ContentContext } from 'layout/admin/content/Content';
import { useRaWeightContext, Risk as EddCategory } from '../context/WeightContext';
import RealScoreProvider from '../context/RealScoreContext';
import RaType from './ratype/RaType';
import './RealScoreContent.scss';

export type EddField = {
  id: number;
  name: string;
  type: string;
  active: boolean;
  isAppend: boolean;
  category: string;
  score: number;
  deletable: boolean;
  mutable: boolean;
  order: number;
  displayName: string;
  description: string;
  tree: Tree[];
};
export type NationRisk = {
  id: number;
  score: number;
  iso_code2: string;
  iso_code3: string;
  name_en: string;
  name_kr: string;
  warn_labels: { name_en: string; name_kr: string }[];
};
export type WarnLabel = { id: number; name_en: string; name_kr: string; score: number; active: boolean };

export type State = {
  eddCategory: EddCategory[];
  eddField: { customer: EddField[]; service: EddField[]; country: EddField[] };
  nation: NationRisk[];
  warnLabels: WarnLabel[];
};

export type Action =
  | { type: 'eddCategory'; payload: EddCategory[] }
  | { type: 'eddField'; payload: { customer: EddField[]; service: EddField[]; country: EddField[] } }
  | { type: 'nation'; payload: NationRisk[] }
  | { type: 'warnLabels'; payload: WarnLabel[] };

const initialState: State = {
  eddCategory: [],
  eddField: { customer: [], service: [], country: [] },
  nation: [],
  warnLabels: [],
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'eddCategory': draft['eddCategory'] = action.payload; break;
      case 'eddField': draft['eddField'] = action.payload; break;
      case 'nation': draft['nation'] = action.payload; break;
      case 'warnLabels': draft['warnLabels'] = action.payload; break;
      default: break;
    }
  });
};

const get = (url: string) => {
  return process(
    api.reqData({ url }),
    api.get.request,
    api.fullFilled(({ response }) => {
      return response?.data.list;
    })
  );
};

interface Props {
  edd_countries_mode: number;
}
function RealScoreContent(props: Props) {
  const { edd_countries_mode } = props;
  const { setLoading } = useContext(ContentContext);
  const { state: { data: edd_categories } } = useRaWeightContext(); // prettier-ignore
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);

    const p = edd_categories.map((ec) => get(`field/edd_fields/${ec.edd_type}?active_only=true`));
    const payload = edd_categories.length
      ? [get(edd_countries_mode === 1 ? 'country?warn_only=true' : 'edd_countries'), get('warn_labels'), ...p]
      : [get(edd_countries_mode === 1 ? 'country?warn_only=true' : 'edd_countries'), get('warn_labels')];
    const [nation, warnLabels, customer, service, country] = await Promise.all(payload);

    setState({ type: 'nation', payload: nation });
    setState({ type: 'eddField', payload: { customer, service, country } });
    setState({ type: 'warnLabels', payload: (warnLabels as WarnLabel[])?.filter((l) => l.active) ?? [] });

    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, [edd_categories]);

  return (
    <div id="admin-manage-ra-realscore-content">
      {edd_categories.map((edd_category) => (
        <RealScoreProvider key={edd_category.id}>
          <RaType
            key={edd_category.id}
            edd_category={edd_category}
            edd_field={state.eddField[edd_category.edd_type]}
            nation={state.nation}
            warnLabels={state.warnLabels}
          />
        </RealScoreProvider>
      ))}
    </div>
  );
}

export default RealScoreContent;
