import React, { Dispatch } from 'react';
import './Account.scss';
import { Button, Modal, Popover } from 'common/components';
import { Action, State } from '../CtSubscribeSetting';
import AccountModal from './modal/AccountModal';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const Account = ({ state, setState, loadHandler }: Props) => {
  const { allow_change_account, max_change_account } = state.vas;
  return (
    <section id="subscribe-management-setting-account">
      <div className="header">
        <div className="title">계좌 변경</div>
        <Popover
          position="right center"
          clickOutside
          content={() => (
            <div id="admin-subscribe-account-popover-content" className="description">
              계좌 인증을 위한 인증 코드를 받은 후 계좌번호를 변경하여 새로운 인증 코드를 받을 수 있게 해줍니다.
            </div>
          )}
        >
          <i className="title-info" />
        </Popover>
      </div>
      <div className="content">
        <div className="label">{allow_change_account ? `사용 중 (${max_change_account}회)` : '사용 안 함'} </div>
      </div>
      <div className="button-group">
        <Modal
          content={({ closeHandler }) => (
            <AccountModal state={state} setState={setState} loadHandler={loadHandler} closeHandler={closeHandler} />
          )}
        >
          <Button className="submit" text={allow_change_account ? '중지' : '사용'} />
        </Modal>
      </div>
      {state.disabled.manual && <div className="subscribe-setting-disabled" />}
    </section>
  );
};

export default Account;
