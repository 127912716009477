import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { api, process } from 'common/functions';
import { ContentContext } from 'layout/admin/content/Content';
import Forbidden from '../forbidden/Forbidden';
import { CustomFieldProvider } from './context/CustomFieldContext';
import Custom from './custom/Custom';
import './CustomFieldContent.scss';

export type Module = { custom_field: boolean };
type Action = { type: 'module'; payload: Module };
type State = { module: Module };

const initialState: State = {
  module: { custom_field: false },
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'module': draft['module'] = action.payload; break;
    }
  });
};

const get = (url: string) =>
  process(
    api.reqData({ url }),
    api.get.request,
    api.fullFilled(({ response }) => {
      if (response?.data.module) return response?.data.module;
      if (response?.data.list) return response?.data.list;
      return undefined;
    })
  );

const CustomFieldContent = () => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);
  const { module } = state;

  const loadHandler = async () => {
    setLoading(true);

    const module = await get('modules');

    setState({ type: 'module', payload: module });
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <div id="admin-manage-customfield-content">
      {module.custom_field ? (
        <CustomFieldProvider>
          <Custom />
        </CustomFieldProvider>
      ) : (
        <Forbidden
          content={
            <>
              <p>페이지 접근 권한이 없습니다.</p>
              <p>해당 기능을 사용하려면 고객센터로 연락해 주세요.</p>
            </>
          }
        />
      )}
    </div>
  );
};

export default CustomFieldContent;
