import React, { Dispatch, useState } from 'react';
import { modal } from 'layout';
import { Alert } from 'common/components';
import { api, process } from 'common/functions';
import type { State as TableState, Action as TableAction } from '../../../CtReviewerTable';
import './Modal_AdminDeny.scss';

interface Props {
  state: TableState;
  index: number;
  setState: Dispatch<TableAction>;
  closeHandler: () => void;
  refresh: () => void;
}

const ModalAdminDeny: React.FC<Props> = (props) => {
  const { set } = Alert.Context();
  const [loading, setLoading] = useState(false);
  const { closeHandler } = props;
  const rowData = props.state.data[props.index];

  const submitHandler = () => {
    setLoading(true);
    process(
      api.reqData({ url: `reviewers/${rowData.id}/approve`, data: { approve: false } }),
      api.put.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { result_code } = response.data.api_response;
          if (result_code === 'N100') {
            set({ success: '심사자 신청이 거절되었습니다.' });
            closeHandler();
            props.refresh();
          }
        }
      })
    );
  };

  return (
    <modal.Container styles={{ width: 500 }} id="root-admin-page-approval-delete">
      <modal.Header align="center">계정 삭제 안내</modal.Header>
      <modal.Content>
        <div className="user-info">
          <div className="name">{rowData.name}</div>
          <div className="username">{rowData.username}</div>
        </div>

        <hr />

        <div className="description">
          거절 처리된 계정은 삭제됩니다. <br />
          계속 진행하시겠습니까?
        </div>
      </modal.Content>
      <modal.Footer loading={loading} submitText="삭제" submitHandler={submitHandler} closeHandler={closeHandler} />
    </modal.Container>
  );
};

export default ModalAdminDeny;
