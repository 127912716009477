import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { api, process } from 'common/functions';
import { M_Table } from 'common/components';
import { ContentContext } from 'layout/root/content/Content';
import HistoryTable from './HistoryTable';

export type Data = {
  label_name: string;
  label_name_before: string | null;
  log_type: 1 | 2;
  score_log: [number, number] | null;
  active_log: 1 | 2 | null;
  con_add_log: { name_en: string; name_kr: string }[] | null;
  con_del_log: { name_en: string; name_kr: string }[] | null;
  created_at: string;
  root_name: string;
  expand: boolean;
};
export type Action = { type: 'data'; payload: Data[] } | { type: 'expand'; payload: number };
export type State = {
  data: Data[];
  header: M_Table.header[];
};

const initialState: State = {
  data: [],
  header: [
    { title: '라벨', property: 'label_name', flex: 2 },
    { title: '변경 내역', property: 'log_type', flex: 3 },
    { title: 'ID', property: 'root_name', flex: 1 },
    { title: '변경 일시', property: 'created_at', flex: 1 },
    { title: '', property: 'expand', flex: 0.5 },
  ],
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'data':
        draft['data'] = action.payload;
        break;
      case 'expand':
        draft['data'][action.payload]['expand'] = !draft['data'][action.payload]['expand'];
        break;
      default:
        break;
    }
  });
};

function CtLabelHistoryTable() {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = () => {
    setLoading(true);

    process(
      api.reqData({ url: 'warn_label_logs' }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { list } = response.data;
          const payload = list.map((el: Omit<Data, 'expand'>) => ({ ...el, expand: false }));
          setState({ type: 'data', payload });
        }
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return <HistoryTable state={state} setState={setState} />;
}

export default CtLabelHistoryTable;
