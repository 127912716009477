import { M_Table } from 'common/components';
import { api, process } from 'common/functions';
import { useApiCancel, useMount } from 'common/hooks';
import { produce } from 'immer';
import type { Pagination } from 'models';
import React, { useEffect, useReducer } from 'react';
import SettingsTable from './SettingsTable';

const initPagination = { info: { current: 1, total: 10 }, format: { limit: 20, offset: 0 } };
export type Data = {
  active: boolean;
  email: string;
  id: number;
  is_approve: boolean;
  name: string;
  phone_number: string;
  username: string;
};

export type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'setData'; payload: Data[] }
  | { type: 'pagination'; payload: Pagination };

export interface State {
  data: Data[];
  loading: boolean;
  header: M_Table.header[];
  pagination: Pagination;
}

const initialState: State = {
  data: [],
  loading: true,
  pagination: { ...initPagination },
  header: [
    { title: '변경일시', property: 'request_time', flex: 3 },
    { title: '아이디', property: 'admin_id', flex: 3 },
    { title: '이름', property: 'admin_name', flex: 2 },
    { title: '변경 전', property: 'level_before' },
    { title: '변경 후', property: 'level_after' },
  ],
};

// prettier-ignore
const reducer = (state: State, action: Action): State => {
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break
      case 'loading': draft['loading'] = action.payload; break;
      case 'pagination': draft['pagination'] = action.payload; break;
    }
  });
};

const CtSettingTable: React.FC = () => {
  useApiCancel(['threshold/logs']);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = () => {
    setState({ type: 'loading', payload: true });

    const params = { start: state.pagination.format.offset, count: state.pagination.format.limit };
    process(
      api.reqData({ url: 'threshold/logs', params }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          setState({ type: 'setData', payload: response.data.list || [] });
          // setState({ type: 'pagination', payload: state.pagination });
        }
        setState({ type: 'loading', payload: false });
      })
    );
  };

  useEffect(() => {
    loadHandler();
  }, []);

  useMount(() => {
    loadHandler();
  }, [state.pagination.info.current]);

  return <SettingsTable state={state} setState={setState} loadHandler={loadHandler} />;
};

export default CtSettingTable;
