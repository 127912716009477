import classNames from 'classnames';
import { Controller, M_Select, Pagination, Search, Select, Table } from 'common/components';
import type * as M from 'models';
import React, { Dispatch } from 'react';
import './ClientTable.scss';
import type { Action, State } from './CtClientTable';
import Title from './controller/Title';
import Expand from './expand/Expand';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const ReviewerTable: React.FC<Props> = ({ state, setState, loadHandler }): JSX.Element => {
  const { header, data, searchKey, pagination } = state;

  const handlers = {
    refresh: loadHandler,
    search: (searchText: string) => setState({ type: 'searchText', payload: Symbol(searchText) }),
    searchKey: (payload: M_Select.item): void => setState({ type: 'searchKey', payload }),
    pagination: (payload: M.Pagination): void => setState({ type: 'pagination', payload }),
  };

  return (
    <section id="root-client-table">
      <Controller title={<Title refresh={handlers.refresh} />}>
        <Select
          styles={{ optionsHeight: 'auto' }}
          onChange={handlers.searchKey}
          selected={searchKey}
          options={[
            { title: '선택', value: 'all' },
            { title: '고객사명', value: 'name' },
          ]}
        />
        <Search submitHandler={handlers.search} disabled={searchKey.value === 'all'} />
      </Controller>
      <Table data={data} header={header}>
        <Table.Head>{({ value }) => value.title}</Table.Head>
        <Table.Body>
          {({ index }) => {
            const rowData = data[index];
            return (
              <Table.Row key={rowData.id} styles={{ height: rowData.expand ? 'auto' : 72 }}>
                <Table.Tr index={index} style={{ height: 72 }}>
                  {({ value, property }) => {
                    const industry = ['선택', '비금융권', '금융권', '가상자산사업자', 'P2P온투업자'];
                    const purchase = ['API', '솔루션'];
                    if (property === 'industry') return industry[rowData[property]];
                    if (property === 'purchase_type') return purchase[rowData[property]];
                    if (property === 'active')
                      return (
                        <div className={classNames('active-column', { active: !!rowData[property] })}>
                          {rowData[property] ? '활성' : '비활성'}
                        </div>
                      );
                    if (property === 'expand')
                      return (
                        <i
                          className={classNames('expand-icon', { expanded: rowData.expand })}
                          onClick={() => setState({ type: 'expand', payload: index })}
                        />
                      );
                    return value || '-';
                  }}
                </Table.Tr>
                {rowData.expand && <Expand loadHandler={handlers.refresh} rowData={rowData} />}
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
      <div className="table__context__page__gear">
        <Pagination pagination={pagination} onChange={handlers.pagination} />
      </div>
    </section>
  );
};

export default ReviewerTable;
