import React from 'react';
import { api } from 'common/functions';

export const useApiCancel = (urls: string[]) => {
  return React.useEffect(() => {
    return () => {
      const tokens = urls.map((url: string) => api.token[url]);
      for (const token of tokens) {
        if (token) token();
      }
    };
  }, []);
};

export const useUnmount = (f: () => void): void => {
  React.useEffect(() => {
    return f;
  }, []);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMount = (f: () => void, dep: any[]): void => {
  const isFirstRun = React.useRef(false);

  React.useEffect(() => {
    if (isFirstRun.current) f();
    if (!isFirstRun.current) isFirstRun.current = true;
  }, dep);
};

// controlled 와 uncontrolled 상태를 분석해서 사용할 상태와 함수를 리턴
type TUseContorl<T> = {
  state?: T;
  dispatcher?: (state: T) => void;
  default?: T;
};
export const useControl = <T>(props: TUseContorl<T>): [T, (state: T) => void] => {
  const state = props.state as NonNullable<T>;
  const d = props.default as NonNullable<T>;
  const unControlled = React.useState<T>(state ?? d);

  return [
    props.state === undefined ? unControlled[0] : props.state,
    (arg: T) => {
      if (!props.state || !props.dispatcher) unControlled[1](arg);
      if (props.dispatcher) props.dispatcher(arg);
    },
  ];
};
