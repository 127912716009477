import { AuthenticatePage } from './manage/AuthenticatePage';
import { SubscribePage } from './manage/SubscribePage';
import { ThemeSettingPage } from './manage/ThemeSettingPage';

import { RaPage } from './manage/edd/RaPage';
import { WeightPage } from './manage/edd/WeightPage';
import { RealScorePage } from './manage/edd/RealScorePage';
import { CriterionPage } from './manage/edd/CriterionPage';
import { CustomFieldPage } from './manage/edd/CustomFieldPage';

const Edd = { RaPage, WeightPage, CriterionPage, CustomFieldPage, RealScorePage };

export { LoginPage } from './login/LoginPage';
export { ReviewerPage } from './reviewer/ReviewerPage';
export { TermsPage } from './terms/TermsPage';
export { SettingPage } from './settings/SettingPage';

export const Manage = { AuthenticatePage, CustomFieldPage, SubscribePage, ThemeSettingPage, Edd };
