import { api, process } from 'common/functions';
import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import CtSubscribeTable from './setting/CtSubscribeSetting';
import './SubscribeContent.scss';
import InfoCard from './info/Info';

export type Module = {
  account_verification: boolean;
  face_authentication: boolean;
  id_card_ocr: boolean;
  id_card_verification: boolean;
  liveness: boolean;
  custom_field: boolean;
};
export type Configs = { key: string; value: string };
export type VAS = {
  identification: boolean;
  email_notification: boolean;
  sms_notification: boolean;
  use_custom_end_message: boolean;
  custom_end_message: string;
  allow_auto_approve: boolean;
  use_ocr_driver_date: boolean;
  trust_driver_date: boolean;
  use_ocr_driver_expired_date: boolean;
  trust_driver_expired_date: boolean;
  max_change_account: number;
  allow_change_account: boolean;
  allow_account_subcode: boolean;
  use_account_subcode: boolean;
  account_subcode_position: string;
  account_subcode: string;
};
export type Disabled = { manual: boolean; custom: boolean };
export type Data = { module: Module; vas: VAS; kDriver: boolean };
export type State = { data: Data; loading: boolean; disabled: Disabled };

export const initVas: VAS = {
  identification: false,
  email_notification: false,
  sms_notification: false,
  use_custom_end_message: false,
  custom_end_message: '',
  allow_auto_approve: true,
  /* 신분증 인증 */
  use_ocr_driver_date: true,
  trust_driver_date: true,
  use_ocr_driver_expired_date: true,
  trust_driver_expired_date: true,
  /* 계좌 인증 */
  max_change_account: 1,
  allow_change_account: true,
  allow_account_subcode: true,
  use_account_subcode: false,
  account_subcode_position: '',
  account_subcode: '',
};
const initData: Data = {
  module: {
    account_verification: false,
    face_authentication: false,
    id_card_ocr: false,
    id_card_verification: false,
    liveness: false,
    custom_field: false,
  },
  vas: initVas,
  kDriver: false,
};

const initialState: State = {
  data: initData,
  loading: false,
  disabled: {
    manual: false,
    custom: true,
  },
};

export type Action =
  | { type: 'setData'; payload: Data }
  | { type: 'loading'; payload: boolean }
  | { type: 'disabled'; payload: { type: keyof Disabled; value: boolean } };

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'setData': draft['data'] = action.payload; break;
      case 'disabled': draft.disabled[action.payload.type] = action.payload.value; break;
    }
  });
};

export const get = (url: string) => {
  return process(
    api.reqData({ url }),
    api.get.request,
    api.fullFilled(({ response }) => {
      if (response?.data.list) return response.data.list;
      if (response?.data.module) return response.data.module;
      if (response?.data.detail) return response.data.detail;
      return undefined;
    })
  );
};

const SubscribeContent: React.FC = () => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);
    setState({ type: 'loading', payload: true });
    const queue = [get('modules'), get('configs'), get('idcards/korean-driver')];
    const [module, configs, kDriver] = await Promise.all(queue);
    // TODO refactoring 필요
    const vas = configs.reduce((prev: Configs, cur: Configs) => ({ ...prev, [cur.key]: cur.value }), { ...initVas });
    const data: Data = { ...initData, module, vas, kDriver: kDriver.active };

    setState({ type: 'setData', payload: data });
    if (module.custom_field) setState({ type: 'disabled', payload: { type: 'custom', value: false } });
    setState({ type: 'loading', payload: false });
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <div id="admin-manage-subscribe-content">
      <InfoCard module={state.data.module} vas={state.data.vas} />
      <CtSubscribeTable state={state} loadHandler={loadHandler} />
    </div>
  );
};

export default SubscribeContent;
