import React, { Dispatch, useState } from 'react';
import { modal } from 'layout';
import { Alert } from 'common/components';
import { api, process } from 'common/functions';
import { Action, State } from '../../CtSubscribeSetting';
import './ManualModal.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
  closeHandler: () => void;
}

const ManualModal: React.FC<Props> = ({ state, setState, loadHandler, closeHandler }) => {
  const { allow_auto_approve } = state.vas;
  const { set } = Alert.Context();
  const [loading, setLoading] = useState(false);

  const submitHandler = () => {
    setLoading(true);
    process(
      api.reqData({ url: 'configs', data: { allow_auto_approve: String(!allow_auto_approve) } }),
      api.put.request,
      api.fullFilled(({ response }) => {
        loadHandler();
        closeHandler();
        set({ success: `자동심사가 ${!allow_auto_approve ? '설정' : '중지'}되었습니다.` });
        setState({ type: 'allowAutoApprove', payload: !allow_auto_approve });
        return response;
      })
    );
  };
  return (
    <div id="identification__modal__wrapper">
      <modal.Container styles={{ width: 500 }}>
        <modal.Header align="center">자동심사 여부</modal.Header>
        <modal.Content>
          <div className="status-container">
            <div className="status">{allow_auto_approve ? '사용중' : '사용 안 함'}</div>
            <div className="icon" />
            <div className="status ok">{allow_auto_approve ? '사용 안 함' : '사용'}</div>
          </div>
          <hr />
          <div className="detail">
            자동심사를 {allow_auto_approve ? '미사용' : '사용'}하시겠습니까?
            <br />
            사용 여부를 전환할 경우 서비스에 바로 반영됩니다.
          </div>
        </modal.Content>
        <modal.Footer
          submitText={allow_auto_approve ? '사용 안 함' : '사용'}
          loading={loading}
          submitHandler={submitHandler}
          closeHandler={closeHandler}
        />
      </modal.Container>
    </div>
  );
};

export default ManualModal;
