import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import { api, process } from 'common/functions';

type RaWeightContextType = {
  state: State;
  setState: React.Dispatch<Action>;
  submitHandler: () => void;
};
export const RaWeightContext = createContext<RaWeightContextType | null>(null);
export type Risk = { id: number; edd_type: 'customer' | 'service' | 'country'; name: string; weight: number };

type Action =
  | { type: 'edit'; payload: boolean }
  | { type: 'extraWeight'; payload: null }
  | { type: 'data'; payload: Risk[] }
  | { type: 'defaultData'; payload: Risk[] };
type State = {
  extraWeight: number;
  data: Risk[];
  defaultData: Risk[];
};

const initialState = {
  extraWeight: 1,
  data: [],
  defaultData: [],
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'extraWeight':
        draft['extraWeight'] = draft['data'].reduce((acc, cur) => acc - cur.weight * 10, 10) / 10;
        break;
      case 'data':
        draft['data'] = action.payload;
        break;
      case 'defaultData':
        draft['defaultData'] = action.payload;
        break;
      default:
        break;
    }
  });
};

const get = (url: string) => {
  return process(
    api.reqData({ url }),
    api.get.request,
    api.fullFilled(({ response }) => {
      if (response?.data.list) return response?.data.list;
      if (response?.data.module) return response?.data.module;
      return undefined;
    })
  );
};

const put = (url: string, data: { changes: Risk[] }) => {
  return process(api.reqData({ url, data }), api.put.request);
};
interface Props {
  children: React.ReactNode;
}
function RaWeightProvider({ children }: Props) {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);
    const edd_category = await get('field/edd_category');

    setState({ type: 'data', payload: edd_category });
    setState({ type: 'defaultData', payload: edd_category });

    setState({ type: 'extraWeight', payload: null });
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  const submitHandler = async () => {
    setLoading(true);
    const { data: edd_category } = state;
    await put('field/edd_category', { changes: edd_category });
    loadHandler();
  };

  return <RaWeightContext.Provider value={{ state, setState, submitHandler }}>{children}</RaWeightContext.Provider>;
}

RaWeightProvider.Consumer = RaWeightContext.Consumer as React.Consumer<RaWeightContextType>;
export default RaWeightProvider;

export const useRaWeightContext = () => {
  const context = useContext(RaWeightContext);
  if (!context) throw new Error('raWeightContext is null');

  return context;
};
