import React, { Dispatch } from 'react';
import { modal } from 'layout';
import { Alert } from 'common/components';
import { api, process } from 'common/functions';
import { Action, State } from '../CtAuthenticateCardGroup';
import './DeleteHmac.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
  closeHandler: () => void;
}

const DeleteHmac: React.FC<Props> = ({ state, setState, loadHandler, closeHandler }) => {
  const { loading } = state;
  const { set } = Alert.Context();

  const submitHandler = () => {
    setState({ type: 'loading', payload: true });
    process(
      api.reqData({ url: 'secrets/hmac' }),
      api.delete.request,
      api.fullFilled(({ response }) => {
        loadHandler();
        closeHandler();
        set({ success: 'HMAC Key가 삭제되었습니다.' });
        return response;
      })
    );
  };

  return (
    <div id="delet_hmac__modal__wrapper">
      <modal.Container styles={{ width: 500 }}>
        <modal.Header align="center">HMAC Key 삭제</modal.Header>
        <modal.Content>
          <div className="detail">
            HMAC Key를 삭제하시겠습니까?
            <br />
            삭제 시 해당 Key를 사용한 인증을 이용할 수 없습니다.
          </div>
        </modal.Content>
        <modal.Footer loading={loading} submitText="삭제" submitHandler={submitHandler} closeHandler={closeHandler} />
      </modal.Container>
    </div>
  );
};

export default DeleteHmac;
