import React, { useState } from 'react';
import { modal } from 'layout';
import { map } from 'lodash';
import type { M_Select } from 'common/components';
import { api, process } from 'common/functions';
import { Select, Alert, Input } from 'common/components';
import './Modal_RejectReview.scss';

interface Props {
  id: number;
  closeHandler: () => void;
  refresh: () => void;
}

const ModalRejectReview: React.FC<Props> = ({ id, closeHandler, refresh }) => {
  const { set } = Alert.Context();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<string>('');
  const [selected, setSelected] = useState<M_Select.item>({ title: '거부 사유 선택', value: '거부 사유 선택' });
  const reason = ['거부 사유 선택', '오래된 사진으로 판단 불가', '동일인이 아님', '수정 정보 오류', '직접 입력'];
  const options = map(reason, (el) => ({ title: el, value: el }));
  const isInput = selected?.value === '직접 입력';
  const notSelected = selected.value === '거부 사유 선택';

  const submitHandler = async () => {
    if (!notSelected) {
      // setState({ type: 'submitLoading', payload: true });
      setLoading(true);
      const params = { approve: 0, auditor_id: 2, rejected_reason: isInput ? value : selected.value };
      process(
        api.reqData({ url: `review/manuals/${id}`, data: params }),
        api.post.request,
        api.fullFilled(() => {
          refresh();
          set({ success: '심사 거부가 완료되었습니다.' });
        })
      );
    }
  };

  return (
    <modal.Container id="review-modal-reject-review" styles={{ width: 380 }}>
      <modal.Header align="center">거부 사유</modal.Header>
      <modal.Content>
        {!isInput && <Select styles={{ height: 56 }} selected={selected} options={options} onChange={setSelected} />}
        {isInput && (
          <div className="input-field">
            <p>{value.length}/45</p>
            <Input className="basic" placeholder="사유를 입력해주세요." onChange={(e) => setValue(e.target.value)} />
          </div>
        )}
      </modal.Content>
      <modal.Footer
        submitText="등록"
        loading={loading}
        closeHandler={closeHandler}
        disabled={isInput ? !value.length : notSelected}
        submitHandler={submitHandler}
      />
    </modal.Container>
  );
};

export default ModalRejectReview;
