import React, { Dispatch } from 'react';
import { Modal, Button, Table, Pagination, Controller } from 'common/components';
import { NavLink } from 'react-router-dom';
import { time } from 'common/functions';
import classNames from 'classnames';
import * as M from 'models';
import type { State, Action } from './CtPrivacyTable';
import Expand from './expand/Expand';
import ModalRegistPrivacy from './modal/Modal_RegistPrivacy';
import './PrivacyTable.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: (pagination?: M.Pagination) => void;
}

const PrivacyTable: React.FC<Props> = ({ state, setState, loadHandler }): JSX.Element => {
  const { header, data, pagination } = state;

  const handlers = {
    refresh: loadHandler,
    pagination: (payload: M.Pagination): void => setState({ type: 'pagination', payload }),
  };

  return (
    <section id="root-terms-privacy-table">
      <Controller
        title={
          <>
            <NavLink to="/root/terms-management/privacy">개인정보처리방침</NavLink>
            <NavLink to="/root/terms-management/service">이용 약관</NavLink>
          </>
        }
      >
        <Modal
          content={({ closeHandler }) => <ModalRegistPrivacy closeHandler={closeHandler} refresh={handlers.refresh} />}
        >
          <Button className="register" text="+ 개정" />
        </Modal>
      </Controller>

      <Table data={data} header={header}>
        <Table.Head>{({ value }) => value.title}</Table.Head>
        <Table.Body>
          {({ index }) => {
            const rowData = data[index];
            return (
              <Table.Row key={rowData.id} styles={{ height: rowData.expand ? 'auto' : 72 }}>
                <Table.Tr index={index} style={{ height: 72 }}>
                  {({ value, property }) => {
                    if (property === 'created_at') {
                      const epoch = new Date(value).getTime();
                      return time.timeFormatFromUTCEpoch(epoch, 3);
                    }
                    if (property === 'expand')
                      return (
                        <i
                          className={classNames('expand-icon', { expanded: rowData.expand })}
                          onClick={() => setState({ type: 'expand', payload: index })}
                        />
                      );
                    return value || '-';
                  }}
                </Table.Tr>
                {rowData.expand && <Expand rowData={rowData} />}
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
      <div className="table__context__page__gear">
        <Pagination pagination={pagination} onChange={handlers.pagination} />
      </div>
    </section>
  );
};

export default PrivacyTable;
