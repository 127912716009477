import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import { admin } from 'store';
import { Admin } from 'pages';
import * as layout from 'layout';
import { Authentication, InterCepter } from 'hoc/admin';
import MyBox from 'layout/page/header/mybox/client/Popover_MyBox';
import TempPassModifyPage from 'pages/tempPassModify/TempPassModifyPage';

export default {
  path: '/admin',
  element: (
    <Provider store={admin.store}>
      <InterCepter>
        <Outlet />
      </InterCepter>
    </Provider>
  ),
  children: [
    { index: true, element: <Admin.LoginPage /> } /* login page */,
    { path: 'temp-pass-modify/:role', element: <TempPassModifyPage /> },
    {
      path: '*',
      element: (
        <Authentication>
          <layout.Page
            myBox={MyBox}
            links={[
              { url: '/admin/reviewer-management', text: '심사자 관리' },
              { url: '/admin/terms-guidance', text: '약관 가이드' },
              { url: '/admin/manage', text: '관리' },
              { url: '/admin/settings', text: '심사 설정' },
            ]}
          />
        </Authentication>
      ),
      children: [
        {
          path: 'reviewer-management',
          element: <layout.admin.Content />,
          children: [{ index: true, element: <Admin.ReviewerPage /> }],
        },
        {
          path: 'terms-guidance',
          element: <layout.admin.Content />,
          children: [{ index: true, element: <Admin.TermsPage /> }],
        },
        {
          path: 'manage',
          element: <layout.admin.Content />,
          children: [
            {
              element: <layout.admin.Manage />,
              children: [
                { path: 'subscribe', element: <Admin.Manage.SubscribePage /> },
                {
                  path: 'edd',
                  element: <layout.admin.Edd />,
                  children: [
                    { path: 'ra', element: <Admin.Manage.Edd.RaPage /> },
                    { path: 'weight', element: <Admin.Manage.Edd.WeightPage /> },
                    { path: 'realscore', element: <Admin.Manage.Edd.RealScorePage /> },
                    { path: 'criterion', element: <Admin.Manage.Edd.CriterionPage /> },
                    { path: 'customField', element: <Admin.Manage.Edd.CustomFieldPage /> },
                    { path: '*', element: <Navigate to="ra" /> },
                  ],
                },
                { path: 'authenticate', element: <Admin.Manage.AuthenticatePage /> },
                { path: 'theme', element: <Admin.Manage.ThemeSettingPage /> },
                { path: '*', element: <Navigate to="subscribe" /> },
              ],
            },
          ],
        },
        {
          path: 'settings',
          element: <layout.admin.Content />,
          // title="심사 설정"
          children: [{ index: true, element: <Admin.SettingPage /> }],
        },
        { path: '*', element: <Navigate to="reviewer-management" /> },
      ],
    },
  ],
};
