import React, { useState, createContext, Dispatch } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer, KycLoading } from 'common/components';
import './Content.scss';

export const ContentContext = createContext<{ loading: boolean; setLoading: Dispatch<boolean> }>({
  loading: false,
  setLoading: () => false,
});

export const Content: React.FC = () => {
  const [loading, setLoading] = useState(false);
  return (
    <ContentContext.Provider value={{ loading, setLoading }}>
      <main id="alchera-reviewer-content-layout">
        {loading && <KycLoading />}
        <div id="content-space">
          <div id="content-outlet">
            <Outlet />
          </div>
          <Footer />
        </div>
      </main>
    </ContentContext.Provider>
  );
};

export default Content;
