/* eslint-disable no-plusplus */
import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { Popover } from 'common/components';
import { Action, ColorChipType, Data } from '../../../../ThemeSettingContent';
import { ChipPiece } from '../../static-chip/Chip';
import ColorPicker from '../../color-picker/ColorPicker';
import './CustomChip.scss';

type CustomChipProps = {
  data: Data;
  chipValue: ColorChipType;
  setState: Dispatch<Action>;
};

const CustomChip = ({ chipValue, setState, data }: CustomChipProps) => {
  const { id, color } = chipValue;
  const { theme_color } = data;

  const chipIndex = data.custom_colors.findIndex((element) => element.id === id);

  const undeletedChip = data.custom_colors.filter((_, index) => index !== chipIndex);

  const onClick = () => {
    setState({
      type: 'setData',
      payload: { ...data, theme_color: { ...theme_color, type: 'custom', index: id, color } },
    });
  };

  const Remove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    setState({
      type: 'setData',
      payload: {
        ...data,
        custom_colors: undeletedChip,
        customId: data.customId,
      },
    });
  };

  return (
    <Popover
      position="bottom center"
      clickOutside
      content={() => <ColorPicker chipValue={chipValue} setState={setState} data={data} />}
    >
      <div className="dynamic-chip" onClick={onClick}>
        <ChipPiece
          className={classNames({
            checkChip: theme_color.type === 'custom' && theme_color.index === id && theme_color.color === color,
          })}
          color={color}
        >
          <i className="check-icon" />
        </ChipPiece>

        <div
          className={classNames('remove-chip', {
            noneRemoveChip: theme_color.type === 'custom' && theme_color.index === id && theme_color.color === color,
          })}
          onClick={(e) => Remove(e)}
        >
          <i className="remove-icon" />
        </div>
      </div>
    </Popover>
  );
};

export default CustomChip;
