import React, { Dispatch } from 'react';
import { Action, State } from './CtSubscribeSetting';
import './SubscribeSetting.scss';
import Message from './message/Message';
import { Module } from '../SubscribeContent';
import IdcardSetting from './idcardSetting/IdcardSetting';
import AccountSetting from './accountSetting/AccountSetting';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  module: Module;
  loadHandler: () => void;
}

const SubscribeTable: React.FC<Props> = ({ state, setState, module, loadHandler }): JSX.Element => {
  const { id_card_ocr, account_verification } = module;

  return (
    <div id="subscribe-management-setting">
      {id_card_ocr && <IdcardSetting state={state} setState={setState} loadHandler={loadHandler} />}
      {account_verification && <AccountSetting state={state} setState={setState} loadHandler={loadHandler} />}
      <h2 className="section-title">부가 서비스</h2>
      <div className="row">
        <Message state={state} setState={setState} loadHandler={loadHandler} />
      </div>
    </div>
  );
};

export default SubscribeTable;
