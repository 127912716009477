import React, { useState, useRef, useEffect } from 'react';
import './AuthenticateCard.scss';

interface Props {
  title: string;
  label: string;
  tooltip?: string;
  modalContent: JSX.Element;
  description?: boolean;
}
const AuthenticateCard = (props: Props) => {
  const { title, label, tooltip, modalContent, description } = props;
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  useEffect(() => {
    const clickOutSide = (e: Event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(e.target as Node)) {
        setShowTooltip(false);
      }
    };
    document.addEventListener('click', clickOutSide);

    return () => {
      document.removeEventListener('click', clickOutSide);
    };
  }, []);

  const tooltipHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowTooltip((prev) => !prev);
  };

  return (
    <article className="content-box">
      <div className="header">
        <div className="title">{title}</div>
        {!!tooltip && (
          <div className="title-info" onClick={tooltipHandler}>
            {showTooltip && (
              <div className="description" ref={tooltipRef}>
                {tooltip}
              </div>
            )}
          </div>
        )}
        <div className="label">{label}</div>
        <div className="button-group">{modalContent}</div>
      </div>
      {description && (
        <div className="content">
          <div className="title">필수 정보 항목</div>
          <ul>
            <li>이름</li>
            <li>생년월일</li>
            <li>전화번호</li>
            <li>이메일</li>
          </ul>
        </div>
      )}
    </article>
  );
};

export default AuthenticateCard;
