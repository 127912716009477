import React, { Dispatch } from 'react';
import { Button, Table, Controller, Modal } from 'common/components';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import type { State, Action } from './CtPrivacyTable';
import Expand from './expand/Expand';
import ModalRegistPrivacy from './modal/Modal_RegistPrivacy';
import './PrivacyTable.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const PrivacyTable: React.FC<Props> = ({ state, setState, loadHandler }): JSX.Element => {
  const { header, data } = state;

  const handlers = { refresh: loadHandler };

  return (
    <section id="root-guidance-privacy-table">
      <Controller
        title={
          <>
            <NavLink to="/root/terms-guidance-management/privacy">개인정보수집동의서</NavLink>
            <NavLink to="/root/terms-guidance-management/service">개인정보처리방침</NavLink>
          </>
        }
      >
        <Modal
          content={({ closeHandler }) => <ModalRegistPrivacy closeHandler={closeHandler} refresh={handlers.refresh} />}
        >
          <Button className="register" text="+ 등록" />
        </Modal>
      </Controller>
      <Table data={data} header={header}>
        <Table.Head>{({ value }) => value.title}</Table.Head>
        <Table.Body>
          {({ index }) => {
            const rowData = data[index];
            return (
              <Table.Row key={rowData.id} styles={{ height: rowData.expand ? 'auto' : 72 }}>
                <Table.Tr index={index} style={{ height: 72 }}>
                  {({ value, property }) => {
                    if (property === 'expand')
                      return (
                        <i
                          className={classNames('expand-icon', { expanded: rowData.expand })}
                          onClick={() => setState({ type: 'expand', payload: index })}
                        />
                      );
                    return value || '-';
                  }}
                </Table.Tr>
                {rowData.expand && <Expand rowData={rowData} handlers={handlers} />}
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
    </section>
  );
};

export default PrivacyTable;
