import React, { useState, Dispatch } from 'react';
import { Modal, Alert } from 'common/components';
import { api, process } from 'common/functions';
import PortalFail from '../modal/Portal_Fail';
import ModalAdminDeny from './modal/Modal_AdminDeny';
import type { State as TableState, Action as TableAction } from '../../CtAdminTable';
import './Approval.scss';

interface Props {
  state: TableState;
  index: number;
  setState: Dispatch<TableAction>;
  refresh: () => void;
}

const Approval: React.FC<Props> = (props) => {
  const { set } = Alert.Context();
  const [fail, setFail] = useState<null | { current_count: number; max_count: number }>(null);
  const { customer_id } = props.state;
  const rowData = props.state.data[props.index];

  const approvalHandler = () => {
    process(
      api.reqData({ url: `customers/${customer_id?.value}/admins/${rowData.id}/approve`, data: { approve: true } }),
      api.put.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { current_count, max_count } = response.data;
          const { result_code } = response.data.api_response;
          if (result_code === 'N100') {
            set({ success: '어드민 신청이 수락되었습니다.' });
            props.refresh();
          }
          if (result_code === 'F607') setFail({ current_count, max_count });
        }
      })
    );
  };

  return (
    <div className="approval">
      <p className="approve" onClick={approvalHandler}>
        수락
      </p>
      {fail && (
        <Modal.Portal setToggle={() => setFail(null)}>
          {({ closeHandler }) => <PortalFail {...props} fail={fail} approval closeHandler={closeHandler} />}
        </Modal.Portal>
      )}
      <p className="vertical-line" />
      <Modal content={({ closeHandler }) => <ModalAdminDeny {...props} closeHandler={closeHandler} />}>
        <p className="reject">거절</p>
      </Modal>
    </div>
  );
};

export default Approval;
