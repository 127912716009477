import React, { Dispatch, useReducer } from 'react';
import { produce } from 'immer';
import classNames from 'classnames';
import { modal } from 'layout';
import { Validator, Input, LinkedList } from 'common/components';
import { CustomField } from '../../context/CustomFieldContext';
import './Modal_CustomFieldAdd.scss';

export type TreeNode = { name: string; tree: TreeNode[] };

type Action = { type: 'name'; payload: string } | { type: 'tree'; payload: TreeNode[] };
type State = {
  name: string;
  tree: TreeNode[];
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'name':
        draft['name'] = action.payload;
        break;
      case 'tree':
        draft['tree'] = action.payload;
        break;
    }
  });
};

interface ModalTreeProps {
  closeHandler: () => void;
  state: State;
  setState: Dispatch<Action>;
}
function Modal_TreeItem(props: ModalTreeProps) {
  const { closeHandler, state, setState } = props;
  const { name, tree } = state;

  return (
    <modal.Container id="modal-customfield-direct-dropdown" styles={{ width: 1016 }}>
      <modal.Header>객관식 수정</modal.Header>
      <modal.Content>
        <div className="condition">
          <div className="item">
            <h4>항목</h4>
            <span>{name}</span>
          </div>
        </div>
        <div className={classNames('category__container', { disabled: !name.length })}>
          <LinkedList.Provider
            tree={tree}
            next={[{ title: '대분류' }, { title: '중분류' }, { title: '소분류' }]}
            onChange={(data) => setState({ type: 'tree', payload: data })}
          >
            <LinkedList.Consumer>
              {({ tree }) => {
                const [root] = tree;
                return <LinkedList tree={root.tree} parent={root} />;
              }}
            </LinkedList.Consumer>
          </LinkedList.Provider>
        </div>
      </modal.Content>
      <modal.Footer closeHandler={closeHandler} submitText="수정" disabled={!name.length || !tree.length} />
    </modal.Container>
  );
}

interface ModalOXrops {
  closeHandler: () => void;
  state: State;
  setState: Dispatch<Action>;
}
function Modal_OXItem(props: ModalOXrops) {
  const { closeHandler, state, setState } = props;
  const { name, tree } = state;
  const [o1, o2] = tree;
  const isDuplicatedItem = !!o1?.name.length && !!o2?.name.length && o1.name === o2.name;
  const itemErrorMessage = isDuplicatedItem ? '다른 선택지를 입력해주세요' : undefined;

  return (
    <modal.Container id="modal-customfield-direct-yesno" styles={{ width: 700 }}>
      <modal.Header>YES / NO 수정</modal.Header>
      <modal.Content>
        <div className="condition">
          <div className="item">
            <h4>항목</h4>
            <span>{name}</span>
          </div>
        </div>
        <div className={classNames('category__container', { disabled: !name.length })}>
          <div className="category">
            <h4>선택1</h4>
            <Input
              type="text"
              className="basic"
              placeholder="선택지를 입력하세요."
              error={itemErrorMessage}
              value={o1?.name}
              onChange={(e) => {
                const { value } = e.target;
                setState({ type: 'tree', payload: [{ name: value, tree: [] }, o2] });
              }}
            />
          </div>
          <div className="category">
            <h4>선택2</h4>
            <Input
              type="text"
              className="basic"
              placeholder="선택지를 입력하세요."
              error={itemErrorMessage}
              value={o2?.name}
              onChange={(e) => {
                const { value } = e.target;
                setState({ type: 'tree', payload: [o1, { name: value, tree: [] }] });
              }}
            />
          </div>
        </div>
      </modal.Content>
      <modal.Footer
        closeHandler={closeHandler}
        submitText="수정"
        disabled={!name.length || !o1?.name.length || !o2?.name.length || isDuplicatedItem}
      />
    </modal.Container>
  );
}

interface Props {
  closeHandler: () => void;
  submitHandler: (treeItem: { name: string; type: 'tree' | 'ox' | ''; tree: TreeNode[] }) => void;
  field: CustomField;
}
function Modal_CustomFieldEdit(props: Props) {
  const { closeHandler, submitHandler, field } = props;
  const initData = { name: field.name, tree: field.tree ?? [] };
  const [state, setState] = useReducer(reducer, initData);

  const onSubmit = () => {
    submitHandler({ ...state, type: field.type as 'tree' | 'ox' | '' });
    closeHandler();
  };

  const Modal_Content = field.type === 'tree' ? Modal_TreeItem : Modal_OXItem;

  return (
    <Validator.Provider onSubmit={onSubmit}>
      <Modal_Content closeHandler={closeHandler} state={state} setState={setState} />
    </Validator.Provider>
  );
}

export default Modal_CustomFieldEdit;
