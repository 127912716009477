import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Provider } from 'react-redux';
import { reviewer } from 'store';
import { Reviewer } from 'pages';
import * as layout from 'layout';
import { Authentication, InterCepter } from 'hoc/reviewer';
import MyBox from 'layout/page/header/mybox/client/Popover_MyBox';
import TempPassModifyPage from 'pages/tempPassModify/TempPassModifyPage';

export default {
  path: '/reviewer',
  element: (
    <Provider store={reviewer.store}>
      <InterCepter>
        <Outlet />
      </InterCepter>
    </Provider>
  ),
  children: [
    { index: true, element: <Reviewer.LoginPage /> } /* login page */,
    { path: 'temp-pass-modify/:role', element: <TempPassModifyPage /> },
    {
      path: '*',
      element: (
        <Authentication>
          <layout.Page
            myBox={MyBox}
            links={[
              { url: '/reviewer/evaluation-review', text: '심사 수행' },
              { url: '/reviewer/evaluation-result', text: '심사 결과' },
            ]}
          />
        </Authentication>
      ),
      children: [
        {
          path: 'evaluation-review',
          element: <layout.reviewer.Content />,
          children: [{ index: true, element: <Reviewer.ReviewPage /> }],
        },
        {
          path: 'evaluation-result',
          element: <layout.reviewer.Content />,
          children: [{ index: true, element: <Reviewer.ResultPage /> }],
        },
        { path: '*', element: <Navigate to="evaluation-review" /> },
      ],
    },
  ],
};
