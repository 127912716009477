import React, { useReducer, Dispatch } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { produce } from 'immer';
import { NullishGuard } from 'hoc';
import { Validator } from 'common/components';
import { findCustomer, api, process, delayed } from 'common/functions';
import { regExp } from 'common/validations';
import { InputCheck } from '@Icons';
import { Box, Form } from '@usebeauty';
import type { FindType } from '../FindAccountPage';
import Result from './result/Result';
import './FindId.scss';

export type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'result'; payload: { username: string; done: boolean } }
  | { type: 'customerLoading'; payload: boolean }
  | { type: 'customerId'; payload: number };

interface State {
  loading: boolean;
  type: '아이디 찾기' | '비밀번호 찾기';
  result: { username: string; done: boolean };
  customerLoading: boolean;
  customerId: number;
}

const initialState: State = {
  loading: false,
  type: '아이디 찾기',
  result: { username: '', done: false },
  customerLoading: false,
  customerId: -1,
};

const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  // prettier-ignore
  return produce(state, (draft) => {
    switch (type) {
      case 'loading': draft['loading'] = payload; break;
      case 'result': draft['result'] = payload; break;
      case 'customerLoading': draft['customerLoading'] = payload; break;
      case 'customerId': draft['customerId'] = payload; break;

    }
  });
};

interface Props {
  children: React.ReactNode;
  setFindType: Dispatch<FindType>;
}

const FindId: React.FC<Props> = ({ children, setFindType }) => {
  const location = useLocation();
  const { pathname } = location;
  const isReviewer = pathname.includes('reviewer');

  const [state, setState] = useReducer(reducer, initialState);
  const { loading, result, customerLoading, customerId } = state;

  const {
    control,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: { customer_name: '', name: '', email: '' },
  });

  watch();

  const submitHandler = async () => {
    // 고객사가 존재할때
    if (customerId > -1) {
      setState({ type: 'loading', payload: true });
      const params = {
        customer_id: customerId,
        name: getValues('name'),
        email: getValues('email'),
        role: isReviewer ? 1 : 0,
      };
      process(
        api.reqData({ url: 'find/id', data: params }),
        api.post.request,
        api.fullFilled(({ response }) => {
          const payload = { username: '', done: true };
          if (response) payload.username = response.data.username;
          setState({ type: 'result', payload });
          setState({ type: 'loading', payload: false });
        })
      );
    }
  };

  const clientCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ type: 'customerLoading', payload: true });
    const { value } = e.target;
    delayed.cancel();
    if (value) {
      delayed(async () => {
        const result = await findCustomer({ customer_name: value });
        setState({ type: 'customerId', payload: result.id });
        if (errors.customer_name) clearErrors('customer_name');

        if (result.id === -1) {
          setError('customer_name', { message: '등록되지 않은 고객사명입니다.' });
          setState({ type: 'customerId', payload: 0 });
        }
        setState({ type: 'customerLoading', payload: false });
      });
    } else {
      setState({ type: 'customerLoading', payload: false });
    }
  };

  const disabledClient = !!errors.customer_name || !getValues('customer_name') || customerLoading;

  return (
    <>
      <NullishGuard data={[result.done]}>
        <Result username={result.username} setState={setState} setFindType={setFindType} />
      </NullishGuard>

      <NullishGuard data={[!result.done]}>
        <Validator.Provider id="kyc-find-id-form-field" form onSubmit={submitHandler}>
          {children}
          <div className="customer-input">
            <Form.Input
              st={{ marginBottom: 10 }}
              name="customer_name"
              control={control}
              placeholder="고객사명을 입력하세요."
              onChange={clientCheckHandler}
              rules={{
                validate: {
                  unRegisteredCustomer: () => {
                    if (!getValues('customer_name').length) return true;
                    return customerId === 0 ? '등록되지 않은 고객사명입니다.' : true;
                  },
                },
              }}
            />
            {!disabledClient && <InputCheck className="check" />}
          </div>

          <Box st={{ marginTop: 16 }}>
            <Form.Input
              name="name"
              control={control}
              placeholder="이름"
              rules={{
                pattern: {
                  value: regExp.name,
                  message: '한글, 영문 대/소문자, 빈칸만 사용 가능합니다.',
                },
              }}
            />
          </Box>

          <Box st={{ marginTop: 16 }}>
            <Form.Input
              name="email"
              control={control}
              placeholder="이메일"
              rules={{
                pattern: {
                  value: regExp.email,
                  message: '입력 형식이 올바르지 않습니다.',
                },
              }}
            />
          </Box>

          <Validator.Submit
            text="다음"
            disabled={disabledClient || !getValues('name') || !getValues('email') || !!errors.email}
            loading={loading}
            styles={{ width: '100%', height: 56, radius: 8 }}
          />
        </Validator.Provider>
      </NullishGuard>
    </>
  );
};

export default FindId;
