import React, { useContext, useEffect, useReducer } from 'react';
import { useApiCancel } from 'common/hooks';
import { produce } from 'immer';
import { api, process } from 'common/functions';
import { ContentContext } from 'layout/admin/content/Content';
import AuthenticateCardGroup from './AuthenticateCardGroup';

export type Data = { identification: boolean; hmac: boolean; account: boolean; max_change_account: number };
export type Action = { type: 'setData'; payload: Data } | { type: 'loading'; payload: boolean };
type Config = { key: string; value: string };
type Configs = {
  sms_notification: boolean;
  max_change_account: number;
  email_notification: boolean;
  identification: boolean;
  custom_end_message: string;
  allow_change_account: boolean;
  use_custom_end_message: boolean;
};

export interface State {
  configs: Data;
  loading: boolean;
}

const initialConfigs: Configs = {
  sms_notification: false,
  max_change_account: 1,
  email_notification: false,
  identification: false,
  custom_end_message: '',
  allow_change_account: false,
  use_custom_end_message: false,
};
const initialState: State = {
  configs: {
    identification: false,
    hmac: false,
    account: false,
    max_change_account: 0,
  },
  loading: false,
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'loading':
        draft['loading'] = action.payload;
        break;
      case 'setData':
        draft['configs'] = action.payload;
        break;
    }
  });
};

const CtAuthenticateCardGroup: React.FC = () => {
  const { setLoading } = useContext(ContentContext);

  const [state, setState] = useReducer(reducer, initialState);
  useApiCancel(['configs']);
  useApiCancel(['secrets/hmac']);

  const loadHandler = async () => {
    setLoading(true);
    const getData = (url: string) => {
      return process(
        api.reqData({ url }),
        api.get.request,
        api.fullFilled(({ response }) => response)
      );
    };

    const queue = [getData('configs'), getData('secrets/hmac')];
    const [configs, hmac] = await Promise.all(queue);
    const configObj = configs.data.list.reduce((acc: Config, cur: Config) => ({ ...acc, [cur.key]: cur.value }), {
      ...initialConfigs,
    });

    setState({
      type: 'setData',
      payload: {
        identification: configObj.identification,
        hmac: hmac.data.is_exist,
        account: configObj.allow_change_account,
        max_change_account: configObj.max_change_account,
      },
    });
    setState({ type: 'loading', payload: false });
    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return <AuthenticateCardGroup state={state} setState={setState} loadHandler={loadHandler} />;
};

export default CtAuthenticateCardGroup;
