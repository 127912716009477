import React, { Dispatch, useReducer } from 'react';
import { produce } from 'immer';
import classNames from 'classnames';
import { modal } from 'layout';
import { Validator, Input, LinkedList } from 'common/components';
import { excutions } from 'common/validations';
import { CustomField } from '../../context/CustomFieldContext';
import './Modal_CustomFieldAdd.scss';

export type TreeNode = { name: string; tree: TreeNode[] };

type Action = { type: 'name'; payload: string } | { type: 'tree'; payload: TreeNode[] };

type State = {
  name: string;
  tree: TreeNode[];
};

const initialTreeState: State = {
  name: '',
  tree: [],
};
const initialOXState: State = {
  name: '',
  tree: [
    { name: '네', tree: [] },
    { name: '아니오', tree: [] },
  ],
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'name':
        draft['name'] = action.payload;
        break;
      case 'tree':
        draft['tree'] = action.payload;
        break;
    }
  });
};

interface ModalTreeProps {
  closeHandler: () => void;
  state: State;
  setState: Dispatch<Action>;
  customFieldNames: string[];
}
function Modal_TreeItem(props: ModalTreeProps) {
  const { closeHandler, state, setState, customFieldNames } = props;
  const { name, tree } = state;

  const regResult = excutions.fieldName.regExp(name as string);
  const isDuplicatedName = !!name && customFieldNames.includes(name);
  const errorMessage = (() => {
    if (regResult) return '한글, 영문 대/소문자, 숫자, 특수문자(-_().,), 빈칸만 사용 가능합니다.';
    if (isDuplicatedName) return '이미 등록된 항목명입니다.';
    return undefined;
  })();

  return (
    <modal.Container id="modal-customfield-direct-dropdown" styles={{ width: 1016 }}>
      <modal.Header>객관식 추가</modal.Header>
      <modal.Content>
        <div className="condition">
          <div className="item">
            <h4>항목</h4>
            <Input
              type="text"
              className="basic"
              styles={{ width: 300 }}
              placeholder="항목을 입력하세요."
              maxLength={20}
              error={errorMessage}
              value={name}
              onChange={(e) => {
                const { value } = e.target;
                setState({ type: 'name', payload: value });
              }}
            />
          </div>
        </div>
        <div className={classNames('category__container', { disabled: !name.length })}>
          <LinkedList.Provider
            tree={tree}
            next={[{ title: '대분류' }, { title: '중분류' }, { title: '소분류' }]}
            onChange={(data) => setState({ type: 'tree', payload: data })}
          >
            <LinkedList.Consumer>
              {({ tree }) => {
                const [root] = tree;
                return <LinkedList tree={root.tree} parent={root} />;
              }}
            </LinkedList.Consumer>
          </LinkedList.Provider>
        </div>
      </modal.Content>
      <modal.Footer
        closeHandler={closeHandler}
        submitText="추가"
        disabled={!name.length || !tree.length || regResult || isDuplicatedName}
      />
    </modal.Container>
  );
}

interface ModalOXProps {
  closeHandler: () => void;
  state: State;
  setState: Dispatch<Action>;
  customFieldNames: string[];
}
function Modal_OXItem(props: ModalOXProps) {
  const { closeHandler, state, setState, customFieldNames } = props;
  const { name, tree } = state;
  const [o1, o2] = tree;

  const regResult = excutions.fieldName.regExp(name as string);
  const isDuplicatedName = !!name && customFieldNames.includes(name);
  const isDuplicatedItem = !!o1?.name.length && !!o2?.name.length && o1.name === o2.name;
  const nameErrorMessage = (() => {
    if (regResult) return '한글, 영문 대/소문자, 숫자, 특수문자(-_().,), 빈칸만 사용 가능합니다.';
    if (isDuplicatedName) return '이미 등록된 항목명입니다.';
    return undefined;
  })();

  const itemErrorMessage = isDuplicatedItem ? '다른 선택지를 입력해주세요' : undefined;

  return (
    <modal.Container id="modal-customfield-direct-yesno" styles={{ width: 700 }}>
      <modal.Header>YES / NO 추가</modal.Header>
      <modal.Content>
        <div className="condition">
          <div className="item">
            <h4>항목</h4>
            <Input
              type="text"
              className="basic"
              placeholder="항목을 입력하세요."
              maxLength={20}
              error={nameErrorMessage}
              value={name}
              onChange={(e) => {
                const { value } = e.target;
                setState({ type: 'name', payload: value });
              }}
            />
          </div>
        </div>
        <div className={classNames('category__container', { disabled: !name.length })}>
          <div className="category">
            <h4>선택1</h4>
            <Input
              type="text"
              className="basic"
              placeholder="선택지를 입력하세요."
              error={itemErrorMessage}
              value={o1?.name || ''}
              onChange={(e) => {
                const { value } = e.target;
                setState({ type: 'tree', payload: [{ name: value, tree: [] }, o2] });
              }}
            />
          </div>
          <div className="category">
            <h4>선택2</h4>
            <Input
              type="text"
              className="basic"
              placeholder="선택지를 입력하세요."
              error={itemErrorMessage}
              value={o2?.name || ''}
              onChange={(e) => {
                const { value } = e.target;
                setState({ type: 'tree', payload: [o1, { name: value, tree: [] }] });
              }}
            />
          </div>
        </div>
      </modal.Content>
      <modal.Footer
        closeHandler={closeHandler}
        submitText="추가"
        disabled={
          !name.length || !o1?.name.length || !o2?.name.length || regResult || isDuplicatedName || isDuplicatedItem
        }
      />
    </modal.Container>
  );
}

interface Props {
  closeHandler: () => void;
  submitHandler: (treeItem: { name: string; type: 'tree' | 'ox' | ''; tree: TreeNode[] }) => void;
  type?: 'tree' | 'ox';
  field: CustomField;
  customFieldNames: string[];
}
function Modal_CustomFieldAdd(props: Props) {
  const { closeHandler, submitHandler, type = '', field, customFieldNames } = props;
  const initData =
    type === 'tree'
      ? { name: field.name, tree: field.tree ?? initialTreeState.tree }
      : { name: field.name, tree: field.tree ?? initialOXState.tree };
  const [state, setState] = useReducer(reducer, initData);

  const onSubmit = () => {
    submitHandler({ ...state, type });
    closeHandler();
  };

  const Modal_Content = type === 'tree' ? Modal_TreeItem : Modal_OXItem;

  return (
    <Validator.Provider onSubmit={onSubmit}>
      <Modal_Content
        closeHandler={closeHandler}
        state={state}
        setState={setState}
        customFieldNames={customFieldNames}
      />
    </Validator.Provider>
  );
}

export default Modal_CustomFieldAdd;
