import React from 'react';
import { EddField } from '../../../Expand';

const getValue = (type: string, value: string) => {
  if (type === 'tree' && value) {
    const trees = JSON.parse(value);
    return trees.join(' - ');
  }
  if (type === 'ox_addr' && value) {
    const [answer, country, ...address] = JSON.parse(value);
    return [answer, country, address.join(' ')];
  }
  return value;
};

function Edd({ edd, isExpired }: { edd: EddField[]; isExpired: boolean }) {
  return (
    <article className="reviewer-review-edd">
      <h4>EDD (강화된 고객 확인)</h4>
      <ul className="info-section">
        {edd.map((field, index) => {
          const { key, type } = field;
          const value = isExpired ? '만료됨' : getValue(type, field.value);
          return (
            <li key={`${field.key}_${index}`}>
              <div className="label">
                <h4>{key}</h4>
              </div>
              {type === 'ox_addr' ? (
                <div className="value">
                  {isExpired ? (
                    '만료됨'
                  ) : (
                    <>
                      <p>{value[0]}</p>
                      <p>{`${value[1]}, ${value[2]}`}</p>
                    </>
                  )}
                </div>
              ) : (
                <div className="value">{value}</div>
              )}
            </li>
          );
        })}
      </ul>
    </article>
  );
}

export default Edd;
