import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { api, process } from 'common/functions';
import { Button, Modal } from 'common/components';
import { useEddConext } from '../../../context/EddContext';
import { EddRa } from '../../../context/model.type';
import Modal_RaNationMode from '../../settings/modal/Modal_RaNationMode';
import NationRiskItem from '../../settings/nationRisk/NationRiskItem';
import './CountryRiskDetail.scss';

const RiskContent = styled.div`
  transition: max-height 0.3s ease, padding 0.3s ease;
  border-radius: 8px;
}}
`;

const get = (url: string) => {
  return process(
    api.reqData({ url }),
    api.get.request,
    api.fullFilled(({ response }) => {
      if (response?.data.list) return response?.data.list;
      if (response?.data.value) return response?.data.value;
      return undefined;
    })
  );
};

function CountryRiskDetail() {
  const { edit, state, setState, use_ra } = useEddConext();
  const { country, edd_countries_mode } = state.data;

  const updateRaItem = (nextData: EddRa.Country[]) => {
    setState({ type: 'country', payload: nextData });
  };

  const handleEddCountryModeChange = async (value: number) => {
    if (value !== edd_countries_mode) {
      setState({ type: 'edd_countries_mode', payload: value });
      const country = value === 1 ? await get('country?warn_only=true') : await get('edd_countries');
      setState({ type: 'country', payload: country });
    }
  };

  return (
    <article className="edd-setting-country-risk-detail">
      <div className="title">
        <i onClick={() => setState({ type: 'detailPage', payload: 'none' })} />
        <h4>국가 위험</h4>
        <div className="score">
          {use_ra && (
            <>
              <div>
                <span />
                <span>100점</span>
              </div>
              <div />
            </>
          )}
        </div>
      </div>
      <RiskContent>
        <div className="nation-risk-type">
          {edit ? (
            <div className="radio__group">
              <Button
                type="button"
                disabled={!edit}
                className={classNames('radio__item', { checked: edd_countries_mode === 1 })}
                onClick={() => handleEddCountryModeChange(1)}
              >
                <div>
                  <i />
                  <span>자동 갱신</span>
                </div>
                <p>최신 글로벌 기준에 맞춰 자동으로 국가와 점수가 갱신됩니다</p>
              </Button>

              <Modal
                content={({ closeHandler }) => (
                  <Modal_RaNationMode
                    closeHandler={closeHandler}
                    cancelHandler={() => handleEddCountryModeChange(1)}
                    submitHandler={() => handleEddCountryModeChange(2)}
                  />
                )}
              >
                <Button type="button" className={classNames('radio__item', { checked: edd_countries_mode === 2 })}>
                  <div>
                    <i />
                    <span>직접 설정</span>
                  </div>
                  <p>위험국가를 추가/삭제가 가능하고 개별 점수를 설정할 수 있습니다</p>
                </Button>
              </Modal>
            </div>
          ) : (
            <div className="info">
              <h4>{edd_countries_mode === 1 ? '자동 갱신' : '직접 설정'}</h4>
              <span>
                {edd_countries_mode === 1
                  ? '최신 글로벌 기준에 맞춰 자동으로 국가와 점수가 갱신됩니다'
                  : '위험국가를 추가/삭제가 가능하고 개별 점수를 설정할 수 있습니다'}
              </span>
            </div>
          )}
        </div>
        <div className="item-container">
          <div className="item-list">
            <NationRiskItem
              use_ra={use_ra}
              edd_country_mode={edd_countries_mode}
              item={country}
              edit={edit}
              updateRaItem={updateRaItem}
            />
          </div>
        </div>
      </RiskContent>
    </article>
  );
}

export default CountryRiskDetail;
