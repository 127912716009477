import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { map, omit, reduce, toPairs } from 'lodash';
import { Alert, Button, Modal } from 'common/components';
import { api, process } from 'common/functions';
import { NullishGuard } from 'hoc';
import { ContentContext } from 'layout/root/content/Content';
import type { Data as RowData } from '../CtClientTable';
import EditInfoModal from './modal/EditInfoModal';
import LinkedAccountModal from './modal/LinkedAccountModal';
import Modal_UsebToken from './modal/Modal_UsebToken';
import Addons from './Addons/Addons';
import Aside from './aside/Aside';
import Setting from './settings/Settings';
import './Expand.scss';

export type Detail = {
  active: boolean;
  id: number;
  industry: number;
  max_admin: number;
  max_reviewer: number;
  name: string;
  phone_number: string;
  purchase_type: number;
  status: number;
};

export type Config = {
  identification: boolean;
  sms_notification: boolean;
  email_notification: boolean;
  save_original_id_image: boolean;
  manual_upload_id_pc: boolean;
  manual_upload_id_mobile: boolean;
  manual_input_mobile: boolean;
  reuse_limit_minute: number;
  useb_basic_auth: string;
  use_custom_end_message: boolean;
  custom_end_message: string;
  allow_account_subcode: boolean;
  use_name_verification: boolean;
  allow_change_account: boolean;
  max_change_account: number;
  allow_reuse: boolean;
  allow_custom_theme: boolean;
  use_idcard_face_detect: boolean;
  allow_ra: boolean;
  allow_kyb: boolean;
  allow_wasm_ocr: boolean;
  allow_wasm_ssa: boolean;
  allow_manual_shot: boolean;
};

export type IdCard = {
  'korean-registration': { active: boolean; description: '주민등록증' };
  'korean-driver': { active: boolean; description: '운전면허증' };
  'korean-passport': { active: boolean; description: '한국여권' };
  'foreign-passport': { active: boolean; description: '외국여권' };
  'foreign-registration': { active: boolean; description: '외국인등록증' };
};

export type Module = {
  account_verification: boolean;
  face_authentication: boolean;
  id_card_ocr: boolean;
  id_card_verification: boolean;
  liveness: boolean;
  custom_field: boolean;
  edd_field: boolean;
  riskAssesment: boolean;
};

export type Data = { detail: Detail; config: Config; idCard: IdCard; module: Module };

export type Action =
  | { type: 'setData'; payload: Data }
  | { type: 'module'; payload: Partial<Module> }
  | { type: 'riskAssesment'; payload: boolean }
  | { type: 'idCard'; payload: Partial<IdCard> }
  | { type: 'config'; payload: Partial<Config> }
  | { type: 'detail'; payload: Partial<Detail> }
  | { type: 'edit'; payload: boolean };

export interface State {
  data: Data;
  defaultData: Data;
  subscription: [string[], string[]];
  edit: boolean;
}

const initDetail: Detail = {
  active: false,
  id: -1,
  industry: -1,
  max_admin: -1,
  max_reviewer: -1,
  name: '',
  phone_number: '',
  purchase_type: -1,
  status: -1,
};

export const initConfig: Config = {
  identification: false,
  sms_notification: false,
  email_notification: true,
  save_original_id_image: false,
  manual_upload_id_mobile: false,
  manual_input_mobile: false,
  manual_upload_id_pc: false,
  reuse_limit_minute: 1440,
  useb_basic_auth: 'default',
  use_custom_end_message: false,
  custom_end_message: '',
  allow_account_subcode: false,
  use_name_verification: false,
  allow_change_account: true,
  max_change_account: 1,
  allow_reuse: false,
  allow_custom_theme: false,
  use_idcard_face_detect: false,
  allow_ra: false,
  allow_kyb: false,
  allow_wasm_ocr: false,
  allow_wasm_ssa: false,
  allow_manual_shot: false,
};

export const initIdCard: IdCard = {
  'korean-registration': { description: '주민등록증', active: false },
  'korean-driver': { description: '운전면허증', active: false },
  'korean-passport': { description: '한국여권', active: false },
  'foreign-passport': { description: '외국여권', active: false },
  'foreign-registration': { description: '외국인등록증', active: false },
};

const initModule: Module = {
  account_verification: false,
  face_authentication: false,
  id_card_ocr: false,
  id_card_verification: false,
  liveness: false,
  custom_field: false,
  edd_field: false,
  riskAssesment: false,
};

const initData = {
  detail: initDetail,
  config: initConfig,
  idCard: initIdCard,
  module: initModule,
};
const initialState: State = { data: initData, defaultData: initData, subscription: [[], []], edit: false };
const reducer = (state: State, action: Action) => {
  const reducing = (acc: [string[], string[]], [key, value]: [string, boolean]) => {
    if (!value) return acc;
    if (key === 'id_card_ocr') {
      acc[0].push('신분증인증');
      acc[1].push('신분증OCR');
    }
    if (key === 'id_card_verification') {
      acc[1].push('진위확인');
    }
    if (key === 'account_verification') {
      acc[0].push('계좌 인증');
      acc[1].push('1원 계좌인증');
    }
    if (key === 'face_authentication') {
      acc[0].push('얼굴 인증');
      acc[1].push('얼굴 인증');
    }
    if (key === 'liveness') acc[1].push('Liveness');
    return acc;
  };
  // prettier-ignore
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData':
        draft['defaultData'] = action.payload;
        draft['data'] = action.payload;
        draft['subscription'] = reduce(toPairs(action.payload.module || {}),reducing,[[], []]);
      break;
      case 'module': 
        if(draft.data) {
          const assign = {...draft.data.module, ...action.payload}
          // 신분증 인증,. 얼굴인증, 계좌인증 하나라도 선택이 되어있지 않다면 사용여부 비활성화
          if(!assign.id_card_ocr && !assign.face_authentication && !assign.account_verification) {
            assign.edd_field = false;
            assign.custom_field = false;
          }
          if (!assign.edd_field && !assign.custom_field) {
            // 다른 모듈에 의해 고객위험평가가 비활성화 될 때 config/allow_ra 도 함께 비활성화되어야함
            assign.riskAssesment = false;
            draft.data['config']['allow_ra'] = false;
          }
          draft.data['module'] = assign;
        } break;
      case 'riskAssesment': 
        draft.data['module']['riskAssesment'] = action.payload;
        break;
      case 'idCard': if(draft.data) draft.data['idCard'] = {...draft.data.idCard, ...action.payload}; break;
      case 'config': if(draft.data) draft.data['config'] = {...draft.data.config, ...action.payload}; break;
      case 'detail': if(draft.data) draft.data['detail'] = {...draft.data.detail, ...action.payload}; break;
      case 'edit':
        draft['edit'] = action.payload;
        if(!action.payload) draft['data'] = draft.defaultData;
        break;
    }
  });
};

interface Props {
  rowData: RowData;
  loadHandler: () => void;
}

const Expand: React.FC<Props> = ({ rowData, loadHandler }) => {
  const { setLoading } = useContext(ContentContext);
  const { set } = Alert.Context();
  const [state, setState] = useReducer(reducer, initialState);
  const { detail, config, idCard, module } = state.data;
  const { id } = rowData;

  const p = (url: string) => {
    return process(
      api.reqData({ url }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response?.data.list) return response.data.list;
        if (response?.data.module) return response.data.module;
        if (response?.data.detail) return response.data.detail;
        return undefined;
      })
    );
  };

  const getExpandData = async () => {
    setLoading(true);
    const detail = await p(`customers/${id}`); // 고객사 최초 등록 후 상세보기하면 DB초기화 시작함. 초기화 완료 전에 다음 API호출하면 서버에러(E580) 발생.
    const queue = [p(`customers/${id}/configs`),p(`customers/${id}/idcards`),p(`modules/${id}`)]; // prettier-ignore
    const [rawConf, rawIdCard, module] = await Promise.all(queue);
    const config: Config = reduce(rawConf, (acc, cur) => ({ ...acc, [cur.key]: cur.value }), { ...initConfig }); // prettier-ignore
    const idCard: IdCard = reduce(rawIdCard, (acc, cur) => ({ ...acc, [cur.name]: omit(cur, 'name') }), { ...initIdCard }); // prettier-ignore
    setState({ type: 'setData', payload: { detail, config, idCard, module: { ...module, riskAssesment: module.edd_field || module.custom_field } } }); // prettier-ignore
    setLoading(false);
  };

  useEffect(() => {
    getExpandData();
  }, []);

  const submitHandler = async () => {
    const { data, defaultData } = state;
    if (data) {
      const putIdCard = (): Promise<void>[] | boolean => {
        const differ = api.differ(data.idCard, defaultData.idCard);
        if (differ) {
          return map(toPairs(differ), ([key, { active }]) => {
            const url = `customers/${id}/idcards/${key}/active`;
            return process(api.reqData({ url, data: { active } }), api.put.request);
          });
        }
        return false;
      };

      const putModule = (): Promise<void> | boolean => {
        const differ = api.differ(data.module, defaultData.module);
        const params = { customer_id: id, module: omit(data.module, 'riskAssesment') };
        if (differ) return process(api.reqData({ url: 'modules', data: params }), api.put.request);
        return differ;
      };

      const putConfig = (): Promise<void> | boolean => {
        const differ = api.differ(data.config, defaultData.config);

        const requestConfig: Config = Object.entries(data.config).reduce((acc, [key, value]) => {
          if (key === undefined || !Object.keys(initConfig).includes(key)) return acc;
          return { ...acc, [key]: value.toString() };
        }, {} as Config);

        if (differ)
          return process(api.reqData({ url: `customers/${id}/configs`, data: requestConfig }), api.put.request);
        return differ;
      };

      const putDetail = (): Promise<void> | boolean => {
        const differ = api.differ(data.detail, defaultData.detail);
        if (differ) return process(api.reqData({ url: `customers`, data: data.detail }), api.put.request);
        return differ;
      };

      await Promise.all([putIdCard(), putModule(), putConfig(), putDetail()].flat());
      await getExpandData();
      setState({ type: 'edit', payload: false });
      set({ success: '구독 정보 변경이 완료되었습니다.' });
    }
  };

  const len = state.subscription[0].length + state.subscription[1].length;

  return (
    <NullishGuard data={[detail, config, idCard, module]}>
      <section className="root-client-table-tr-expand">
        <div className="content-wrapper">
          <div className="content-header">
            <div className="title-info">
              <h4>구독정보</h4>
              {!!len && (
                <p>
                  <span>{state.subscription[0].join(' + ')}</span>
                  <span>({state.subscription[1].join(', ')})</span>
                </p>
              )}
            </div>
            <div className="util-buttons">
              <NullishGuard data={[!state.edit]}>
                <Button className="cancel" text="편집" onClick={() => setState({ type: 'edit', payload: true })} />
              </NullishGuard>

              <NullishGuard data={[state.edit]}>
                <Button className="cancel" text="취소" onClick={() => setState({ type: 'edit', payload: false })} />
                <Button className="submit" text="완료" onClick={submitHandler} />
              </NullishGuard>
            </div>
          </div>
          <div className="content-body">
            <Setting edit={state.edit} data={state.data as NonNullable<Data>} setState={setState} />
            <Addons edit={state.edit} data={state.data as NonNullable<Data>} setState={setState} />
            <Aside edit={state.edit} data={state.data as NonNullable<Data>} setState={setState} />
          </div>
        </div>
        <div className="content-footer">
          <Modal
            content={({ closeHandler }) => <Modal_UsebToken closeHandler={closeHandler} detail={state.data.detail} />}
          >
            <Button className="cancel" text="고객사 토큰 관리" />
          </Modal>
          <Modal
            content={({ closeHandler }) => (
              <LinkedAccountModal closeHandler={closeHandler} detail={state.data.detail} />
            )}
          >
            <Button className="cancel" text="연결 계정 관리" />
          </Modal>
          <Modal
            content={({ closeHandler }) => (
              <EditInfoModal closeHandler={closeHandler} detail={state.data.detail} loadHandler={loadHandler} />
            )}
          >
            <Button className="cancel" text="고객사 정보 수정" />
          </Modal>
        </div>
      </section>
    </NullishGuard>
  );
};

export default Expand;
