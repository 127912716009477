/* eslint-disable no-useless-escape */
export const regExp = {
  id: /^[a-zA-Z0-9]{8,20}$/,
  required: /^\s*$/,
  password: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{9,20}$/,
  accountPassword: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{9,20}$/,
  phone: /^[0-9-+]{1,20}$/,
  termsKey: /^[a-z0-9-]+$/,
  versionKey: /^[a-z0-9.]+$/,
  noDotAtTheEnd: /[^\.]$/,
  email: /^[a-z0-9\.\-_]+@([a-z0-9\-]+\.)+[a-z]{2,}$/,
  // name: /^[가-힣]+$/ || /^[a-zA-Z]+$/,
  fieldName: /^[a-zA-z0-9가-힣-_\(\)\.\,\s]{0,20}$/,
  name: /^[a-zA-Zㄱ-힣\s]*$/,
  clientName: /^[a-zA-Z가-힣0-9\s.',()+_-]*$/,
  character: /^[a-zA-Z가-힣]*$/,
};

// 룰 설정 ####################################
export const excutions = {
  required: {
    regExp: (value: string): boolean => !!value.match(regExp.required),
    message: '필수 정보입니다.',
  },
  id: {
    regExp: (value: string): boolean => !!value && !value.match(regExp.id),
    message: '8~20자의 영문 대, 소문자만 사용 가능합니다.',
  },
  passwordRequired: {
    regExp: (value: string): boolean => !!value.match(regExp.required),
    message: '새 비밀번호를 입력해 주세요. ',
  },
  password: {
    regExp: (value: string): boolean => !!value && !value.match(regExp.password),
    message: '비밀번호 조건에 부합하지 않습니다. 확인 후 수정해 주세요.',
  },
  accountPassword: {
    regExp: (value: string): boolean => !!value && !value.match(regExp.accountPassword),
    message: '9~20자로 영문 대/소문자, 숫자, 특수문자(!@#$%^&)를 포함하세요.',
  },
  phone: {
    regExp: (value: string): boolean => !!value && !value.match(regExp.phone),
    message: '숫자,특수문자(+-)만 사용 가능합니다.',
  },
  versionKey: {
    regExp: (value: string): boolean => !!value && !value.match(regExp.versionKey),
    message: '영문 소문자, 숫자, 특수문자(.)만 사용 가능합니다.',
  },
  noDotAtTheEnd: {
    regExp: (value: string): boolean => !!value && !value.match(regExp.noDotAtTheEnd),
    message: '입력 형식이 올바르지 않습니다.',
  },
  termsKey: {
    regExp: (value: string): boolean => !!value && !value.match(regExp.termsKey),
    message: '영문 소문자, 숫자, 특수문자(-)만 사용 가능합니다.',
  },
  email: {
    regExp: (value: string): boolean => !!value && !value.match(regExp.email),
    message: '입력 형식이 올바르지 않습니다.',
  },
  name: {
    regExp: (value: string): boolean => !!value && !value.match(regExp.name),
    message: '한글, 영문 대/소문자, 빈칸만 사용 가능합니다.',
  },
  clientName: {
    regExp: (value: string): boolean => !!value && !value.match(regExp.clientName),
    message: "한글, 영문 대/소문자, 숫자, 특수문자(+-_().'), 빈칸만 사용 가능합니다.",
  },
  fieldName: {
    regExp: (value: string): boolean => !!value && !value.match(regExp.fieldName),
    message: '한글, 영문 대/소문자, 숫자, 특수문자(-_().,), 빈칸만 사용 가능합니다.',
  },
  character: {
    regExp: (value: string): boolean => !!value && !value.match(regExp.character),
    message: '국문 또는 영문만 사용 가능합니다.',
  },
};
