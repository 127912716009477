import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { Controller } from 'common/components';
import './Manage.scss';

function Manage() {
  return (
    <div id="admin-manage-content-navigator">
      <Controller
        title={
          <>
            <NavLink to="/admin/manage/subscribe">구독 정보</NavLink>
            <NavLink to="/admin/manage/edd">고객 위험 평가</NavLink>
            <NavLink to="/admin/manage/authenticate">인증 방식</NavLink>
            <NavLink to="/admin/manage/theme">테마 설정</NavLink>
          </>
        }
      />
      <Outlet />
    </div>
  );
}

export default Manage;
