import React from 'react';
import { modal } from 'layout';
import { Button } from 'common/components';
import './Modal_CustomFieldActive.scss';

interface Props {
  closeHandler: () => void;
}
function Modal_CustomFieldActive(props: Props) {
  const { closeHandler } = props;

  return (
    <modal.Container id="modal-customfield-config">
      <modal.Header align="center">요청 사항 등록</modal.Header>
      <modal.Content>
        <div>
          <p>고객에게 추가로 요청할 질문들을</p>
          <p>
            아래의 '<span>직접 등록 항목</span>'에서 등록하신 후, 활성화하세요.
          </p>
        </div>
      </modal.Content>
      <div className="modal__footer">
        <Button type="button" text="확인" styles={{ radius: 0 }} className="footer__submit" onClick={closeHandler} />
      </div>
    </modal.Container>
  );
}

export default Modal_CustomFieldActive;
