import React, { SetStateAction, Dispatch } from 'react';
import { Button, Validator } from 'common/components';
import { useCustomContext } from '../../context/CustomFieldContext';
import '../Custom.scss';

interface Props {
  configEdit: boolean;
  edit: boolean;
  setEdit: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ configEdit, edit, setEdit }: Props) => {
  const { state, setState } = useCustomContext();
  const { data, defaultData } = state;
  // 신규항목의 입력조건 만족하는지 확인
  const requiredFulfilled =
    data.customFields.filter(
      (cf) =>
        (cf.isAppend && (cf.type === '' || cf.name === '' || cf.required === null)) ||
        cf.error.type ||
        cf.error.name ||
        cf.error.active ||
        cf.error.duplicate
    ).length === 0;

  const customFieldChanged =
    data.customFields.filter((cf, index) => JSON.stringify(cf) !== JSON.stringify(defaultData.customFields[index]))
      .length !== 0;

  const customFieldActive = !requiredFulfilled || !customFieldChanged;

  return (
    <div className="header">
      <div className="button-group">
        {!edit ? (
          !configEdit && <Button text="편집" onClick={() => setEdit(true)} />
        ) : (
          <>
            <Button
              text="취소"
              onClick={() => {
                setState({ type: 'setData', payload: defaultData });
                setState({ type: 'configs', payload: state.defaultConfigs });
                setEdit(false);
              }}
            />
            <Validator.Submit disabled={customFieldActive} text="저장" />
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
