import React, { Dispatch, useState } from 'react';
import classNames from 'classnames';
import { Alert, Button, Input, Popover, Radio, Validator } from 'common/components';
import { api, process } from 'common/functions';
import { Action as PropAction, State as PropState } from '../../CtSubscribeSetting';
import { useSubcode, useSubcodePosition } from './func';
import './AccountSubcode.scss';

interface Props {
  state: PropState;
  setState: Dispatch<PropAction>;
  loadHandler: () => void;
}
function AccountSubcode({ state, setState, loadHandler }: Props) {
  const { set } = Alert.Context();
  const { use_account_subcode, account_subcode, account_subcode_position } = state.vas;
  const [edit, setEdit] = useState(false);

  const { subcodePositionValue, bgPosition } = useSubcodePosition({ use_account_subcode, account_subcode_position });
  const { subcodeLeft, setSubcodeLeft, subcodeRight, setSubcodeRight } = useSubcode({ account_subcode, position: account_subcode_position }); // prettier-ignore

  const submitHandler = () => {
    setEdit(false);

    const data = { use_account_subcode: String(use_account_subcode), account_subcode_position, account_subcode };
    process(
      api.reqData({ url: 'configs', data }),
      api.put.request,
      api.fullFilled(() => {
        loadHandler();
        set({ success: `인증 문구 설정이 완료되었습니다.` });
      })
    );
  };

  const radioChange = (value: number) => {
    if (value === 1) {
      setSubcodeRight('');
      setState({ type: 'vas',payload: { use_account_subcode: true, account_subcode_position: 'left', account_subcode: '' } }); // prettier-ignore
    } else if (value === 2) {
      setSubcodeLeft('');
      setState({ type: 'vas', payload: { use_account_subcode: true, account_subcode_position: 'right', account_subcode: '' } }); // prettier-ignore
    } else {
      setSubcodeLeft('');
      setSubcodeRight('');
      setState({ type: 'vas', payload: { use_account_subcode: false, account_subcode: '' } });
    }
  };

  const { use_account_subcode: d_uas, account_subcode_position: d_uasp, account_subcode: d_as } = state.defaultVas;
  const isDirty =
    JSON.stringify({ use_account_subcode, account_subcode_position, account_subcode }) !==
    JSON.stringify({ use_account_subcode: d_uas, account_subcode_position: d_uasp, account_subcode: d_as });

  const disabled =
    !isDirty ||
    (use_account_subcode &&
      (account_subcode_position === 'left' || account_subcode_position === 'right') &&
      account_subcode.length === 0);

  const SubcodeInfo = () => {
    if (subcodePositionValue === 0) {
      // 사용 안 함
      return <span className="info">인증 문구 미사용시 인증 코드(숫자)만 표시됩니다.</span>;
    }
    if (subcodePositionValue === 2) {
      // 우측 표시
      return (
        <span className="info">
          ※ 금융기관 및 증권사별로 글자 제한수가 있어, <br />
          인증문구 일부가 나타나지 않을 수 있습니다. <br />
          (예: 감사합니다 → 감사합니)
        </span>
      );
    }
    return <></>;
  };

  return (
    <section id="subscribe-management-setting-account-subcode">
      <Validator.Provider onSubmit={submitHandler}>
        <div className="header">
          <div className="title">인증 문구</div>
          <Popover
            position="right center"
            clickOutside
            content={() => (
              <div id="admin-subscribe-subcode-popover-content" className="description">
                1원 계좌 인증 시 인증코드와 함께 표시되는 문구를 설정할 수 있습니다. 미사용시 인증코드만 표시됩니다.
              </div>
            )}
          >
            <i className="title-info" />
          </Popover>
        </div>
        <div className="content">
          <div className="radio">
            <Radio.Group value={subcodePositionValue} disabled={!edit} onChange={radioChange}>
              <Radio.Item text="좌측 표시" value={1}>
                {edit || subcodePositionValue === 1 ? (
                  <div>
                    <Input
                      styles={{ width: 97, height: 40, radius: 8 }}
                      validType="realtime"
                      rules={['character']}
                      disabled={!edit}
                      maxLength={2}
                      placeholder="최대 2글자"
                      value={subcodeLeft}
                      onFocus={() => radioChange(1)}
                      onChange={(e) => {
                        const { value } = e.target;
                        setSubcodeLeft(value);
                        setState({ type: 'vas', payload: { account_subcode: value } });
                      }}
                    />
                    + 인증번호
                  </div>
                ) : (
                  <></>
                )}
              </Radio.Item>
              <Radio.Item text="우측 표시" value={2}>
                {edit || subcodePositionValue === 2 ? (
                  <div>
                    인증번호 +
                    <Input
                      styles={{ width: 97, height: 40, radius: 8 }}
                      validType="realtime"
                      rules={['character']}
                      disabled={!edit}
                      maxLength={5}
                      placeholder="최대 5글자"
                      value={subcodeRight}
                      onFocus={() => radioChange(2)}
                      onChange={(e) => {
                        const { value } = e.target;
                        setSubcodeRight(value);
                        setState({ type: 'vas', payload: { account_subcode: value } });
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </Radio.Item>
              <Radio.Item text="사용 안 함" value={0} />
            </Radio.Group>
          </div>
          <div className={classNames(['preview', bgPosition])}>
            <span>{subcodePositionValue !== 0 ? account_subcode : ''}</span>
            <SubcodeInfo />
          </div>
        </div>
        <div className="button-group">
          {edit ? (
            <>
              <Button
                className="cancel"
                text="취소"
                onClick={() => {
                  setState({ type: 'vas', payload: state.defaultVas });
                  setEdit(false);
                }}
              />
              <Validator.Submit className="submit" text="완료" disabled={disabled} />
            </>
          ) : (
            <Button className="submit" text="편집" onClick={() => setEdit(true)} />
          )}
        </div>
      </Validator.Provider>
    </section>
  );
}

export default AccountSubcode;
