import React, { Dispatch, useState } from 'react';
import { modal } from 'layout';
import { NullishGuard } from 'hoc';
import { Alert } from 'common/components';
import { api, process } from 'common/functions';
import { Action, State } from '../CtAuthenticateCardGroup';
import './Hmac.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
  closeHandler: () => void;
}

const Hmac: React.FC<Props> = ({ state, setState, loadHandler, closeHandler }) => {
  const {
    configs: { hmac: isHmac },
    loading,
  } = state;
  const [hmac, setHmac] = useState<string>('');
  const [step, setStep] = useState<number>(0);
  const { set } = Alert.Context();

  const onCopy = () => {
    navigator.clipboard.writeText(hmac).then(() => {
      set({ success: 'HMAC Key가 복사되었습니다.' });
    });
  };

  const submitHandler = () => {
    setState({ type: 'loading', payload: true });
    process(
      api.reqData({ url: 'secrets/hmac' }),
      api.put.request,
      api.fullFilled(({ response }) => {
        setHmac(response?.data.value);
        setState({ type: 'loading', payload: false });
        set({ success: `HMAC Key가 ${!isHmac ? '생성' : '갱신'}되었습니다.` });
        setStep(1);
        return response;
      })
    );
  };

  return (
    <div id="hmac__modal__wrapper">
      <modal.Container>
        <modal.Header align="center">{isHmac ? 'HMAC Key 갱신' : 'HMAC Key 생성'}</modal.Header>
        <NullishGuard data={[step === 0]}>
          <modal.Content>
            <div className="step-1">
              <div className="icon" />
              <div className="detail">
                HMAC Key는 보안 코드 형태이며 인증 시 사용됩니다. 생성된 HMAC Key는
                <span className="accent">최초 1회만 확인</span>이 가능하므로 반드시 안전한 곳에 별도 보관해 주세요.
              </div>
            </div>
          </modal.Content>
          <modal.Footer submitText="사용" submitHandler={submitHandler} loading={loading} closeHandler={closeHandler} />
        </NullishGuard>

        <NullishGuard data={[step !== 0]}>
          <modal.Content>
            <div className="step-2">
              <div className="hmac">
                <div className="hmac-value">{hmac}</div>
                <button type="submit" onClick={onCopy}>
                  복사하기
                </button>
              </div>
              <div className="hmac-detail">
                보안을 위해 반드시 인쇄 또는 별도의 필기를 통해 보관해 주세요. PC 또는 휴대폰 기기, 메일함 등에 보관하실
                경우 해킹의 도구로 이용될 수 있습니다. 생성된 HMAC을 잃어버리면 복구할 수 있는 방법이 없으니 각별히
                유의하시기 바랍니다.
              </div>
            </div>
          </modal.Content>
          <div className="modal__footer">
            <button
              type="submit"
              className="hmac-submit-btn"
              onClick={() => {
                loadHandler();
                closeHandler();
              }}
            >
              네, 안전한 곳에 보관했습니다.
            </button>
          </div>
        </NullishGuard>
      </modal.Container>
    </div>
  );
};

export default Hmac;
