import React, { useEffect } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { Alert } from 'common/components';
import { useDispatch as aDispatch } from 'common/hooks/admin';
import { useDispatch as rDispatch } from 'common/hooks/reviewer';
import { authActions as adminActions } from 'store/actions/admin';
import { authActions as reviewerActions } from 'store/actions/reviewer';
import Header from './header/Header';
import './Page.scss';

interface Props {
  myBox: React.FC<{ closeHandler: () => void }>;
  links: { url: string; text: string }[];
}

const Page = (props: Props) => {
  const { pathname } = useLocation();
  const { links, myBox } = props;

  const adminDispatch = aDispatch();
  const reviewerDispatch = rDispatch();

  const location = pathname.split('/')[1];

  useEffect(() => {
    if (location === 'admin') adminDispatch(adminActions.my());
    if (location === 'reviewer') reviewerDispatch(reviewerActions.my());
  }, []);

  return (
    <Alert.Provider>
      <main id="kyc-page-layout">
        <Alert.Card />
        <Header links={links} myBox={myBox} />
        <Outlet />
      </main>
    </Alert.Provider>
  );
};

export default Page;
