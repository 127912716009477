import Unknown from 'pages/404/404';
import FindAccountPage from 'pages/findAccount/FindAccountPage';
import SignUpPage from 'pages/signup/SignUpPage';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import AdminRoute from './admin';
import ReviewerRoute from './reviewer';
import RootRoute from './root';

export default () =>
  useRoutes([
    // Root Pages #############################################################
    { path: '/sign-up/:role', element: <SignUpPage /> },
    { path: '/find-account/:role', element: <FindAccountPage /> },
    RootRoute,
    AdminRoute,
    ReviewerRoute,
    { path: '*', element: <Unknown /> },
  ]);
