import React, { useState, Dispatch } from 'react';
import { Action, Data } from '../../../../ThemeSettingContent';
import CustomChip from '../custom-chip/CustomChip';
import './ChipPlus.scss';

type ChipPlusProps = {
  setState: Dispatch<Action>;
  data: Data;
};

const ChipPlus = ({ setState, data }: ChipPlusProps) => {
  const [customIds, setCustomIds] = useState(data.custom_colors.length);

  const customPlus = () => {
    const next_custom_chips = [...data.custom_colors, { id: customIds, color: data.theme_color.color }];

    if (next_custom_chips.length > 5) next_custom_chips.shift();

    setState({
      type: 'setData',
      payload: {
        ...data,
        theme_color: { ...data.theme_color, type: 'custom', index: customIds, color: data.theme_color.color },
        custom_colors: next_custom_chips,
        customId: customIds,
      },
    });

    setCustomIds(customIds + 1);
  };

  return (
    <article className="plus">
      <div className="plus-btn" onClick={customPlus}>
        +
      </div>
      <div className="newColorChips">
        {data.custom_colors.map((chipValue) => (
          <CustomChip key={chipValue.id} chipValue={chipValue} setState={setState} data={data} />
        ))}
      </div>
    </article>
  );
};

export default ChipPlus;
