import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { AccordionOptions } from './styled';

export interface Props {
  children: React.ReactNode;
  className?: string;
  title: string | JSX.Element;
  active?: boolean;
  toggleOnRow?: boolean;
  onClick?: () => void;
}

const Accordion: React.FC<Props> = ({
  title,
  className = '',
  active = false,
  toggleOnRow,
  onClick = () => false,
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [mount, setMount] = useState(false);

  useEffect(() => {
    if (!mount) setMount(true);
  }, [mount]);

  useEffect(() => {
    setMount((prev) => !prev);
  }, [children]);

  const maxHeight = !active ? 0 : contentRef.current?.scrollHeight ?? 0;
  const toggleOnRowHandler = () => {
    if (toggleOnRow) onClick();
  };

  const toggleOArrowHandler = () => {
    if (!toggleOnRow) onClick();
  };

  return (
    <div className={classNames('jack__accordion__section', { [className]: className })}>
      <div
        onClick={toggleOnRowHandler}
        className={classNames('jack__accordion_button', { active, button: toggleOnRow })}
      >
        {title}
        <i className={classNames('accordion__arrow__icon', { button: !toggleOnRow })} onClick={toggleOArrowHandler} />
      </div>
      <AccordionOptions ref={contentRef} className="accordion__content" maxHeight={maxHeight}>
        <div className="accordion__item__wrapper">{children}</div>
      </AccordionOptions>
    </div>
  );
};

export default Accordion;
