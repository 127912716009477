import React, { useReducer } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { produce } from 'immer';
import { Footer, Validator } from 'common/components';
import { regExp } from 'common/validations';
import { api, process } from 'common/functions';
import { useDispatch as useAdminDispatch } from 'common/hooks/admin';
import { authActions as authAdminActions } from 'store/actions/admin';
import { useDispatch as useReviewerDispatch } from 'common/hooks/reviewer';
import { authActions as authReviewerActions } from 'store/actions/reviewer';
import { Box, Form } from '@usebeauty';

import { Lock, UnLock } from '@Icons';
import './TempPassModifyPage.scss';

type Action = { type: 'loading'; payload: boolean };

interface State {
  loading: boolean;
}

const initialState: State = { loading: false };
const reducer = (state: State, action: Action) => {
  const { type, payload } = action;
  // prettier-ignore
  return produce(state, (draft) => {
    switch (type) {
      case 'loading': draft['loading'] = payload; break;
    }
  });
};

const TempPassModifyPage: React.FC = () => {
  const { role } = useParams();
  const navigate = useNavigate();
  const location = useLocation() as { state: { customer_id: number; username: string; password: string } };
  const dispatch = (role === 'admin' ? useAdminDispatch : useReviewerDispatch)();
  const authActions = role === 'admin' ? authAdminActions : authReviewerActions;
  const [, setState] = useReducer(reducer, initialState);

  const {
    control,
    watch,
    setError,
    clearErrors,

    formState: { errors },
  } = useForm({ mode: 'all', defaultValues: { password: '', confirmPassword: '' } });

  const { password, confirmPassword } = watch();

  const inputCheck = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (password.length === 0) {
      setError('confirmPassword', { message: '새 비밀번호를 먼저 입력해주세요.' });
    } else if (password !== value) {
      setError('confirmPassword', { message: '입력하신 비밀번호가 일치하지 않습니다.' });
    }
  };
  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (password !== value) {
      setError('confirmPassword', { message: '입력하신 비밀번호가 일치하지 않습니다.' });
    }
  };

  const submitHandler = async () => {
    const params = { ...location.state, new_password: confirmPassword };
    setState({ type: 'loading', payload: true });
    process(
      api.reqData({ url: 'mypage', data: params }),
      api.put.request,
      api.fullFilled(async ({ response }) => {
        if (response) {
          if (location.state.customer_id > -1) {
            await dispatch(
              authActions.login({
                customer_id: params.customer_id,
                username: params.username,
                password: params.new_password,
                navigate,
              })
            );
          }
        }
      })
    );
  };

  const disabled = !!Object.keys(errors).length || !password.length || !confirmPassword.length;
  return (
    <>
      <div id="temp-pass" className={role}>
        <div id="temp-pass-form-field">
          <div className="find-title">
            <i />
          </div>
          <section className="form-section">
            <Validator.Provider form onSubmit={submitHandler}>
              <div className="login-text">비밀번호 설정</div>
              <Form.Input
                control={control}
                st={{ marginBottom: 10 }}
                name="password"
                placeholder="새 비밀번호를 입력해주세요"
                lockOpenIcon={<UnLock st={password ? { color: '#5f65ff' } : { color: '#cccccc' }} />}
                lockCloseIcon={<Lock st={password ? { color: '#868686' } : { color: '#cccccc' }} />}
                type="password"
                maxLength={20}
                onChange={(e) => {
                  if (e.target.value === confirmPassword && errors.confirmPassword) {
                    clearErrors('confirmPassword');
                  } else if (e.target.value !== confirmPassword && confirmPassword.length) {
                    setError('confirmPassword', { message: '입력하신 비밀번호가 일치하지 않습니다.' });
                  }
                }}
                rules={{
                  pattern: {
                    value: regExp.accountPassword,
                    message: '비밀번호 조건에 부합하지 않습니다. 확인 후 수정해 주세요.',
                  },
                }}
              />

              <Box st={{ marginBottom: 16 }}>
                <Form.Input
                  control={control}
                  st={{ marginBottom: 10 }}
                  name="confirmPassword"
                  type="password"
                  placeholder="새 비밀번호를 재입력하세요."
                  lockOpenIcon={<UnLock st={confirmPassword ? { color: '#5f65ff' } : { color: '#cccccc' }} />}
                  lockCloseIcon={<Lock st={confirmPassword ? { color: '#868686' } : { color: '#cccccc' }} />}
                  maxLength={20}
                  onBlur={onBlurHandler}
                  onFocus={inputCheck}
                  rules={{
                    validate: {
                      notEquals: (value) => {
                        return password === value || '입력하신 비밀번호가 일치하지 않습니다.';
                      },
                    },
                  }}
                />
              </Box>

              <div className="pw-rule-notice">
                <p>9~20자로 영문 대/소문자, 숫자, 특수문자를 포함하세요.</p>
                <p>(특수문자: !@#$%^&)</p>
              </div>
              <Validator.Submit
                watchMode
                text="완료"
                disabled={disabled}
                styles={{ width: '100%', height: 56, radius: 8 }}
              />
            </Validator.Provider>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TempPassModifyPage;
