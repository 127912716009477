import classNames from 'classnames';
import React, { Dispatch } from 'react';
import styled from 'styled-components';
import { Data, Action, ColorChipType } from '../../../ThemeSettingContent';
import './Chip.scss';

interface ChipPieceType {
  color: string;
}

export const ChipPiece = styled.div<ChipPieceType>`
  width: 64px;
  height: 64px;
  background: ${(props) => props.color};
  border-radius: 8px;
  position: relative;
  &.checkChip {
    border: 3px solid black;
    i {
      visibility: visible;
    }
  }
  &:hover {
    cursor: pointer;
  }
`;

interface Prop {
  data: Data;
  setState: Dispatch<Action>;
  chipValue: ColorChipType;
}

const Chip = ({ data, setState, chipValue }: Prop) => {
  const { id, color } = chipValue;
  const { theme_color } = data;

  const onClick = () => {
    setState({
      type: 'setData',
      payload: { ...data, theme_color: { ...theme_color, type: 'static', index: id, color } },
    });
  };

  return (
    <div className="static-chip">
      <ChipPiece
        className={classNames({ checkChip: theme_color.type === 'static' && theme_color.color === color })}
        key={id}
        color={color}
        onClick={onClick}
      >
        <i className="check-icon" />
      </ChipPiece>
    </div>
  );
};

export default Chip;
