import React from 'react';
import { Modal, Button } from 'common/components';
import ModalRegistClient from './modal/Modal_RegistClient';
import './Title.scss';

interface Props {
  refresh: () => void;
}
const Title: React.FC<Props> = ({ refresh }) => {
  return (
    <div id="root-client-controller-title">
      고객사 관리
      <Modal content={({ closeHandler }) => <ModalRegistClient closeHandler={closeHandler} refresh={refresh} />}>
        <Button className="register" text="+ 등록" />
      </Modal>
    </div>
  );
};

export default Title;
