import React, { Dispatch } from 'react';
import { modal } from 'layout';
import { Alert } from 'common/components';
import { api, process } from 'common/functions';
import { Action, State } from '../CtAuthenticateCardGroup';
import './Identification.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
  closeHandler: () => void;
}

const Identification: React.FC<Props> = ({ state, setState, loadHandler, closeHandler }) => {
  const {
    loading,
    configs: { identification },
  } = state;
  const { set } = Alert.Context();

  const submitHandler = () => {
    setState({ type: 'loading', payload: true });
    process(
      api.reqData({ url: 'configs', data: { identification: String(!identification) } }),
      api.put.request,
      api.fullFilled(({ response }) => {
        loadHandler();
        closeHandler();
        set({ success: `정보 수신이 ${identification ? '중지' : '설정'}되었습니다.` });
        return response;
      })
    );
  };

  return (
    <div id="identification__modal__wrapper">
      <modal.Container styles={{ width: 500 }}>
        <modal.Header align="center">사용자 필수 정보 수신</modal.Header>
        <modal.Content>
          <div className="status-container">
            <div className="status">{identification ? '사용 중' : '사용 안 함'} </div>
            <div className="icon" />
            <div className="status ok">{!identification ? '사용 중' : '사용 안 함'}</div>
          </div>
          <hr />
          <div className="detail">
            {identification
              ? '사용자 필수 정보 수신을 사용하지 않겠습니까?'
              : '사용자 필수 정보 수신을 사용하시겠습니까?'}
            <br />
            사용 여부를 전환할 경우 서비스에 바로 반영됩니다.
          </div>
        </modal.Content>
        <modal.Footer
          loading={loading}
          submitText={!identification ? '사용' : '사용 안 함'}
          submitHandler={submitHandler}
          closeHandler={closeHandler}
        />
      </modal.Container>
    </div>
  );
};

export default Identification;
