/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { modal } from 'layout';
import type { M_Select } from 'common/components';
import { Loading, Select } from 'common/components';
import { api, process, time } from 'common/functions';
import './Modal_Policy.scss';

const ModalPolicy: React.FC<{ closeHandler: () => void }> = ({ closeHandler }) => {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<M_Select.item | null>(null);
  const [options, setOptions] = useState<M_Select.item[]>([]);
  const [html, setHtml] = useState('');

  useEffect(() => {
    process(
      api.reqData({ url: 'notices/pp' }),
      api.get.request,
      api.fullFilled(({ response }) => {
        type Data = { created_at: string; id: number; version: string };
        if (response) {
          const options = response.data.list.map((el: Data) => {
            const epoch = new Date(el.created_at).getTime();
            const created_at = time.timeFormatFromUTCEpoch(epoch, 3);
            return {
              ...el,
              title: `${el.version} (${created_at})`,
              value: el.version,
            };
          });
          setOptions(options);
          if (options.length) setSelected(options[0]);
          else setLoading(false);
        }
      })
    );
  }, []);

  useEffect(() => {
    if (selected) {
      setLoading(true);
      process(
        api.reqData({ url: `notices/pp/${selected.value}` }),
        api.get.request,
        api.fullFilled(({ response }) => {
          if (response) {
            const { content } = response.data.detail;
            setHtml(decodeURIComponent(atob(content ?? '')));
          }
          setLoading(false);
        })
      );
    }
  }, [selected]);

  return (
    <modal.Container id="kyc-footer-policy-modal" styles={{ width: 640, height: 540 }}>
      <modal.Header>
        <span>개인정보처리방침</span>
        <i onClick={closeHandler} />
      </modal.Header>
      <modal.Content>
        {loading && <Loading />}
        <div className="policy-select">
          <h4>㈜유스비 개인정보처리방침</h4>
          <Select selected={selected} options={options} onChange={setSelected} />
        </div>
        <div className="inner-html" dangerouslySetInnerHTML={{ __html: html }} />
      </modal.Content>
    </modal.Container>
  );
};

export default ModalPolicy;
