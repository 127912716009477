import React, { useEffect, useReducer, useContext } from 'react';
import { toPairs, pick, pickBy, isObject } from 'lodash';
import { produce } from 'immer';
import classNames from 'classnames';
import { api, process } from 'common/functions';
import { NullishGuard } from 'hoc';
import { ContentContext } from 'layout/reviewer/content/Content';
import type { Data as RowData } from '../CtResultTable';
import IdCard from './contents/idcard/IdCard';
import Face from './contents/face/Face';
import Account from './contents/account/Account';
import Custom from './contents/custom/Custom';
import './Expand.scss';

export type RaResult = {
  application_id: number;
  ra_score: number;
  ra_threshold: [number, number];
  customer_score: number;
  customer_std_score: number;
  svc_score: number;
  svc_std_score: number;
  country_score: number;
  country_std_score: number;
  ra_grade: 'low' | 'middle' | 'high';
};

export type EddField = {
  key: string;
  type: 'tree' | 'ox' | 'ox_addr';
  value: string;
};

export type CustomField = {
  key: string;
  type: 'text' | 'file' | 'tree' | 'ox' | 'addr';
  value: string;
  attachment: { id: number; name: string } | null;
};

export type Custom = {
  edd: EddField[];
  custom: CustomField[];
};

export type Account = {
  account_holder: string;
  account_number: string;
  finance_code: string;
  finance_company: string;
  mod_account_holder: null;
  verified: boolean;
};

type HistoryAccount = Pick<Account, 'account_holder' | 'account_number' | 'finance_code'> & {
  created_at: string;
};
export type Histories = {
  accounts: HistoryAccount[];
};

export type FaceCheck = {
  is_live: boolean;
  is_masked: boolean;
  is_same_person: boolean;
  selfie_image: string;
};

export type IdCard = {
  id_card_image: string;
  id_card_origin: string;
  id_crop_image: string;
  is_manual_input: boolean;
  is_uploaded: boolean;
  modified: boolean;
  modified_ocr_data: null;
  original_ocr_data: string;
  uploaded_type: string;
  verified: boolean;
};

export type Module = {
  account_verification: boolean;
  face_authentication: boolean;
  id_card_ocr: boolean;
  id_card_verification: boolean;
  liveness: boolean;
};

type Data = {
  account: Account | null;
  face_check: FaceCheck | null;
  id_card: IdCard | null;
  custom: { edd: EddField[] | null; custom: CustomField[] | null } | null;
  module: Module | null;
  birthday: string;
  id: number;
  name: string;
  phone_number: string;
  request_time: string;
  rejected_reason: string;
  reviewer_name: string;
  reviewer_username: string;
  histories: Histories;
  ra_result: RaResult | null;
};

const title = { id_card: '신분증 인증', face_check: '얼굴 인증', account: '기존 계좌 인증', custom: '고객 위험 평가' };
type Key = keyof typeof title;
type List = (['id_card', IdCard] | ['face_check', FaceCheck] | ['account', Account] | ['custom', Custom])[];
export type Action =
  | { type: 'setData'; payload: Data }
  | { type: 'loading'; payload: boolean }
  | { type: 'submitLoading'; payload: boolean }
  | { type: 'isExpired'; payload: boolean };
export interface State {
  data: Data | null;
  customField: CustomField[] | null;
  loading: boolean;
  submitLoading: boolean;
  isExpired: boolean;
}
const initialState: State = {
  data: null,
  customField: null,
  loading: true,
  submitLoading: false,
  isExpired: false,
};
const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break;
      case 'loading': draft['loading'] = action.payload; break;
      case 'isExpired': draft['isExpired'] = action.payload; break;
      case 'submitLoading': draft['submitLoading'] = action.payload; break;
    }
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseEddSurvey = (detail: any) => {
  const { edd, custom: respCustom, ra_result } = detail;

  return { ...detail, custom: !edd && !respCustom ? null : { edd, custom: respCustom, ra_result } };
};

interface Props {
  rowData: RowData;
}

const Expand: React.FC<Props> = ({ rowData }) => {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);
  const { id, result_type } = rowData;

  useEffect(() => {
    setLoading(true);
    process(
      api.reqData({ url: `review/results/${id}` }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const res = parseEddSurvey(response.data.detail);
          setState({ type: 'setData', payload: res });
          if (response.data.detail.name === 'EXPIRED') setState({ type: 'isExpired', payload: true });
        }
        setLoading(false);
      })
    );
  }, []);

  const data = pickBy(pick(state.data, ['id_card', 'face_check', 'account', 'custom']), isObject) as NonNullable<
    Pick<Data, Key>
  >;
  const list = toPairs(data) as List;

  return (
    <NullishGuard data={[state.data]}>
      {([data]) => {
        return (
          <section
            className={classNames('reviewer-result-table-tr-expand', {
              'no-gap': result_type === 1 || result_type === 2,
            })}
          >
            <div className="content-wrapper">
              <ul>
                {list.map(([k, v], index) => {
                  return (
                    <li key={k}>
                      <div className="title">
                        <span>{`0${index + 1}`}</span>
                        {title[k]}
                      </div>
                      <NullishGuard data={[k === 'id_card', state.data?.module]}>
                        {([, module]) => (
                          <IdCard rowData={rowData} content={v as IdCard} module={module} isExpired={state.isExpired} />
                        )}
                      </NullishGuard>

                      <NullishGuard data={[k === 'face_check', data.id_card]}>
                        {([, idCard]) => (
                          <Face
                            content={v as FaceCheck}
                            idCard={idCard}
                            module={state.data?.module || null}
                            isExpired={state.isExpired}
                          />
                        )}
                      </NullishGuard>

                      <NullishGuard data={[k === 'account', state.data?.histories]}>
                        {([, histories]) => (
                          <Account content={v as Account} histories={histories} isExpired={state.isExpired} />
                        )}
                      </NullishGuard>

                      <NullishGuard data={[k === 'custom']}>
                        <Custom content={v as Custom} ra_result={state.data?.ra_result} isExpired={state.isExpired} />
                      </NullishGuard>
                    </li>
                  );
                })}
              </ul>
            </div>

            <article>
              {result_type !== 1 && (
                <div>
                  {result_type !== 2 && (
                    <div>
                      <h4>심사자 정보</h4>
                      <p>
                        이름: <span>{data.reviewer_name}</span>
                      </p>
                      <p>
                        아이디: <span>{data.reviewer_username}</span>
                      </p>
                    </div>
                  )}
                  {data.rejected_reason && (
                    <div className="reject">
                      <h4>거부 사유</h4>
                      <p>
                        <span>{data.rejected_reason}</span>
                      </p>
                    </div>
                  )}
                </div>
              )}
            </article>
          </section>
        );
      }}
    </NullishGuard>
  );
};
// result_type === 1 자동승인
// result_type === 2 자동거부
// result_type === 3 수동승인
// result_type === 4 수동거부
export default Expand;
