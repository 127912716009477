import React, { Dispatch } from 'react';
import { toPairs, pick, omit, values, some } from 'lodash';
import classNames from 'classnames';
import { Switch, CheckBox, Alert, Popover, Radio } from 'common/components';
import { initConfig } from '../Expand';
import type { Data, Action, Config, Module } from '../Expand';
import './Settings.scss';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
}

const Settings: React.FC<Props> = ({ edit, data, setState }) => {
  const { set } = Alert.Context();
  const { module, idCard, config } = data;

  return (
    <article className="root-page-client-expand-functions">
      <h5>인증 설정</h5>

      <div className="setting-container">
        <div className="setting-item">
          <div className={classNames('switch-box', module.id_card_ocr ? 'active' : 'inactive')}>
            신분증 인증
            <Switch
              disabled={!edit}
              className={classNames({ readonly: !edit })}
              value={module.id_card_ocr}
              onChange={(v) => {
                const value = !!v;
                const payload: Partial<Module> = { id_card_ocr: value };
                if (!value) {
                  payload.liveness = value;
                  payload.face_authentication = value;
                  setState({
                    type: 'config',
                    payload: pick(initConfig, [
                      'save_original_id_image',
                      'manual_upload_id_mobile',
                      'manual_input_mobile',
                      'manual_upload_id_pc',
                      'use_idcard_face_detect',
                      'allow_wasm_ocr',
                      'allow_wasm_ssa',
                    ]),
                  });
                  setState({ type: 'module', payload: { id_card_verification: value } });
                  // setState({ type: 'idCard', payload: initIdCard });
                }
                setState({ type: 'module', payload });
              }}
            />
          </div>
          <div className="setting-item-content">
            <div className="category">
              <div className="cate-title">
                신분증 진위확인
                <Switch
                  disabled={!edit || !module.id_card_ocr}
                  className={classNames({ readonly: !edit })}
                  value={module.id_card_verification}
                  onChange={(value) => setState({ type: 'module', payload: { id_card_verification: !!value } })}
                />
              </div>
            </div>

            <div className="category">
              <div className="cate-title">
                원본 사진 제공
                <Switch
                  disabled={!edit || !module.id_card_ocr} // disabled 는 편집모드와 상관없이 ic_card_ocr 모듈 여부에만 정해집니다.
                  className={classNames({ readonly: !edit })}
                  value={config.save_original_id_image}
                  onChange={(value) => setState({ type: 'config', payload: { save_original_id_image: !!value } })}
                />
              </div>
            </div>

            <div className="category">
              <Radio.Group
                disabled={!edit || !module.id_card_ocr}
                value={config.allow_wasm_ocr}
                onChange={(value) => setState({ type: 'config', payload: { allow_wasm_ocr: value } })}
              >
                <Radio.Item value={false} text="Server" />
                <Radio.Item value text="WASM" />
              </Radio.Group>
            </div>
            {config.allow_wasm_ocr ? (
              <>
                <div className="category">
                  <div className="cate-title">
                    SSA
                    <Switch
                      disabled={!edit || !config.allow_wasm_ocr}
                      className={classNames({ readonly: !edit })}
                      value={config.allow_wasm_ssa}
                      onChange={(value) => setState({ type: 'config', payload: { allow_wasm_ssa: !!value } })}
                    />
                  </div>
                </div>

                <div className="category border">
                  <div className="cate-title">
                    수동촬영
                    <Switch
                      disabled={!edit || !module.id_card_ocr} // disabled 는 편집모드와 상관없이 ic_card_ocr 모듈 여부에만 정해집니다.
                      className={classNames({ readonly: !edit })}
                      value={config.allow_manual_shot}
                      onChange={(value) => setState({ type: 'config', payload: { allow_manual_shot: !!value } })}
                    />
                  </div>

                  <div className="category">
                    <div className="cate-title">
                      [PC] 사진 첨부
                      <Switch
                        disabled={!edit || !module.id_card_ocr} // disabled 는 편집모드와 상관없이 ic_card_ocr 모듈 여부에만 정해집니다.
                        className={classNames({ readonly: !edit })}
                        value={config.manual_upload_id_pc}
                        onChange={(value) => setState({ type: 'config', payload: { manual_upload_id_pc: !!value } })}
                      />
                    </div>
                  </div>

                  <div className={classNames('category', { border: !config.allow_wasm_ocr })}>
                    <div className="cate-title">
                      [Mobile] 사진 첨부
                      <Switch
                        disabled={!edit || !module.id_card_ocr} // disabled 는 편집모드와 상관없이 ic_card_ocr 모듈 여부에만 정해집니다.
                        className={classNames({ readonly: !edit })}
                        value={config.manual_upload_id_mobile}
                        onChange={(value) => {
                          const payload: Partial<Config> = { manual_upload_id_mobile: !!value };
                          if (!value) payload.manual_input_mobile = !!value;
                          setState({ type: 'config', payload });
                        }}
                      />
                    </div>
                    <div className="category">
                      <div className="cate-title">
                        직접 입력 허용
                        <Switch
                          disabled={!edit || !config.manual_upload_id_mobile}
                          className={classNames({ readonly: !edit })}
                          value={config.manual_input_mobile}
                          onChange={(value) => setState({ type: 'config', payload: { manual_input_mobile: !!value } })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="category">
                  <div className="cate-title">
                    [PC] 사진 첨부
                    <Switch
                      disabled={!edit || !module.id_card_ocr} // disabled 는 편집모드와 상관없이 ic_card_ocr 모듈 여부에만 정해집니다.
                      className={classNames({ readonly: !edit })}
                      value={config.manual_upload_id_pc}
                      onChange={(value) => setState({ type: 'config', payload: { manual_upload_id_pc: !!value } })}
                    />
                  </div>
                </div>

                <div className={classNames('category', { border: !config.allow_wasm_ocr })}>
                  <div className="cate-title">
                    [Mobile] 사진 첨부
                    <Switch
                      disabled={!edit || !module.id_card_ocr} // disabled 는 편집모드와 상관없이 ic_card_ocr 모듈 여부에만 정해집니다.
                      className={classNames({ readonly: !edit })}
                      value={config.manual_upload_id_mobile}
                      onChange={(value) => {
                        const payload: Partial<Config> = { manual_upload_id_mobile: !!value };
                        if (!value) payload.manual_input_mobile = !!value;
                        setState({ type: 'config', payload });
                      }}
                    />
                  </div>
                  <div className="category">
                    <div className="cate-title">
                      직접 입력 허용
                      <Switch
                        disabled={!edit || !config.manual_upload_id_mobile}
                        className={classNames({ readonly: !edit })}
                        value={config.manual_input_mobile}
                        onChange={(value) => setState({ type: 'config', payload: { manual_input_mobile: !!value } })}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="category">
              <div className="cate-title">신분증 설정</div>
              <div className="checkbox-list border">
                {toPairs(idCard).map(([key, value]) => {
                  return (
                    <CheckBox
                      key={key}
                      disabled={!edit || !module.id_card_ocr} // disabled 는 편집모드와 상관없이 ic_card_ocr 모듈 여부에만 정해집니다.
                      text={value.description}
                      checked={value.active ? 1 : 0}
                      className={classNames({ readonly: !edit })}
                      onChange={(check) => {
                        const rest = some(values(omit(idCard, key)), ['active', true]);
                        if (!rest) set({ fail: '최소 1개 이상은 필수 선택입니다.' });
                        else setState({ type: 'idCard', payload: { [key]: { ...value, active: !!check } } });
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="setting-item">
          <div className={classNames('switch-box', module.face_authentication ? 'active' : 'inactive')}>
            얼굴 인증
            <Switch
              disabled={!edit || !module.id_card_ocr}
              className={classNames({ readonly: !edit })}
              value={module.face_authentication}
              onChange={(value) => {
                setState({ type: 'module', payload: { face_authentication: !!value } });
                if (!value) setState({ type: 'module', payload: { liveness: !!value } });
              }}
            />
          </div>
          <div className="setting-item-content">
            <div className="category">
              <div className="cate-title">
                Passive Liveness
                <Switch
                  disabled={!edit || !module.face_authentication}
                  className={classNames({ readonly: !edit })}
                  value={module.liveness}
                  onChange={(value) => setState({ type: 'module', payload: { liveness: !!value } })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="setting-item">
          <div className={classNames('switch-box', module.account_verification ? 'active' : 'inactive')}>
            계좌 인증
            <Switch
              disabled={!edit}
              className={classNames({ readonly: !edit })}
              value={module.account_verification}
              onChange={(value) => {
                setState({ type: 'module', payload: { account_verification: !!value } });
                setState({
                  type: 'config',
                  payload: pick(initConfig, ['allow_account_subcode', 'use_name_verification']),
                });
              }}
            />
          </div>
          <div className="setting-item-content">
            <div className="category">
              <div className="cate-title">
                예금주명 조회
                <Switch
                  disabled={!edit || !module.account_verification}
                  className={classNames({ readonly: !edit })}
                  value={config.use_name_verification}
                  onChange={(value) => setState({ type: 'config', payload: { use_name_verification: !!value } })}
                />
              </div>
            </div>
            <div className="category">
              <div className="cate-title">
                <div>
                  인증코드 문구
                  <Popover
                    clickOutside
                    position="bottom left"
                    content={() => (
                      <div id="root-page-client-expand-popover" className="description">
                        입력한 문구는 1원 송금 시 예금주의 계좌에 인증코드와 함께 표시됩니다.
                      </div>
                    )}
                  >
                    <i className="tooltip" />
                  </Popover>
                </div>
                <Switch
                  disabled={!edit || !module.account_verification}
                  className={classNames({ readonly: !edit })}
                  value={config.allow_account_subcode}
                  onChange={(value) => setState({ type: 'config', payload: { allow_account_subcode: !!value } })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="setting-item">
          <div className={classNames('switch-box', module.riskAssesment ? 'active' : 'inactive')}>
            고객 위험 평가
            <Switch
              className={classNames({ readonly: !edit })}
              disabled={(!module.id_card_ocr && !module.face_authentication && !module.account_verification) || !edit}
              value={module.riskAssesment}
              onChange={(value) => {
                const payload = { riskAssesment: !!value, edd_field: !!value };
                if (!value) {
                  // off 하면 edd, ra, survey 모두 꺼야 함
                  setState({ type: 'module', payload: { ...payload, custom_field: !!value } });
                  setState({ type: 'config', payload: { allow_ra: !!value } });
                } else {
                  setState({ type: 'module', payload });
                }
              }}
            />
          </div>
          <div className="setting-item-content">
            <div className="category">
              <div className="cate-title">
                EDD
                <Switch
                  disabled={!edit || !module.riskAssesment}
                  className={classNames({ readonly: !edit })}
                  value={module.edd_field}
                  onChange={(value) => {
                    setState({ type: 'module', payload: { edd_field: !!value } });
                    if (!value) {
                      setState({ type: 'config', payload: { allow_ra: !!value } });
                    }
                  }}
                />
              </div>
              <div className="category">
                <div className="cate-title">
                  RA
                  <Switch
                    disabled={!edit || !module.edd_field}
                    className={classNames({ readonly: !edit })}
                    value={config.allow_ra}
                    onChange={(value) => setState({ type: 'config', payload: { allow_ra: !!value } })}
                  />
                </div>
              </div>
            </div>
            <div className="category border">
              <div className="cate-title">
                추가 설문
                <Switch
                  disabled={!edit || !module.riskAssesment}
                  className={classNames({ readonly: !edit })}
                  value={module.custom_field}
                  onChange={(value) => setState({ type: 'module', payload: { custom_field: !!value } })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="setting-item">
          <div className={classNames('switch-box', config.allow_kyb ? 'active' : 'inactive')}>
            KYB
            <Switch
              className={classNames({ readonly: !edit })}
              disabled={!edit}
              value={config.allow_kyb}
              onChange={(value) => setState({ type: 'config', payload: { allow_kyb: !!value } })}
            />
          </div>
        </div>
      </div>
    </article>
  );
};

export default Settings;
