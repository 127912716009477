import React, { useReducer, useRef, useState, useEffect } from 'react';
import { modal, Layer } from 'layout';
import { produce } from 'immer';
import { findCustomer, api, process } from 'common/functions';
import type { M_Select } from 'common/components';
import classNames from 'classnames';
import { Validator, validator, Input, Alert, Select, Radio } from 'common/components';
import './Modal_RegistClient.scss';

type Data = {
  phone_number: string;
  industry: M_Select.item | null;
  purchase_type: number | null;
  active: null | boolean;
};
type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'data'; payload: Partial<Data> }
  | { type: 'customer'; payload: Partial<{ name: string; message: string; check: boolean }> };

interface State {
  loading: boolean;
  customer: { name: string; message: string; check: boolean };
  data: Data;
}

const initialState: State = {
  loading: false,
  customer: { name: '', message: '', check: false },
  data: { phone_number: '', industry: null, purchase_type: null, active: null },
};

const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch (action.type) {
      case 'loading': draft['loading'] = action.payload; break;
      case 'data': draft['data'] = { ...draft.data, ...action.payload}; break;
      case 'customer': draft['customer'] = { ...draft.customer, ...action.payload }; break;
    }
  });
};

interface Props {
  closeHandler: () => void;
  refresh: () => void;
}

const ModalRegistClient: React.FC<Props> = ({ closeHandler, refresh }) => {
  const { set } = Alert.Context();
  const contentRef = useRef<HTMLDivElement>(null);
  const [errorList, setErrorList] = useState<HTMLElement[]>([]);
  const [errorCount, setErrorCount] = useState<number>(0);
  const [state, setState] = useReducer(reducer, initialState);
  const { loading, data, customer } = state;
  const styles = { marginTop: 8, marginBottom: 20 };

  // 고객사 중복 확인
  const clientCheckHandler = async () => {
    if (customer.message && customer.message !== '중복여부를 확인하세요.') return;
    if (!customer.name) setState({ type: 'customer', payload: { message: '고객사 명을 입력하세요.' } });
    else {
      const params = { customer_name: customer.name };
      const { id } = await findCustomer(params);
      let payload = { message: '', check: false };
      if (id > -1) payload = { message: '이미 사용 중인 고객사명입니다.', check: false };
      else payload = { message: '사용 가능한 고객사명입니다.', check: true };
      setState({ type: 'customer', payload });
    }
  };

  const submitHandler = () => {
    setState({ type: 'loading', payload: true });
    const params = {
      name: customer.name,
      industry: data.industry?.value,
      phone_number: data.phone_number,
      purchase_type: data.purchase_type,
      active: data.active,
    };

    process(
      api.reqData({ url: 'customers', data: params }),
      api.post.request,
      api.fullFilled(({ response }) => {
        if (response) {
          closeHandler();
          set({ success: '고객사 등록이 완료되었습니다.' });
          refresh();
        }
      })
    );
  };

  setTimeout(() => {
    const el = contentRef.current?.getElementsByClassName('jack__validator__error__message');
    if ((el || []).length !== errorList.length) {
      const assign = [...(contentRef.current?.getElementsByClassName('jack__validator__error__message') || [])];
      setErrorList(assign as HTMLElement[]);
    }
  }, 1);

  useEffect(() => {
    setErrorCount(errorList.length);
  }, [errorList]);

  return (
    <Validator.Provider onSubmit={submitHandler} id="root-client-register-provider">
      <modal.Container styles={{ width: 500 }} id="root-modal-register-client">
        <modal.Header>신규 등록</modal.Header>
        <modal.Content>
          <div className="content" ref={contentRef}>
            <modal.Heading>고객사명</modal.Heading>
            <Validator.Provider onSubmit={clientCheckHandler}>
              <div className="customer-check">
                <Input
                  className={classNames('basic', { error: customer.message && !customer.check })}
                  placeholder="고객사명을 입력하세요"
                  onChange={(e) => {
                    const { value } = e.target;
                    const { validError } = validator.excute(['clientName'], value);
                    let message = validError;
                    if (!validError && !customer.check) message = '중복여부를 확인하세요.';
                    setState({ type: 'customer', payload: { name: value, message, check: false } });
                  }}
                />
                <Validator.Submit text="중복확인" />
                {customer.message && (
                  <div className={classNames('api-check-message', { error: !customer.check })}>{customer.message}</div>
                )}
              </div>
            </Validator.Provider>

            <Layer styles={styles}>
              <modal.Heading>전화번호</modal.Heading>
              <Input
                className="basic"
                placeholder="전화번호를 입력하세요."
                validType="realtime"
                rules={['required', 'phone']}
                value={data.phone_number}
                onChange={(e) => setState({ type: 'data', payload: { phone_number: e.target.value } })}
              />
            </Layer>

            <Layer styles={styles}>
              <modal.Heading>구분</modal.Heading>
              <Select
                placeholder="선택"
                rules={['required']}
                selected={data.industry}
                styles={{ optionsHeight: 'auto' }}
                onChange={(value) => setState({ type: 'data', payload: { industry: value } })}
                options={[
                  { title: '비금융권', value: 1 },
                  { title: '금융권', value: 2 },
                  { title: '가상자산사업자', value: 3 },
                  { title: 'P2P온투업자', value: 4 },
                ]}
              />
            </Layer>

            <Layer styles={styles}>
              <modal.Heading>구매형태</modal.Heading>
              <Radio.Group
                className="box"
                value={data.purchase_type}
                onChange={(value) =>
                  setState({
                    type: 'data',
                    payload: {
                      purchase_type: value,
                    },
                  })
                }
              >
                <Radio.Item text="솔루션" value={1} />
                <Radio.Item text="API" value={0} />
              </Radio.Group>
            </Layer>

            <Layer styles={styles}>
              <modal.Heading>활성여부</modal.Heading>
              <Radio.Group
                className="box"
                value={data.active}
                onChange={(value) => setState({ type: 'data', payload: { active: value } })}
              >
                <Radio.Item text="활성" value />
                <Radio.Item text="비활성" value={false} />
              </Radio.Group>
            </Layer>
          </div>
        </modal.Content>
        <modal.Footer
          submitText="등록"
          loading={loading}
          closeHandler={closeHandler}
          disabled={
            data.active === null || data.purchase_type === null || !customer.check || !!errorList.length || !!errorCount
          }
        />
      </modal.Container>
    </Validator.Provider>
  );
};

export default ModalRegistClient;
