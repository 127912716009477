import React, { Dispatch } from 'react';
import { Table, M_Table, M_Select, Pagination, Controller, Select, Search } from 'common/components';
import type * as M from 'models';
import { time } from 'common/functions';
import classNames from 'classnames';
import type { State, Action } from './CtResultTable';
import Expand from './expand/Expand';
import './ResultTable.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
}

const ReviewerTable: React.FC<Props> = ({ state, setState }): JSX.Element => {
  const { header, data, pagination, searchKey, totalCount } = state;

  const handlers = {
    order: (order: M_Table.sortPayload): void => setState({ type: 'order', payload: order }),
    search: (searchText: string) => setState({ type: 'searchText', payload: Symbol(searchText) }),
    searchKey: (payload: M_Select.item): void => setState({ type: 'searchKey', payload }),
    pagination: (payload: M.Pagination): void => setState({ type: 'pagination', payload }),
  };

  return (
    <section id="reviewer-result-table">
      <Controller title={`심사 결과 (${totalCount})`}>
        <Select
          className="select-resilt-type"
          styles={{ optionsHeight: 'auto' }}
          onChange={(payload) => setState({ type: 'result_type', payload })}
          selected={state.result_type}
          options={[
            { title: '전체', value: 0 },
            { title: '자동승인', value: 1 },
            { title: '자동거부', value: 2 },
            { title: '수동승인', value: 3 },
            { title: '수동거부', value: 4 },
          ]}
        />
        <Select
          styles={{ optionsHeight: 'auto' }}
          onChange={handlers.searchKey}
          selected={searchKey}
          options={[
            { title: '선택', value: 'all' },
            { title: '이름', value: 'name' },
            { title: '전화번호', value: 'phone_number' },
            { title: '생년월일', value: 'birthday' },
          ]}
        />
        <Search submitHandler={handlers.search} disabled={searchKey.value === 'all'} />
      </Controller>
      <Table
        data={data}
        header={header}
        config={{
          order: { sort: state.order, onClick: handlers.order },
        }}
      >
        <Table.Head>{({ value }) => value.title}</Table.Head>
        <Table.Body>
          {({ index }) => {
            const rowData = data[index];
            return (
              <Table.Row key={rowData.id} styles={{ height: rowData.expand ? 'auto' : 72 }}>
                <Table.Tr index={index} style={{ height: 72 }}>
                  {({ value, property }) => {
                    if (property === 'request_time') {
                      const epoch = new Date(value).getTime();
                      return time.timeFormatFromUTCEpoch(epoch, 2);
                    }
                    if (property === 'result_type') {
                      if (value === 1) return <span className="approved">자동승인</span>;
                      if (value === 2) return <span className="fail">자동거부</span>;
                      if (value === 3) return <span className="approved">수동승인</span>;
                      if (value === 4) return <span className="fail">수동거부</span>;
                    }
                    if (property === 'expand')
                      return (
                        <i
                          className={classNames('expand-icon', { expanded: rowData.expand })}
                          onClick={() => setState({ type: 'expand', payload: index })}
                        />
                      );
                    return value === 'EXPIRED' ? '만료됨' : value || '-';
                  }}
                </Table.Tr>
                {rowData.expand && <Expand rowData={rowData} />}
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
      <div className="table__context__page__gear">
        <Pagination pagination={pagination} onChange={handlers.pagination} />
      </div>
    </section>
  );
};

export default ReviewerTable;
