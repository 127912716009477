import React, { useReducer, Dispatch } from 'react';
import { useParams } from 'react-router-dom';
import { Validator, Button } from 'common/components';
import { modal } from 'layout';
import { produce } from 'immer';
import { api, process, checkEmail } from 'common/functions';
import { Box, Form } from '@usebeauty';
import { useForm } from 'react-hook-form';
import { InputCheck } from '@Icons';
import { regExp } from 'common/validations';
import type { State as CtState, Action as CtAction } from '../SignUpPage';
import './Information.scss';

type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'email'; payload: Partial<{ duplicate: boolean; message: string; checked: boolean }> }
  | { type: 'username'; payload: Partial<{ duplicate: boolean; message: string; checked: boolean }> };

interface State {
  loading: boolean;
  email: { duplicate: boolean; message: string; checked: boolean };
  username: { duplicate: boolean; message: string; checked: boolean };
}

const initialState: State = {
  loading: false,
  email: { duplicate: false, message: '', checked: false },
  username: { duplicate: false, message: '', checked: false },
};

const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch (action.type) {
      case 'loading': draft['loading'] = action.payload; break;
      case 'email': draft['email'] = { ...draft.email, ...action.payload }; break;
      case 'username': draft['username'] = { ...draft.username, ...action.payload }; break;
    }
  });
};

interface Props {
  state: CtState;
  setState: Dispatch<CtAction>;
}

const Information: React.FC<Props> = ({ state: CtState, setState: setCtState }) => {
  const { role } = useParams();
  const [state, setState] = useReducer(reducer, initialState);

  const { customer_id } = CtState;

  const {
    control,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'all',
    defaultValues: { username: '', name: '', email: '', phone_number: '' },
  });

  // 아이디 중복 확인
  const idCheckHandler = () => {
    const params = { customer_id, value: getValues('username') };
    process(
      api.reqData({ url: 'check/id', data: params }),
      api.post.request,
      api.fullFilled(({ response, error }) => {
        let payload = { duplicate: false, message: '', checked: false };
        if (response) {
          const { result_code } = response.data.api_response;
          if (result_code === 'E600') payload = { duplicate: true, message: '입력 유형이 올바르지 않습니다. 확인 후 수정해 주세요.', checked: false }; // prettier-ignore
          if (result_code === 'F602')
            payload = { duplicate: true, message: '이미 사용중인 아이디입니다.', checked: false };
          if (result_code === 'N100')
            payload = { duplicate: true, message: '사용 가능한 아이디입니다.', checked: true };
        }

        if (error) {
          const { result_code } = error.data.api_response;
          if (result_code === 'E600') payload = { duplicate: true,message: '8~20자의 영문 대, 소문자만 사용 가능합니다.', checked: false }; // prettier-ignore
          if (result_code === 'E531') payload = { duplicate: true,message: '아이디를 입력해 주세요.', checked: false }; // prettier-ignore
        }

        setState({ type: 'username', payload });
      })
    );
  };

  // 이메일 중복 확인
  const emailCheckHandler = async () => {
    if (customer_id) {
      const result = await checkEmail({ customer_id, value: getValues('email') });
      setState({ type: 'email', payload: result });
    }
  };

  const submitHandler = async () => {
    setState({ type: 'loading', payload: true });
    const params = {
      customer_id,
      name: getValues('name'),
      phone_number: getValues('phone_number'),
      email: getValues('email'),
      username: getValues('username'),
      from: 'kyc',
    };
    const url = role === 'admin' ? `customers/${customer_id}/admins` : 'reviewers';
    process(
      api.reqData({ url, data: params }),
      api.post.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { result_code } = response.data;
          if (result_code === 'N100') setCtState({ type: 'step', payload: 4 });
        }
      })
    );
  };

  type checkMessageType = {
    disabled: boolean;
    check: {
      duplicate: boolean;
      checked: boolean;
      message: string;
    };
  };

  const CheckMessage = ({ disabled, check }: checkMessageType) => {
    if (disabled) return <></>;
    if (!check.duplicate) return <p className="duplicate-message">중복 여부를 확인해주세요</p>;
    if (check.checked) return <p className="kyb__use__possible_id">{check.message}</p>;
    return <p className="duplicate-message">{check.message}</p>;
  };

  return (
    <Validator.Provider className="information" onSubmit={submitHandler}>
      <Box>
        <modal.Heading>아이디</modal.Heading>
        <Validator.Provider onSubmit={idCheckHandler}>
          <div className="duplicate-check">
            <Form.Input
              className="form-id-input"
              name="username"
              placeholder="8~20자의 영문, 숫자로 입력하세요."
              control={control}
              rules={{
                pattern: {
                  value: regExp.id,
                  message: '8~20자의 영문 대, 소문자만 사용 가능합니다.',
                },
              }}
              onChange={() => {
                setState({
                  type: 'username',
                  payload: {
                    duplicate: false,
                    checked: false,
                  },
                });
              }}
              maxLength={20}
            />
            {state.username.checked && <InputCheck className="check" />}
            <Validator.Submit text="중복확인" />
          </div>
          <CheckMessage disabled={!!errors.username || !getValues('username')} check={state.username} />
        </Validator.Provider>
      </Box>
      <Box st={{ marginTop: 16 }}>
        <modal.Heading>이름</modal.Heading>
        <Form.Input
          placeholder="이름을 입력해주세요"
          name="name"
          control={control}
          rules={{
            pattern: {
              value: regExp.name,
              message: '국문, 영문 대소문자만 입력할 수 있습니다',
            },
          }}
        />
      </Box>

      <Box st={{ marginTop: 16 }}>
        <modal.Heading>전화번호</modal.Heading>
        <Form.Input
          placeholder="전화번호를 입력해주세요"
          name="phone_number"
          control={control}
          rules={{
            pattern: {
              value: regExp.phone,
              message: '숫자,특수문자(+-)만 사용 가능합니다.',
            },
          }}
        />
      </Box>
      <Box st={{ marginTop: 16 }}>
        <modal.Heading>이메일</modal.Heading>
        <Validator.Provider onSubmit={emailCheckHandler}>
          <div className="duplicate-check">
            <Form.Input
              className="form-email-input"
              name="email"
              placeholder="이메일을 입력하세요"
              control={control}
              rules={{
                pattern: {
                  value: regExp.email,
                  message: '입력 형식이 올바르지 않습니다.',
                },
              }}
              onChange={() => {
                setState({
                  type: 'email',
                  payload: {
                    duplicate: false,
                    checked: false,
                  },
                });
              }}
            />
            <Validator.Submit text="중복확인" />
          </div>
          <CheckMessage disabled={!!errors.email || !getValues('email')} check={state.email} />
        </Validator.Provider>
      </Box>

      <div className="next-field">
        <Button text="뒤로" onClick={() => setCtState({ type: 'step', payload: 2 })} />
        <Validator.Submit
          loading={state.loading}
          text="등록"
          disabled={!state.username.checked || !state.email.checked}
        />
      </div>
    </Validator.Provider>
  );
};

export default Information;
