import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { SubController } from 'common/components';
import './Manage.scss';

function Manage() {
  return (
    <div id="admin-manage-edd-content-navigator">
      <SubController
        title={
          <>
            <NavLink to="/admin/manage/edd/ra">위험 설정</NavLink>
            <NavLink to="/admin/manage/edd/weight">가중치 설정</NavLink>
            <NavLink to="/admin/manage/edd/realscore">실제반영 점수</NavLink>
            <NavLink to="/admin/manage/edd/criterion">평가기준 설정</NavLink>
            <NavLink to="/admin/manage/edd/customField">추가 설문</NavLink>
          </>
        }
      />
      <Outlet />
    </div>
  );
}

export default Manage;
