import { M_Table } from 'common/components';
import { api, process } from 'common/functions';
import { useApiCancel, useMount } from 'common/hooks';
import { produce } from 'immer';
import { ContentContext } from 'layout/root/content/Content';
import type { Pagination } from 'models';
import React, { useContext, useEffect, useReducer } from 'react';
import PrivacyTable from './PrivacyTable';

const initPagination = { info: { current: 1, total: 0 }, format: { limit: 20, offset: 0 } };
export type Data = {
  created_at: string;
  id: number;
  version: string;
  expand: boolean;
};

export type Action =
  | { type: 'setData'; payload: Data[] }
  | { type: 'expand'; payload: number }
  | { type: 'pagination'; payload: Pagination }
  | { type: 'loading'; payload: boolean };

export interface State {
  data: Data[];
  header: M_Table.header[];
  pagination: Pagination;
}

const initialState: State = {
  data: [],
  pagination: { ...initPagination },
  header: [
    { title: '등록일', property: 'created_at', flex: 2, align: 'center' },
    { title: '버전', property: 'version', flex: 2, align: 'center' },
    { title: '상세보기', property: 'expand', align: 'center' },
  ],
};

// prettier-ignore
const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'setData': draft['data'] = action.payload; break;
      case 'expand': draft.data[action.payload]['expand'] = !draft.data[action.payload].expand; break;
      case 'pagination': draft['pagination'] = action.payload; break;
    }
  });
};

const CtPrivacyTable: React.FC = () => {
  useApiCancel(['notices/pp']);
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = () => {
    setLoading(true);
    setState({ type: 'loading', payload: true });

    const params = {
      start: state.pagination.format.offset,
      count: state.pagination.format.limit,
    };
    process(
      api.reqData({ url: 'notices/pp', params }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { list, total_count } = response.data;
          const payload = list.map((el: Omit<Data, 'expand'>) => ({ ...el, expand: false }));
          setState({ type: 'setData', payload });
          setState({ type: 'pagination', payload: api.setTotal(state.pagination, total_count) });
        }
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    loadHandler();
  }, []);

  useMount(() => {
    loadHandler();
  }, [state.pagination.info.current]);

  return <PrivacyTable state={state} setState={setState} loadHandler={loadHandler} />;
};

export default CtPrivacyTable;
