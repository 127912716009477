import React, { useReducer } from 'react';
import { modal, Layer } from 'layout';
import { api, process } from 'common/functions';
import { Validator, Alert, TextArea, Input } from 'common/components';
import classNames from 'classnames';
import { produce } from 'immer';
import type { Data as RowData } from '../../CtPrivacyTable';
import type { Data } from '../Expand';
import './Modal_ModifyPrivacy.scss';

type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'add' }
  | { type: 'remove'; payload: number }
  | { type: 'title'; payload: { index: number; value: string } }
  | { type: 'content'; payload: { index: number; value: string } };
export interface State {
  loading: boolean;
  data: Data[];
}

const initialState: State = { loading: false, data: [] };
const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch(action.type) {
      case 'loading': draft['loading'] = action.payload; break;
      case 'add': draft['data'].push({title: '', content: ''}); break;
      case 'remove': draft['data'].splice(action.payload, 1); break;
      case 'title': draft.data[action.payload.index]['title'] = action.payload.value; break;
      case 'content': draft.data[action.payload.index]['content'] = action.payload.value; break;
    }
  });
};

interface Props {
  data: Data[];
  rowData: RowData;
  closeHandler: () => void;
  getExpandData: (type?: 'update') => void;
}

const ModalModifyPrivacy: React.FC<Props> = ({ closeHandler, rowData, getExpandData, ...rest }) => {
  const { set } = Alert.Context();
  const [state, setState] = useReducer(reducer, initialState, (init) => ({ ...init, data: rest.data || [] }));
  const { data } = state;

  const submitHandler = () => {
    setState({ type: 'loading', payload: true });
    process(
      api.reqData({ url: `guides/${rowData.key}`, data: { content: btoa(encodeURIComponent(JSON.stringify(data))) } }),
      api.put.request,
      api.fullFilled(({ response }) => {
        if (response) {
          closeHandler();
          set({ success: '약관 편집이 완료되었습니다.' });
          getExpandData('update');
        }
      })
    );
  };

  const active = (() => {
    if (data.length !== rest.data.length) return true;
    return data.some((el, i) => api.differ(el, rest.data[i]));
  })();

  return (
    <Validator.Provider onSubmit={submitHandler}>
      <modal.Container id="root-page-guidance-modify-privacy" styles={{ width: 720, maxHeight: 600 }}>
        <modal.Header>약관 편집</modal.Header>
        <modal.Content>
          <Layer styles={{ marginTop: 8, marginBottom: 20 }}>
            <modal.Heading>약관 Key</modal.Heading>
            <Input
              placeholder="약관 Key를 입력하세요"
              className="basic"
              value={rowData.key}
              styles={{ width: '100%' }}
              readonly
            />
          </Layer>

          <modal.Heading>약관 내용 (HTML)</modal.Heading>
          {data.map((el, i) => {
            const showAdd = data.length - 1 === i;
            const showRemove = data.length > 1;
            const multiple = showAdd && showRemove;
            return (
              <Layer key={i} styles={{ marginTop: 8, marginBottom: 20 }} className="terms-layer">
                <div className="content">
                  <Input
                    placeholder="제목을 입력하세요."
                    className="basic"
                    validType="realtime"
                    rules={[{ maxLength: 100 }]}
                    value={el.title}
                    styles={{ width: '100%' }}
                    onChange={(e) => setState({ type: 'title', payload: { index: i, value: e.target.value } })}
                  />
                  <TextArea
                    placeholder="약관 내용은 HTML 형태로 입력하세요."
                    styles={{ width: '100%', height: 274 }}
                    value={el.content}
                    onChange={(e) => setState({ type: 'content', payload: { index: i, value: e.target.value } })}
                  />
                </div>
                <div className="utils">
                  {showAdd && (
                    <i className={classNames('add', { multiple })} onClick={() => setState({ type: 'add' })} />
                  )}
                  {showRemove && (
                    <i
                      className={classNames('remove', { multiple })}
                      onClick={() => setState({ type: 'remove', payload: i })}
                    />
                  )}
                </div>
              </Layer>
            );
          })}
        </modal.Content>
        <modal.Footer loading={state.loading} submitText="변경" disabled={!active} closeHandler={closeHandler} />
      </modal.Container>
    </Validator.Provider>
  );
};

export default ModalModifyPrivacy;
