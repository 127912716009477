import React, { Dispatch } from 'react';
import { Modal } from 'common/components';
import './AuthenticateCardGroup.scss';
import { Action, State } from './CtAuthenticateCardGroup';
import Identification from './modal/Identification';
import Hmac from './modal/Hmac';
import DeleteHmac from './modal/DeleteHmac';
// import Account from './modal/Account';
import AuthenticateCard from './AuthenticateCard';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const AuthenticateCardGroup = ({ state, setState, loadHandler }: Props) => {
  return (
    <section id="required-management-content">
      <div className="article-container">
        <AuthenticateCard
          title="필수 정보 입력 화면 사용 여부"
          label={state.configs.identification ? '사용 중' : '사용 안 함'}
          modalContent={
            <Modal
              content={({ closeHandler }) => (
                <Identification
                  state={state}
                  setState={setState}
                  loadHandler={loadHandler}
                  closeHandler={closeHandler}
                />
              )}
            >
              <button type="button">{state.configs.identification ? '중지' : '사용'}</button>
            </Modal>
          }
          description
        />
        <AuthenticateCard
          title="HMAC Key"
          label={state.configs.hmac ? '사용 중' : '사용 안 함'}
          tooltip="HMAC Key는 고객 신원 확인의 보안성을 높이기 위하여 암호화 키를 수반하는 보안 코드입니다."
          modalContent={
            <>
              <Modal
                content={({ closeHandler }) => (
                  <Hmac state={state} setState={setState} loadHandler={loadHandler} closeHandler={closeHandler} />
                )}
              >
                <button type="button">{state.configs.hmac ? '갱신' : '생성'}</button>
              </Modal>
              {state.configs.hmac && (
                <Modal
                  content={({ closeHandler }) => (
                    <DeleteHmac
                      state={state}
                      setState={setState}
                      loadHandler={loadHandler}
                      closeHandler={closeHandler}
                    />
                  )}
                >
                  <button type="button">삭제</button>
                </Modal>
              )}
            </>
          }
        />
        {/* <AuthenticateCard
          title="계좌 변경"
          label={state.configs.account ? `사용 중 (${state.configs.max_change_account}회)` : '사용 안 함'}
          tooltip="계좌 인증을 위한 인증 코드를 받은 후 계좌번호를 변경하여 새로운 인증 코드를 받을 수 있게 해줍니다."
          modalContent={
            <Modal
              content={({ closeHandler }) => (
                <Account state={state} setState={setState} loadHandler={loadHandler} closeHandler={closeHandler} />
              )}
            >
              <button type="button">{state.configs.account ? '중지' : '사용'}</button>
            </Modal>
          }
        /> */}
      </div>
    </section>
  );
};

export default AuthenticateCardGroup;
