import React, { Dispatch } from 'react';
import { Action, State } from '../CtSubscribeSetting';
import Account from '../account/Account';
import AccountSubcode from './subcode/AccountSubcode';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const AccountSetting = ({ state, setState, loadHandler }: Props) => {
  return (
    <>
      <h2 className="section-title">계좌 인증</h2>
      <div className="row">
        <Account state={state} setState={setState} loadHandler={loadHandler} />
      </div>
      <div className="row">
        {state.vas.allow_account_subcode && (
          <AccountSubcode state={state} setState={setState} loadHandler={loadHandler} />
        )}
      </div>
    </>
  );
};

export default AccountSetting;
