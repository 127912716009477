import React from 'react';
import classNames from 'classnames';
import { Button, Modal, Popover } from 'common/components';
import { State } from '../../CtSubscribeSetting';
import ModalDriverExpiryDate from '../modal/expiryDate/Modal_DriverExpiryDate';
import './DriverExpiryDate.scss';

interface Props {
  state: State;
  loadHandler: () => void;
}

const DriverExpiryDate = ({ state, loadHandler }: Props) => {
  const { use_ocr_driver_expired_date, trust_driver_expired_date } = state.vas;
  return (
    <section id="subscribe-management-setting-driver">
      <div className="header">
        <div className="title">운전면허증 마감일자</div>
        <Popover
          position="right center"
          clickOutside
          content={() => (
            <div id="admin-subscribe-driver-popover-content" className="description">
              고객 신원 확인의 정확도를 높이기 위해 운전면허
              <br />
              마감일자를 고객에게 직접 받을 수 있습니다.
            </div>
          )}
        >
          <i className="title-info" />
        </Popover>
      </div>
      <div className="content">
        <div className="label">
          {use_ocr_driver_expired_date
            ? '사용 안 함'
            : `사용 중 (${trust_driver_expired_date ? '자동심사' : '수동심사'})`}
        </div>
      </div>
      <div className="button-group">
        <Modal
          content={({ closeHandler }) => (
            <ModalDriverExpiryDate state={state} loadHandler={loadHandler} closeHandler={closeHandler} mode="edit" />
          )}
        >
          <Button className={classNames('edit', { active: use_ocr_driver_expired_date })} text="편집" />
        </Modal>

        <Modal
          content={({ closeHandler }) => (
            <ModalDriverExpiryDate
              state={state}
              loadHandler={loadHandler}
              closeHandler={closeHandler}
              mode={use_ocr_driver_expired_date ? 'use' : 'stop'}
            />
          )}
        >
          <Button className="submit" text={use_ocr_driver_expired_date ? '사용' : '중지'} />
        </Modal>
      </div>
      {state.disabled.manual && <div className="subscribe-setting-disabled" />}
    </section>
  );
};

export default DriverExpiryDate;
