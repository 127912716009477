/* eslint-disable react/no-danger */
import React from 'react';
import { TextArea, Button, Alert } from 'common/components';
import type { Data } from '../../Expand';
import './Privacy.scss';

const Collection: React.FC<{ privacy: Data[] }> = ({ privacy }) => {
  const { set } = Alert.Context();
  return (
    <div id="term-privacy">
      <h3 className="term-subject">약관 내용</h3>
      {privacy.map((el) => {
        return (
          <div key={el.title} className="term-content">
            <div className="term-title">{el.title}</div>
            <div className="inner-html" dangerouslySetInnerHTML={{ __html: el.content }} />
          </div>
        );
      })}
      <h3 className="term-subject">약관 HTML</h3>
      {privacy.map((el) => {
        return (
          <div key={el.title} className="term-content">
            <div className="term-title">
              {el.title}
              <Button
                text="HTML 복사하기"
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(el.content);
                    set({ success: 'HTML 복사가 완료되었습니다.' });
                  } catch {
                    set({ fail: 'HTML 복사는 실패되었습니다.' });
                  }
                }}
              />
            </div>
            <TextArea readonly className="html-textarea" value={el.content} styles={{ width: '100%', height: 136 }} />
          </div>
        );
      })}
    </div>
  );
};

export default Collection;
