/* eslint-disable react/no-danger */
import React, { useState, Dispatch } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckBox, Accordion, Button } from 'common/components';
import agreement from 'data/aggrement.json';
import type { State, Action } from '../SignUpPage';
import './Agreement.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
}

const Agreement: React.FC<Props> = ({ state, setState }) => {
  const navigate = useNavigate();
  const [agree, setAgree] = useState(0);

  return (
    <div className="agreement">
      <CheckBox text="모두 동의합니다." className="circular" checked={agree} onChange={setAgree} />
      <div className="terms-check">
        <Accordion
          active={state.toggle}
          title={<CheckBox text={agreement.agreement_1.title} checked={agree} onChange={setAgree} />}
          onClick={() => setState({ type: 'toggle' })}
        >
          <div className="agreement-content" dangerouslySetInnerHTML={{ __html: agreement.agreement_1.content }} />
        </Accordion>
      </div>

      <div className="next-field">
        <Button text="동의 안함" onClick={() => navigate(-1)} />
        <Button text="동의" disabled={!agree} onClick={() => setState({ type: 'step', payload: 2 })} />
      </div>
    </div>
  );
};

export default Agreement;
