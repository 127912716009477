import axios from 'axios';
import { authorization } from './index';
import { Resp } from './model';

// ##########################################
export type T_GetCustomers = {
  active: boolean;
  id: number;
  industry: number;
  name: string;
  phone_number: string;
  purchase_type: number;
  status: number;
  expand: boolean;
};

export const getCustomers = (params?: { start: number; count: number; [x: number]: string | undefined }) => {
  return axios.get<Resp<{ list: T_GetCustomers[] }>>('customers', {
    params,
    headers: authorization(),
  });
};
getCustomers.data = {} as T_GetCustomers;

// ##########################################
export type T_GetCustomerAdmins = {
  active: boolean;
  created_at: string;
  email: string;
  id: number;
  is_approve: boolean;
  name: string;
  phone_number: string;
  username: string;
};

export const getCustomerAdmins = (
  customer_id: number,
  params: {
    start: number;
    count: number;
    keyword_field: string;
    keyword?: string;
    order_field?: string;
    order?: string;
  }
) => {
  return axios.get<Resp<{ list: T_GetCustomerAdmins[] }>>(`customers/${customer_id}/admins`, {
    params,
    headers: authorization(),
  });
};
getCustomerAdmins.data = {} as T_GetCustomerAdmins;
