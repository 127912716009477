import React, { FC, useRef, useReducer, useEffect } from 'react';
import { produce } from 'immer';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { excutions } from 'common/validations';
import { Input, Select, Table, TextArea, Validator } from 'common/components';
import { ModalPortal } from 'common/components/module/lib/modal/ModalPortal';
import { CustomField, useCustomContext } from '../../context/CustomFieldContext';
import Modal_CustomFieldAdd, { TreeNode } from '../modal/Modal_CustomFieldAdd';
import Modal_CustomFieldEdit from '../modal/Modal_CustomFieldEdit';

export const ItemTypes = {
  CARD: 'card',
};

const TEXT_LIMIT = 15;
const FILE_LIMIT = 5;
const TYPE_TEXT_COUNT_EXCEED = `텍스트 타입의 최대 활성 값인 ${TEXT_LIMIT}개를 초과하여 선택할 수 없습니다.`;
const TYPE_FILE_COUNT_EXCEED = `첨부파일 타입의 최대 활성 값인 ${FILE_LIMIT}개를 초과하여 선택할 수 없습니다.`;
const ACTIVE_TEXT_EXCEED = '텍스트 타입의 최대 활성 값을 초과하여 활성화할 수 없습니다.';
const ACTIVE_FILE_EXCEED = '첨부파일 타입의 최대 활성 값을 초과하여 활성화할 수 없습니다.';
const INVALID_FIELD_NAME = '한글, 영문 대/소문자, 숫자, 특수문자(-_().,), 빈칸만 사용 가능합니다.';
const DUPLICATE_FIELD_NAME = '이미 동일한 항목명이 존재합니다.';

export interface CardProps {
  id: number;
  index: number;
  edit: boolean;
  field: CustomField;

  moveCard: (dragIndex: number, hoverIndex: number) => void;
  removeHandler: (id: number) => void;
}

const fieldTypeMap = {
  file: '첨부파일',
  text: '텍스트',
  ox: 'Yes / No',
  addr: '주소',
  tree: '객관식',
} as const;

type SelectItem = { title: string; value: string | boolean | null } | null;

type Action =
  | { type: 'type'; payload: NonNullable<SelectItem> }
  | { type: 'required'; payload: NonNullable<SelectItem> }
  | { type: 'active'; payload: NonNullable<SelectItem> }
  | { type: 'toggle'; payload: boolean };
type State = {
  type?: SelectItem;
  required?: SelectItem;
  active?: SelectItem;
  toggle?: boolean;
};
const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'type':
        draft['type'] = { ...action.payload };
        break;
      case 'required':
        draft['required'] = { ...action.payload };
        break;
      case 'active':
        draft['active'] = { ...action.payload };
        break;
      case 'toggle':
        draft['toggle'] = action.payload;
        break;
      default:
        break;
    }
  });
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const TableRow: FC<CardProps> = ({ id, index, edit, field, moveCard, removeHandler }) => {
  const { state: contextState, updateHandler } = useCustomContext();
  const { data } = contextState;
  // row 별 select 항목을 위한 component state
  const initializer = (init: State) => ({ ...init });
  const [state, setState] = useReducer(reducer, {}, initializer);
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect: (monitor) => ({ handlerId: monitor.getHandlerId() }),
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => (edit ? { id, index } : null),
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const activeCount = {
    file: data.customFields.filter((cf) => cf.type === 'file' && cf.active).length,
    text: data.customFields.filter((cf) => cf.type === 'text' && cf.active).length,
  };

  useEffect(() => {
    const typePayload = { title: field.type === '' ? '선택' : fieldTypeMap[field.type], value: field.type };
    const requiredPayload = {
      // eslint-disable-next-line no-nested-ternary
      title: field.required === null ? '' : field.required === true ? '필수 항목' : '선택 항목',
      value: field.required,
    };
    setState({ type: 'type', payload: typePayload });
    setState({ type: 'required', payload: requiredPayload });
    setState({ type: 'active', payload: { title: field.active ? '활성' : '비활성', value: field.active } });
  }, [field.type, field.required, field.active]);

  const validCheck = (key: string, item: { title?: string; value: string | boolean }) => {
    // 신규 값 type 변경 시
    if (key === 'type') {
      if (item.value === 'text' && activeCount.text >= TEXT_LIMIT) {
        setState({ type: key, payload: { title: '', value: null } });
        updateHandler(index, { ...field, [key]: '', error: { ...field.error, type: 'text' } });
        return false;
      }
      if (item.value === 'file' && activeCount.text >= FILE_LIMIT) {
        setState({ type: key, payload: { title: '', value: null } });
        updateHandler(index, { ...field, [key]: '', error: { ...field.error, type: 'file' } });
        return false;
      }
    }
    // 신규 값 name 변경 시
    if (key === 'name') {
      const regResult = excutions.fieldName.regExp(item.value as string);
      if (regResult) {
        updateHandler(index, { ...field, [key]: item.value as string, error: { ...field.error, name: true } });
        return false;
      }
    }
    // 기존 값 active 변경 시
    if (key === 'active' && item.value) {
      if (field.type === 'text' && activeCount.text >= TEXT_LIMIT) {
        setState({ type: key, payload: { title: '비활성', value: false } });
        updateHandler(index, { ...field, [key]: false, error: { ...field.error, active: true } });
        return false;
      }
      if (field.type === 'file' && activeCount.file >= FILE_LIMIT) {
        setState({ type: key, payload: { title: '비활성', value: false } });
        updateHandler(index, { ...field, [key]: false, error: { ...field.error, active: true } });
        return false;
      }
    }
    return true;
  };

  const rowDataChangeHandler = (key: string, item: { title?: string; value: string | boolean }) => {
    if (!validCheck(key, item)) return;
    if (key === 'type' || key === 'required' || key === 'active') {
      setState({ type: key, payload: { title: item.title as string, value: item.value } });
    }

    if (key === 'type' && (item.value === 'tree' || item.value === 'ox')) {
      setState({ type: 'toggle', payload: true });
      updateHandler(index, { ...field, tree: null });
    }
    const duplicate =
      key === 'name'
        ? !!data.customFields.filter((f) => f.name === (item.value as string)).length
        : field.error.duplicate;

    updateHandler(index, { ...field, [key]: item.value, error: { ...field.error, [key]: false, duplicate } });
  };

  const modalSubmitHandler = (item: { name: string; type: 'tree' | 'ox' | ''; tree: TreeNode[] }) => {
    const { name, type, tree } = item;
    if (!edit && !validCheck('name', { value: item.name })) return;
    updateHandler(index, { ...field, name, type, tree });
  };

  const errorMessage = () => {
    if (field.error.active) {
      if (field.type === 'text') return ACTIVE_TEXT_EXCEED;
      if (field.type === 'file') return ACTIVE_FILE_EXCEED;
    }
    if (field.error.type) {
      return field.error.type === 'text' ? TYPE_TEXT_COUNT_EXCEED : TYPE_FILE_COUNT_EXCEED;
    }
    if (field.error.name) {
      return INVALID_FIELD_NAME;
    }
    if (field.error.duplicate) {
      return DUPLICATE_FIELD_NAME;
    }
    return '';
  };

  return (
    <div
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      className={classNames('rowData', { readonly: !edit })}
    >
      <div>
        <Table.Row styles={{ height: 64, marginBottom: errorMessage() ? 24 : 0 }}>
          <Table.Tr index={index}>
            {({ value, property }) => {
              if (property === 'allign') {
                return edit ? <div className="admin-manage-subscribe-setting-custom-direct allign" /> : <></>;
              }
              if (property === 'type') {
                return (
                  <Select
                    styles={{ optionsHeight: 'auto' }}
                    disabled={!field.isAppend}
                    onChange={({ value, title }) => rowDataChangeHandler('type', { value, title: title as string })}
                    placeholder="선택"
                    rules={['required']}
                    error={field.error.type !== false ? ' ' : ''}
                    selected={state.type}
                    options={[
                      { title: '첨부파일', value: 'file' },
                      { title: '텍스트', value: 'text' },
                      { title: 'Yes / No', value: 'ox' },
                      { title: '주소', value: 'addr' },
                      { title: '객관식', value: 'tree' },
                    ]}
                  />
                );
              }
              if (property === 'name') {
                return (
                  <>
                    <Input
                      placeholder="최대 20자 입력가능"
                      disabled={!field.isAppend || (field.isAppend && (field.type === 'tree' || field.type === 'ox'))}
                      className="basic"
                      maxLength={20}
                      error={field.error.name || field.error.duplicate ? ' ' : ''}
                      value={field.name}
                      onChange={(e) => rowDataChangeHandler('name', { value: e.target.value as string })}
                    />
                    {edit && (field.type === 'tree' || field.type === 'ox') && (
                      <i className="edit" onClick={() => setState({ type: 'toggle', payload: true })} />
                    )}
                  </>
                );
              }
              if (property === 'required') {
                return (
                  <Select
                    styles={{ optionsHeight: 'auto' }}
                    className={classNames({ readonly: !edit })}
                    disabled={!edit}
                    onChange={({ value, title }) => rowDataChangeHandler('required', { value, title: title as string })}
                    selected={state.required}
                    rules={['required']}
                    placeholder="선택"
                    options={[
                      { title: '필수 항목', value: true },
                      { title: '선택 항목', value: false },
                    ]}
                  />
                );
              }
              if (property === 'active') {
                return (
                  <>
                    <Select
                      styles={{ optionsHeight: 'auto' }}
                      className={classNames({ readonly: !edit })}
                      disabled={field.isAppend || !edit}
                      onChange={({ value, title }) => rowDataChangeHandler('active', { value, title: title as string })}
                      error={field.error.active ? ' ' : ''}
                      selected={state.active}
                      options={[
                        { title: '활성', value: true },
                        { title: '비활성', value: false },
                      ]}
                    />
                  </>
                );
              }
              if (property === 'description') {
                return (
                  <TextArea
                    readonly={!edit}
                    placeholder="등록할 항목에 대한 설명을 작성하는 칸입니다. 최대 50자까지 입력가능하고, 최대 2줄로 표시됩니다."
                    styles={{ width: '100%', height: 48, marginBottom: 40, fontSize: 13 }}
                    rules={[{ maxLength: 50 }]}
                    className="basic"
                    value={field.description}
                    maxLength={50}
                    onChange={(e) => rowDataChangeHandler('description', { value: e.target.value as string })}
                  />
                );
              }
              if (property === 'remove') {
                return field.isAppend ? (
                  <button
                    type="button"
                    className="admin-manage-subscribe-setting-custom-direct remove"
                    onClick={() => removeHandler(index)}
                  />
                ) : (
                  <></>
                );
              }
              return value || '-';
            }}
          </Table.Tr>
          <Validator.Error error={errorMessage()} top={30} />
        </Table.Row>
        {state.toggle &&
          (field.isAppend ? (
            <ModalPortal setToggle={() => setState({ type: 'toggle', payload: false })}>
              {({ closeHandler }) => (
                <Modal_CustomFieldAdd
                  closeHandler={closeHandler}
                  submitHandler={modalSubmitHandler}
                  type={state.type?.value as 'tree' | 'ox'}
                  field={field}
                  customFieldNames={data.customFields.map((f, i) => (index === i ? '' : f.name))}
                />
              )}
            </ModalPortal>
          ) : (
            <ModalPortal setToggle={() => setState({ type: 'toggle', payload: false })}>
              {({ closeHandler }) => (
                <Modal_CustomFieldEdit closeHandler={closeHandler} submitHandler={modalSubmitHandler} field={field} />
              )}
            </ModalPortal>
          ))}
      </div>
    </div>
  );
};

export default TableRow;
