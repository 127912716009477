import { pick } from 'lodash';
import type { Data } from './ThemeSettingContent';

export type Response =
  | { key: 'allow_custom_theme'; value: boolean }
  | { key: 'use_custom_theme'; value: boolean }
  | { key: 'allow_custom_logo'; value: boolean }
  | { key: 'use_custom_logo'; value: boolean }
  | { key: 'custom_logo'; value: string }
  | { key: 'theme_color'; value: string }
  | { key: 'custom_colors'; value: string };

export const staticChips = [
  { id: 0, color: '#EA2940' },
  { id: 1, color: '#FF790D' },
  { id: 2, color: '#07C050' },
  { id: 3, color: '#2DA7EC' },
  { id: 4, color: '#373EFF' },
  { id: 5, color: '#9A15BB' },
];

export const initialData: Data = {
  allow_custom_theme: false, // 테마 설정 권한 여부
  use_custom_theme: false, // 테마 설정 적용 여부
  use_custom_logo: false, // 고객사 로고 사용 여부
  custom_logo: '', // 고객사 로고 (Base64)
  theme_color: { type: 'static', index: 0, color: '#373EFF', fontColor: '' }, // 고객사 테마 색상
  custom_colors: [], // 사용자설정 테마

  static_colors: staticChips,
  customId: 0,
  base64String: '',
};

export const parseData = (list: Response[]) =>
  pick(
    list.reduce((acc, cur) => {
      if (cur.key === 'custom_colors') {
        const list = JSON.parse(cur.value);
        type Color = { id: number; color: string };
        const colorList: Color[] = list.map((el: string, i: number) => {
          return { color: el, id: i };
        });

        return { ...acc, [cur.key]: colorList };
      }

      if (cur.key === 'theme_color') {
        const theme_color = {
          type: 'static',
          index: 0,
          color: cur.value === 'default' ? '#373EFF' : cur.value.split(',')[0],
          fontColor: cur.value.split(',')[1] || '',
        };
        return { ...acc, [cur.key]: theme_color };
      }

      return { ...acc, [cur.key]: cur.value };
    }, initialData),
    [
      'allow_custom_theme',
      'use_custom_theme',
      'use_custom_logo',
      'custom_logo',
      'theme_color',
      'custom_colors',
      'base64String',
    ]
  );
