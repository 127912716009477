import React, { Dispatch } from 'react';
import { Table, Pagination, Controller, Select, Search, M_Table, M_Select } from 'common/components';
import { time } from 'common/functions';
import type * as M from 'models';
import classNames from 'classnames';
import Expand from './expand/Expand';
import type { State, Action } from './CtReviewTable';
import './ReviewTable.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const ReviewTable: React.FC<Props> = ({ state, setState, loadHandler }): JSX.Element => {
  const { header, data, pagination, searchKey, totalCount } = state;

  const handlers = {
    refresh: loadHandler,
    order: (order: M_Table.sortPayload): void => setState({ type: 'order', payload: order }),
    search: (searchText: string) => setState({ type: 'searchText', payload: Symbol(searchText) }),
    searchKey: (payload: M_Select.item): void => setState({ type: 'searchKey', payload }),
    pagination: (payload: M.Pagination): void => setState({ type: 'pagination', payload }),
  };

  return (
    <section id="reviewer-review-table">
      <Controller title={`심사 수행 (${totalCount})`}>
        <Select
          styles={{ optionsHeight: 'auto' }}
          onChange={handlers.searchKey}
          selected={searchKey}
          options={[
            { title: '선택', value: 'all' },
            { title: '이름', value: 'name' },
            { title: '전화번호', value: 'phone_number' },
            { title: '생년월일', value: 'birthday' },
          ]}
        />
        <Search submitHandler={handlers.search} disabled={searchKey.value === 'all'} />
      </Controller>
      <Table
        data={data}
        header={header}
        config={{
          order: { sort: state.order, onClick: handlers.order },
        }}
      >
        <Table.Head>{({ value }) => value.title}</Table.Head>
        <Table.Body>
          {({ index }) => {
            const rowData = data[index];
            return (
              <Table.Row key={rowData.id} styles={{ height: 'auto' }}>
                <Table.Tr index={index} style={{ height: 72 }}>
                  {({ value, property }) => {
                    if (property === 'request_time') {
                      const epoch = new Date(value).getTime();
                      return time.timeFormatFromUTCEpoch(epoch, 2);
                    }

                    if (property === 'expand') {
                      return (
                        <i
                          className={classNames('expand-icon', { expanded: rowData.expand })}
                          onClick={() => setState({ type: 'expand', payload: index })}
                        />
                      );
                    }
                    return value === 'EXPIRED' ? '만료됨' : value || '-';
                  }}
                </Table.Tr>
                {rowData.expand && <Expand rowData={rowData} refresh={handlers.refresh} />}
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
      <div className="table__context__page__gear">
        <Pagination pagination={pagination} onChange={handlers.pagination} />
      </div>
    </section>
  );
};

export default ReviewTable;
