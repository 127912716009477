import React, { useEffect } from 'react';
import { authActions } from 'store/actions/root';
import { useSelector, useDispatch } from 'common/hooks/root';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}
export const Authentication: React.FC<Props> = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const data = localStorage.getItem('ROOT_TOKEN');
    try {
      if (data) {
        const authObj = JSON.parse(data);
        const expire = new Date(authObj.expire).getTime();
        const bumperTime = 1000 * 60 * 60; // 1 hour (왜 1시간을 빼는지 이유는 모르겠음)
        if (expire - bumperTime - Date.now() <= 0) throw new Error('로그인 만료');
        dispatch(authActions.setData(authObj));
      }
      if (!data) throw new Error('로그인 필요');
    } catch (err) {
      const { message } = err as { message: string };
      console.warn(message);
      dispatch(authActions.logout({ navigate }));
    }
  }, [location.pathname]);

  return <>{auth.data && children}</>;
};
