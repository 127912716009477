import classNames from 'classnames';
import type { M_Select } from 'common/components';
import { Alert, CheckBox, Input, Radio, Select, Validator } from 'common/components';
import { api, process } from 'common/functions';
import { produce } from 'immer';
import { omit } from 'lodash';
import { Layer, modal } from 'layout';
import React, { useReducer } from 'react';
import { Detail } from '../Expand';
import './EditInfoModal.scss';

interface Props {
  detail: Detail;
  loadHandler: () => void;
  closeHandler: () => void;
}

type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'name'; payload: Partial<{ value: string; message: string; check: boolean }> }
  | { type: 'data'; payload: Partial<Customer> }
  | { type: 'modify_check'; payload: number };

type Customer = {
  industry: M_Select.item | null;
  purchase_type: number;
  active: boolean;
  phone_number: string;
};

interface State {
  loading: boolean;
  customer: Customer;
  name: { value: string; message: string; check: boolean };
  modify_check: number;
}

const initialState: State = {
  loading: false,
  customer: {
    industry: null,
    purchase_type: -1,
    active: false,
    phone_number: '',
  },
  name: { value: '', message: '', check: false },
  modify_check: 0,
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'loading':
        draft.loading = action.payload;
        break;
      case 'name':
        draft['name'] = { ...draft.name, ...action.payload };
        break;
      case 'data':
        draft.customer = { ...draft.customer, ...action.payload };
        break;
      case 'modify_check':
        draft.modify_check = action.payload;
        break;
    }
  });
};

const EditInfoModal: React.FC<Props> = ({ detail, loadHandler, closeHandler }) => {
  const initialize = (init: State) => {
    const { name, industry, phone_number, purchase_type, active } = detail;
    let industry_title = '';
    // prettier-ignore
    switch (industry){
      case 1 : industry_title = '비금융권'; break;
      case 2 : industry_title = '금융권'; break;
      case 3 : industry_title = '가상자산사업자'; break;
      case 4 : industry_title = 'P2P온투업자'; break;
    }
    return {
      ...init,
      customer: {
        ...init.customer,
        phone_number,
        purchase_type,
        active,
        industry: { title: industry_title, value: industry },
      },
      name: { ...init.name, value: name },
    };
  };
  const [state, setState] = useReducer(reducer, initialState, initialize);

  const { set } = Alert.Context();

  const defaultData = {
    name: detail.name,
    phone_number: detail.phone_number,
    industry: detail.industry,
    purchase_type: detail.purchase_type,
    active: detail.active,
  };

  let active: Partial<typeof defaultData> | false = false;
  active = api.differ(
    omit({ ...state.customer, name: state.name.value, industry: state.customer.industry?.value }, 'active'),
    omit(defaultData, 'active')
  );

  if (state.customer.active !== defaultData.active && state.modify_check) {
    if (active) active.active = state.customer.active;
    else active = { active: state.customer.active };
  }

  const styles = { marginTop: 8, marginBottom: 20 };

  // 고객사명 중복 확인
  const nameCheckHandler = () => {
    const params = { customer_name: state.name.value };
    process(
      api.reqData({ url: 'find/customer', data: params }),
      api.post.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { result_code } = response.data.api_response;
          let payload = { message: '', check: false };
          if (result_code === 'N100') payload = { message: '이미 사용중인 고객사명입니다.', check: false };
          if (result_code === 'F540') payload = { message: '사용 가능한 고객사명입니다.', check: true };
          setState({ type: 'name', payload });
        }
      })
    );
  };

  const submitHandler = async () => {
    // 이메일 중복 체크
    if (state.name.value !== defaultData.name && !state.name.check) {
      setState({ type: 'name', payload: { message: '중복여부를 확인하세요.' } });
      return;
    }
    setState({ type: 'loading', payload: true });
    const params = {
      ...state.customer,
      id: detail.id,
      name: state.name.value,
      industry: state.customer.industry?.value,
    };
    process(
      api.reqData({ url: 'customers', data: params }),
      api.put.request,
      api.fullFilled(({ response }) => {
        if (response) {
          loadHandler();
          closeHandler();
          set({ success: '계정 정보 수정이 완료되었습니다.' });
        }
      })
    );
  };

  return (
    <Validator.Provider onSubmit={submitHandler} id="edit_info__modal__wrapper">
      <modal.Container>
        <modal.Header align="left">정보 수정</modal.Header>
        <modal.Content>
          <div className="content">
            <modal.Heading>고객사명</modal.Heading>
            <Validator.Provider onSubmit={nameCheckHandler}>
              <div className="name-check">
                <Input
                  className="basic error-text-only"
                  rules={['required']}
                  value={state.name.value}
                  onChange={(e) => {
                    const check = e.target.value === detail.name;
                    setState({ type: 'name', payload: { value: e.target.value, message: '', check } });
                  }}
                />
                <Validator.Submit text="중복확인" disabled={detail.name === state.name.value} />
                {state.name.message && (
                  <div className={classNames('api-check-message', { error: !state.name.check })}>
                    {state.name.message}
                  </div>
                )}
              </div>
            </Validator.Provider>
            <modal.Heading>전화번호</modal.Heading>
            <Layer styles={styles}>
              <Input
                className="basic"
                placeholder="전화번호를 입력하세요."
                rules={['required', 'phone']}
                value={state.customer.phone_number}
                onChange={(e) => setState({ type: 'data', payload: { phone_number: e.target.value } })}
              />
            </Layer>
            <Layer styles={styles}>
              <modal.Heading>구분</modal.Heading>
              <Select
                rules={['required']}
                selected={state.customer.industry}
                styles={{ optionsHeight: 'auto' }}
                onChange={(value) => setState({ type: 'data', payload: { industry: value } })}
                options={[
                  { title: '비금융권', value: 1 },
                  { title: '금융권', value: 2 },
                  { title: '가상자산사업자', value: 3 },
                  { title: 'P2P온투업자', value: 4 },
                ]}
              />
            </Layer>
            <Layer styles={styles}>
              <modal.Heading>구매형태</modal.Heading>
              <Radio.Group
                className="box"
                value={state.customer.purchase_type}
                onChange={(value) => setState({ type: 'data', payload: { purchase_type: value } })}
              >
                <Radio.Item text="솔루션" value={1} />
                <Radio.Item text="API" value={0} />
              </Radio.Group>
            </Layer>

            <Layer styles={styles}>
              <modal.Heading>활성여부</modal.Heading>
              <Radio.Group
                className="box"
                value={state.customer.active}
                onChange={(value) => {
                  setState({ type: 'data', payload: { active: value } });
                  if (value === defaultData.active) setState({ type: 'modify_check', payload: 0 });
                }}
              >
                <Radio.Item text="활성" value />
                <Radio.Item text="비활성" value={false} />
              </Radio.Group>
              <CheckBox
                text="활성여부를 전환할 경우 서비스에 바로 반영됩니다."
                className={classNames({ require: state.customer.active !== detail.active })}
                // className="require"
                disabled={state.customer.active === detail.active}
                checked={state.modify_check}
                onChange={(value) => setState({ type: 'modify_check', payload: value })}
              />
            </Layer>
          </div>
        </modal.Content>
        <modal.Footer disabled={!active} loading={false} submitText="완료" closeHandler={closeHandler} />
      </modal.Container>
    </Validator.Provider>
  );
};

export default EditInfoModal;
