import React, { useReducer, Dispatch } from 'react';
import { Switch, Modal } from 'common/components';
import { produce } from 'immer';
import PortalConfirm from './modal/Portal_Confirm';
import PortalFail from './modal/Portal_Fail';
import Approval from './approval/Approval';
import type { State as TableState, Action as TableAction } from '../CtAdminTable';

type Action =
  | { type: 'close' }
  | { type: 'confirm'; payload: null | boolean }
  | { type: 'fail'; payload: null | { current_count: number; max_count: number } };

interface State {
  confirm: null | boolean;
  fail: null | { current_count: number; max_count: number };
}

const initialState: State = { confirm: null, fail: null };

const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch (action.type) {
      case 'confirm': draft['confirm'] = action.payload; break;
      case 'fail': draft['fail'] = action.payload; break;
      case 'close':
        draft['fail'] = null;
        draft['confirm'] = null;
        break;
    }
  });
};

interface Props {
  state: TableState;
  index: number;
  setState: Dispatch<TableAction>;
  refresh: () => void;
}

const Active: React.FC<Props> = (props) => {
  const [prompt, setPrompt] = useReducer(reducer, initialState);
  const rowData = props.state.data[props.index];

  const value = props.state.data[props.index].active;
  const isConfirm = prompt.confirm !== null && prompt.confirm !== value;
  const isOpen = isConfirm || prompt.fail;

  const closeHandler = () => setPrompt({ type: 'close' });

  return (
    <>
      <Switch value={value} onChange={(value) => setPrompt({ type: 'confirm', payload: !!value })} />
      {isOpen && (
        <Modal.Portal setToggle={closeHandler}>
          {() => {
            return (
              <div>
                {isConfirm && (
                  <PortalConfirm
                    {...props}
                    closeHandler={() => setPrompt({ type: 'confirm', payload: null })}
                    setFail={(payload) => setPrompt({ type: 'fail', payload })}
                  />
                )}
                {prompt.fail && <PortalFail {...props} fail={prompt.fail} closeHandler={closeHandler} />}
              </div>
            );
          }}
        </Modal.Portal>
      )}
      {!rowData.is_approve && <Approval {...props} />}
    </>
  );
};

export default Active;
