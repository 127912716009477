import classNames from 'classnames';
import React from 'react';
import { reduce, toPairs } from 'lodash';
import { Module, VAS } from '../SubscribeContent';
import './Info.scss';

// ! will be replaced after api integrate
export type Manager = {
  admin: number;
  reviewer: number;
};

// ! will be replaced after api integrate
// * VAS refers to value-added-services(부가서비스)

interface Props {
  module: Module;
  vas: VAS;
}

const InfoCard: React.FC<Props> = ({ module, vas }) => {
  const vasObj = {
    email_notification: !!vas.email_notification,
    sms_notification: !!vas.sms_notification,
    custom_field: !!module.custom_field,
  };
  const managers = { admin: 0, reviewer: 0 };

  const reducing = (acc: [string[], string[]], [key, value]: [string, boolean]) => {
    if (!value) return acc;
    if (key === 'id_card_ocr') {
      acc[0].push('신분증인증');
      acc[1].push('신분증OCR/진위확인');
    }
    if (key === 'account_verification') {
      acc[0].push('계좌 인증');
      acc[1].push('1원 계좌인증');
    }
    if (key === 'face_authentication') {
      acc[0].push('얼굴 인증');
      acc[1].push('얼굴 인증');
    }
    if (key === 'liveness') acc[1].push('Liveness');
    return acc;
  };
  const subscription: [string[], string[]] = reduce(toPairs(module || {}), reducing, [[], []]);

  // managers: Manager;
  return (
    <div className="admin-page-manage-subscribe-info">
      <div className="modules">
        <h4 className="title">구독정보</h4>
        <p className="info">
          <span>{subscription[0].join(' + ')}</span>
          <span className="disabled">({subscription[1].join(', ')})</span>
        </p>
      </div>
      <hr />
      <div className="info-wrapper">
        <div className="manager">
          <h4 className="title">관리자 수</h4>
          <div>
            <p className="info">
              <span>어드민</span>
              <span>{managers.admin}명 (TBD)</span>
            </p>
            <p className="info">
              <span>심사자</span>
              <span>{managers.reviewer}명 (TBD)</span>
            </p>
          </div>
        </div>
        <div className="vas">
          <h4 className="title">부가 서비스</h4>
          <div>
            <p className="info">
              <span>이메일 발송</span>
              <span className={classNames({ disabled: !vasObj.email_notification })}>
                {vasObj.email_notification ? '사용' : '미사용'}
              </span>
            </p>
            <p className="info">
              <span>SMS 발송</span>
              <span className={classNames({ disabled: !vasObj.sms_notification })}>
                {vasObj.sms_notification ? '사용' : '미사용'}
              </span>
            </p>
          </div>
        </div>
        <div className="custom">
          <h4 className="title">추가 인증 서비스</h4>
          <p className="info">
            <span className={classNames({ disabled: !vasObj.custom_field })}>
              {vasObj.custom_field ? '사용' : '미사용'}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
