import React, { Dispatch } from 'react';
import { Table, M_Table, Pagination, Controller, Select, Search, M_Select } from 'common/components';
import type * as M from 'models';
import type { State, Action } from './CtReviewerTable';
import Active from './active/Active';
import './ReviewerTable.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const ReviewerTable: React.FC<Props> = ({ state, setState, loadHandler }): JSX.Element => {
  const { header, data, searchKey, pagination } = state;

  const handlers = {
    refresh: loadHandler,
    search: (searchText: string) => setState({ type: 'searchText', payload: Symbol(searchText) }),
    order: (order: M_Table.sortPayload): void => setState({ type: 'order', payload: order }),
    searchKey: (payload: M_Select.item): void => setState({ type: 'searchKey', payload }),
    pagination: (payload: M.Pagination): void => setState({ type: 'pagination', payload }),
  };

  return (
    <section id="admin-reviewer-table">
      <Controller title="심사자 관리">
        <Select
          styles={{ optionsHeight: 'auto' }}
          onChange={handlers.searchKey}
          selected={searchKey}
          options={[
            { title: '선택', value: 'all' },
            { title: '아이디', value: 'username' },
            { title: '이름', value: 'name' },
            { title: '전화번호', value: 'phone_number' },
            { title: '이메일', value: 'email' },
          ]}
        />
        <Search submitHandler={handlers.search} disabled={searchKey.value === 'all'} />
      </Controller>
      <Table
        data={data}
        header={header}
        config={{
          order: { sort: state.order, onClick: handlers.order },
        }}
      >
        <Table.Head>{({ value }) => value.title}</Table.Head>
        <Table.Body>
          {({ index }) => {
            return (
              <Table.Row key={data[index].id} styles={{ height: 72 }}>
                <Table.Tr index={index}>
                  {({ value, property }) => {
                    if (property === 'active')
                      return (
                        <div className="admin-page-reviewer-table-active">
                          <Active index={index} state={state} setState={setState} refresh={handlers.refresh} />
                        </div>
                      );
                    return value || '-';
                  }}
                </Table.Tr>
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
      <div className="table__context__page__gear">
        <Pagination pagination={pagination} onChange={handlers.pagination} />
      </div>
    </section>
  );
};

export default ReviewerTable;
