import React from 'react';
import { RaResult as IRaResult } from '../../../Expand';

function RaResult({ ra_result, isExpired }: { ra_result: IRaResult; isExpired: boolean }) {
  const { ra_grade, ra_score, customer_score, customer_std_score, svc_score, svc_std_score, country_score, country_std_score } = ra_result; // prettier-ignore

  const raGradeMap = { low: '저위험', middle: '중위험', high: '고위험' } as const;

  return (
    <article className="reviewer-review-ra-result">
      <h4>RA (고객 위험도 평가)</h4>
      <div className="info-section">
        <div className="ra-result">
          <h4>위험 평가 결과</h4>
          <p className={`${ra_grade}`}>{isExpired ? '만료됨' : raGradeMap[ra_grade]}</p>
        </div>
        <div className="ra-result-table">
          <div className="ra-result-table-head">
            <div className="ra-result-table-row">
              <span />
              <span>평가점수</span>
              <span>가중치적용배점</span>
            </div>
          </div>
          <div className="ra-result-table-body">
            <div className="ra-result-table-row">
              <h4>고객 위험</h4>
              <span>{isExpired ? '만료됨' : `${customer_score}점`}</span>
              <span>{isExpired ? '만료됨' : `${customer_std_score}점`}</span>
            </div>
            <div className="ra-result-table-row">
              <h4>상품 및 서비스 위험</h4>
              <span>{isExpired ? '만료됨' : `${svc_score}점`}</span>
              <span>{isExpired ? '만료됨' : `${svc_std_score}점`}</span>
            </div>
            <div className="ra-result-table-row">
              <h4>국가 위험</h4>
              <span>{isExpired ? '만료됨' : `${country_score}점`}</span>
              <span>{isExpired ? '만료됨' : `${country_std_score}점`}</span>
            </div>
          </div>
          <div className="ra-result-table-footer">
            <div className="ra-result-table-row">
              <h4>총점</h4>
              <span className={`${ra_grade}`}>{isExpired ? '만료됨' : `${ra_score}점`}</span>
              <span>100점</span>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default RaResult;
