import React, { Dispatch, createContext, useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { api, process } from 'common/functions';
import { ContentContext } from 'layout/admin/content/Content';
import { EddRa } from './model.type';
import { isDisabled } from './func';
import { makePayload } from '../ra/settings/util';

type EddContextProps = {
  edit: boolean;
  toggleEditHandler: () => void;
  defaultHandler: () => void;
  deleteHandler: (arg: { category: 'customer' | 'service'; id: number }) => void;
  submitHandler: () => void;
  use_ra: boolean;
  state: State;
  setState: Dispatch<Action>;
  disabled: boolean;
  errorMessage: string;
};
const EddContext = createContext<EddContextProps | null>(null);

type Action =
  | { type: 'data'; payload: Data }
  | { type: 'defaultData'; payload: Data }
  | { type: 'detailPage'; payload: EddRa.Detail }
  | { type: 'customer'; payload: EddRa.Customer[] }
  | { type: 'service'; payload: EddRa.Service[] }
  | { type: 'country'; payload: EddRa.Country[] }
  | { type: 'deleteIds'; payload: { category: 'customer' | 'service'; ids: number[] } }
  | { type: 'edd_countries_mode'; payload: number };
export type Data = {
  customer: EddRa.Customer[];
  service: EddRa.Service[];
  country: EddRa.Country[];
  edd_countries_mode: number;
};
type State = {
  data: Data;
  defaultData: Data;
  detailPage: EddRa.Detail;
  deleteIds: { customer: number[]; service: number[] };
};

const initialData: Data = {
  customer: [],
  service: [],
  country: [],
  edd_countries_mode: 1,
};
const initialState: State = {
  data: initialData,
  defaultData: initialData,
  detailPage: 'none',
  deleteIds: { customer: [], service: [] },
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'data':
        draft['data'] = action.payload;
        break;
      case 'defaultData':
        draft['defaultData'] = action.payload;
        break;
      case 'detailPage':
        draft['detailPage'] = action.payload;
        break;
      case 'customer':
        draft['data']['customer'] = action.payload;
        break;
      case 'service':
        draft['data']['service'] = action.payload;
        break;
      case 'country':
        draft['data']['country'] = action.payload;
        break;
      case 'deleteIds':
        draft['deleteIds'][action.payload.category] = action.payload.ids;
        break;
      case 'edd_countries_mode':
        draft['data']['edd_countries_mode'] = action.payload;
        break;
    }
  });
};

const get = (url: string) => {
  return process(
    api.reqData({ url }),
    api.get.request,
    api.fullFilled(({ response }) => {
      if (response?.data.list) return response?.data.list;
      return undefined;
    })
  );
};

// TODO: PUT API int
// const put = (url: string, data: { use_edd?: string; use_ra?: string; edd_countries_mode?: string }) => {
//   return process(api.reqData({ url, data }), api.put.request);
// };

interface EddContextProviderProps {
  edit: boolean;
  toggleEditHandler: () => void;
  use_edd: boolean;
  use_ra: boolean;
  edd_countries_mode: number;
  configLoadHandler: () => void;
  children: JSX.Element;
}
function EddContextProvider(props: EddContextProviderProps) {
  const { setLoading } = useContext(ContentContext);
  const { edit, toggleEditHandler, use_edd, use_ra, edd_countries_mode, configLoadHandler } = props;
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);

    const [customer, service, country] = await Promise.all([
      get('field/edd_fields/customer'),
      get('field/edd_fields/service'),
      get(edd_countries_mode === 1 ? 'country?warn_only=true' : 'edd_countries'),
    ]);

    setState({ type: 'data', payload: { customer, service, country, edd_countries_mode } });
    setState({ type: 'defaultData', payload: { customer, service, country, edd_countries_mode } });
    setState({ type: 'deleteIds', payload: { category: 'customer', ids: [] } });
    setState({ type: 'deleteIds', payload: { category: 'service', ids: [] } });

    setLoading(false);
  };

  const defaultHandler = () => {
    setState({ type: 'data', payload: state.defaultData });
    setState({ type: 'deleteIds', payload: { category: 'customer', ids: [] } });
    setState({ type: 'deleteIds', payload: { category: 'service', ids: [] } });
  };

  const deleteHandler = (arg: { category: 'customer' | 'service'; id: number }) => {
    const payload = { category: arg.category, ids: [...state.deleteIds[arg.category], arg.id] };
    setState({ type: 'deleteIds', payload });
  };

  const submitHandler = () => {
    setLoading(true);

    const { country, edd_countries_mode } = state.data;
    const {
      customer: d_customer,
      service: d_service,
      country: d_country,
      edd_countries_mode: d_ecm,
    } = state.defaultData;
    const customer = {
      ...makePayload(use_edd ? state.data.customer : d_customer),
      delete: use_edd ? state.deleteIds.customer : [],
    };
    const service = {
      ...makePayload(use_edd ? state.data.service : d_service),
      delete: use_edd ? state.deleteIds.service : [],
    };

    const payload = {
      use_edd,
      use_ra,
      edd_countries_mode: use_edd ? edd_countries_mode : d_ecm,
      edd_item: { customer, service },
      ...(edd_countries_mode === 2
        ? { edd_countries: (use_edd ? country : d_country).map((c) => ({ id: c.id, score: c.score })) }
        : null),
    };

    process(
      api.reqData({ url: 'field/edd_fields', data: payload }),
      api.put.request,
      api.fullFilled(() => {
        configLoadHandler();
        loadHandler();
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    loadHandler();
  }, []);

  const res = isDisabled(state.data, use_ra);
  const disabled = use_edd && res.disabled;
  const errorMessage = use_edd ? res.errorMessage : '';

  const value = { edit, toggleEditHandler, deleteHandler, defaultHandler, submitHandler, use_ra, state, setState, disabled, errorMessage }; // prettier-ignore
  return <EddContext.Provider value={value}>{props.children}</EddContext.Provider>;
}

EddContextProvider.Consumer = EddContext.Consumer as React.Consumer<EddContextProps>;

export default EddContextProvider;

export const useEddConext = () => {
  const context = useContext(EddContext);
  if (!context) throw new Error('EddContext is null');
  return context;
};
