import React from 'react';
import classNames from 'classnames';
import * as M from 'models';

interface Props {
  pagination: M.Pagination;
  onChange: (pagination: M.Pagination) => void;
  disabled?: boolean;
}

const Pagination: React.FC<Props> = ({ pagination, onChange, disabled }) => {
  const { offset, limit } = pagination.format;
  const { total, current } = pagination.info;
  const pageChaper = Math.floor((current - 1) / 10) * 10;

  const pagingHandler = (offset: number) => {
    if (disabled) return;
    onChange({
      info: { ...pagination.info, current: offset / limit + 1 },
      format: { ...pagination.format, offset },
    });
  };

  const chapter = [...Array(total)].slice(pageChaper, pageChaper + 10);

  const items = chapter.map((_, i) => {
    const index = i + pageChaper;
    return (
      <div
        key={index}
        className={classNames('pagination-item', { active: current - 1 === index })}
        onClick={(): void => pagingHandler(index * limit)}
      >
        {index + 1}
      </div>
    );
  });

  return (
    <div className="jack__pagination">
      <div className="jack__pagination__buttons">
        <div className="jack__left__arrow__start" onClick={() => pagingHandler(0)}>
          <i />
        </div>
        <div
          className="jack__left__arrow"
          onClick={(): void => {
            const offset = (current - 2) * limit;
            pagingHandler(offset < 0 ? 0 : offset);
          }}
        >
          <i />
        </div>
        <div className="jack__page__items">{items}</div>
        <div
          className="jack__right__arrow"
          onClick={() => {
            pagingHandler(offset / limit + 1 < total ? offset + limit : offset);
          }}
        >
          <i />
        </div>
        <div className="jack__right__arrow__end" onClick={() => pagingHandler((total - 1) * limit)}>
          <i />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
