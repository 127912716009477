import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { api, process } from 'common/functions';
import { Button, M_Select } from 'common/components';
import { ContentContext } from 'layout/admin/content/Content';
import classNames from 'classnames';
import EddContextProvider from '../context/EddContext';
import Promotion from '../promotion/Promotion';
import Forbidden from '../forbidden/Forbidden';
import ConfigController from '../contoller/ConfigController';
import RaSettings from './settings/RaSettings';
import RiskDetail from './detail/RiskDetail';
import './RaContent.scss';

type Data = {
  module: { edd_field: boolean };
  configs: {
    allow_ra: boolean;
    use_edd: boolean;
    use_ra: boolean;
    use_custom_field: boolean;
    edd_countries_mode: number;
  };
  template: M_Select.item;
};
export type State = {
  edit: boolean;
  data: Data;
  defaultData: Data;
};

export type Action =
  | { type: 'setEdit'; payload: boolean }
  | { type: 'setData'; payload: Partial<Data> }
  | { type: 'setDefaultData'; payload: Partial<Data> };

const initialData = {
  module: { edd_field: false },
  configs: { allow_ra: false, use_edd: false, use_ra: false, use_custom_field: false, edd_countries_mode: 1 },
  template: { title: '기본 템플릿 설정', value: 'default' },
};
const initialState: State = {
  edit: false,
  data: { ...initialData },
  defaultData: { ...initialData },
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'setEdit': draft['edit'] = action.payload; break;
      case 'setData': draft['data'] = { ...draft.data, ...action.payload }; break;
      case 'setDefaultData': draft['defaultData'] = { ...draft.defaultData, ...action.payload }; break;
    }
  });
};

const get = (url: string) => {
  return process(
    api.reqData({ url }),
    api.get.request,
    api.fullFilled(({ response }) => {
      if (response?.data.list) return response?.data.list;
      if (response?.data.module) return response?.data.module;
      return undefined;
    })
  );
};

// const put = (url: string, data: { use_edd?: string; use_ra?: string; edd_countries_mode?: string }) => {
//   return process(api.reqData({ url, data }), api.put.request);
// };
type ConfigsKey = keyof Data['configs'];
type ResponseConfig = { key: ConfigsKey; value: boolean | number };
const parseConfigs = (list: ResponseConfig[]) => {
  return list.reduce((acc, cur) => {
    if (Object.keys(initialData.configs).includes(cur.key)) {
      return { ...acc, [cur.key]: cur.value };
    }
    return acc;
  }, initialData.configs);
};

function RaContent() {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);

    const [list, module] = await Promise.all([get('configs'), get('modules')]);

    const configs = parseConfigs(list);
    const template = { title: '기본 템플릿 설정', value: 'default' };
    setState({ type: 'setData', payload: { configs, module, template } });
    setState({ type: 'setDefaultData', payload: { configs, module, template } });

    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  // const handleEddRaConfigSubmit = async () => {
  //   const { use_edd, use_ra } = state.data.configs;
  //   setLoading(true);
  //   await put('configs', { use_edd: use_edd.toString(), use_ra: use_ra.toString() });

  //   loadHandler();

  //   setLoading(false);
  // };

  const toggleEditHandler = () => {
    setState({ type: 'setEdit', payload: !state.edit });
  };

  const { allow_ra, use_ra, use_edd, edd_countries_mode } = state.data.configs;
  const raAbled = allow_ra && use_ra;
  const contextProps = { use_edd, use_ra: raAbled, edd_countries_mode, configLoadHandler: loadHandler };

  return (
    <div id="admin-manage-edd-ra-content">
      {state.defaultData.module.edd_field ? (
        <EddContextProvider edit={state.edit} toggleEditHandler={toggleEditHandler} {...contextProps}>
          <EddContextProvider.Consumer>
            {({ state: contextState, disabled, errorMessage, defaultHandler, submitHandler }) => {
              return (
                <>
                  <div className="header">
                    <h3>위험별 설정</h3>
                    <div className="error-message">
                      <div className="message">{disabled && errorMessage}</div>
                    </div>
                    <div className="button-group">
                      {state.edit ? (
                        <>
                          <Button
                            text="취소"
                            onClick={() => {
                              setState({ type: 'setData', payload: state.defaultData });
                              setState({ type: 'setEdit', payload: false });
                              defaultHandler();
                            }}
                          />
                          <Button
                            text="저장"
                            disabled={disabled}
                            onClick={() => {
                              setState({ type: 'setEdit', payload: false });
                              // handleEddRaConfigSubmit();
                              submitHandler();
                            }}
                          />
                        </>
                      ) : (
                        <Button text="편집" onClick={() => setState({ type: 'setEdit', payload: true })} />
                      )}
                    </div>
                  </div>
                  <div className={classNames({ detail: contextState.detailPage !== 'none' })}>
                    <ConfigController state={state} setState={setState} />
                  </div>
                  {state.data.configs.use_edd ? (
                    <RaSettings />
                  ) : (
                    <Forbidden
                      content={
                        <>
                          <p>상단의 EDD 및 RA 기능을 활성화 하신 후</p>
                          <p>상세 위험을 설정하실 수 있습니다</p>
                        </>
                      }
                    />
                  )}
                  <RiskDetail />
                </>
              );
            }}
          </EddContextProvider.Consumer>
        </EddContextProvider>
      ) : (
        <Promotion />
      )}
    </div>
  );
}

export default RaContent;
