import { useEffect, useState } from 'react';

export function useSubcodePosition(vas: { use_account_subcode: boolean; account_subcode_position: string }) {
  const { use_account_subcode, account_subcode_position } = vas;
  if (!use_account_subcode) {
    return { subcodePositionValue: 0, bgPosition: '' };
  }
  if (account_subcode_position === 'left') {
    return { subcodePositionValue: 1, bgPosition: 'left' };
  }
  return { subcodePositionValue: 2, bgPosition: 'right' };
}

export function useSubcode({ account_subcode, position }: { account_subcode: string; position: string }) {
  const [subcodeLeft, setSubcodeLeft] = useState(account_subcode);
  const [subcodeRight, setSubcodeRight] = useState(account_subcode);

  useEffect(() => {
    if (position === 'left') setSubcodeLeft(() => account_subcode);
    if (position === 'right') setSubcodeRight(() => account_subcode);
  }, [account_subcode]);

  return { subcodeLeft, setSubcodeLeft, subcodeRight, setSubcodeRight };
}
