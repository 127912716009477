import { reduce } from 'lodash';
import { Data } from './EddContext';
import { EddRa } from './model.type';
import { checkActiveRaScore } from '../ra/settings/util';

export const isDisabled = (data: Data, use_ra: boolean) => {
  const { customer, service } = data;

  const getTotalScore = (acc: number, cur: EddRa.EddItem) => acc + cur.score;
  const customerTotalScore = reduce(customer, getTotalScore, 0);
  const serviceTotalScore = reduce(service, getTotalScore, 0);

  const checkCustomerScore = checkActiveRaScore(customer);
  const checkServiceScore = checkActiveRaScore(service);

  const satisfiedCustomerScore = checkCustomerScore && customerTotalScore === 100;
  const satisfiedServiceScore = checkServiceScore && serviceTotalScore === 100;
  const eachAtLeastOneActive = customer.some((el) => el.active) && service.some((el) => el.active);

  const errorMessage: string = (() => {
    if (!checkCustomerScore || !checkServiceScore) {
      return '항목 내 선택지 점수를 확인해주세요.';
    }
    if (customerTotalScore !== 100 || serviceTotalScore !== 100) {
      return '각 위험별로 남은 점수는 0점이 되어야 합니다.';
    }
    return '';
  })();

  const disabled = (use_ra && (!satisfiedCustomerScore || !satisfiedServiceScore)) || !eachAtLeastOneActive;
  return { disabled, errorMessage };
};
