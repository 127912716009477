import React, { useEffect, useReducer, useContext } from 'react';
import { ContentContext } from 'layout/admin/content/Content';
import { produce } from 'immer';
import { api, process } from 'common/functions';
import { NullishGuard } from 'hoc';
import { values } from 'lodash';
import Privacy from './content/privacy/Privacy';
import Policy from './content/policy/Policy';
import Navigator from './nav/Navigator';
import type { Data as RowData } from '../CtTermsTable';
import './Expand.scss';

interface Props {
  rowData: RowData;
}

export type Data = { title: string; content: string };
type Action = { type: 'setData'; payload: { privacy: Data[]; policy: Data[] } } | { type: 'tab'; payload: 0 | 1 };
export interface State {
  privacy: Data[] | null;
  policy: Data[] | null;
  tab: 0 | 1;
}

const initialState: State = { privacy: null, policy: null, tab: 0 };
const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData':
        draft['privacy'] = action.payload.privacy;
        draft['policy'] = action.payload.policy;
      break;
      case 'tab': draft['tab'] = action.payload; break;
    }
  });
};

const Expand: React.FC<Props> = ({ rowData }) => {
  const [state, setState] = useReducer(reducer, initialState);
  const { setLoading } = useContext(ContentContext);
  const { privacy, policy, tab } = state;

  const isPrivacy = !!(privacy && tab === 0); // 개인정보 수집 동의서 탭
  const isPolicy = !!(policy && tab === 1); // 개인정보처리방침 탭

  const loadHandler = async () => {
    if (privacy === null && policy === null) {
      setLoading(true);
      const getData = (url: string) => {
        return process(
          api.reqData({ url }),
          api.get.request,
          api.fullFilled(({ response }) => {
            if (response && response.data) {
              const { api_response, detail } = response.data;
              if (api_response.result_code === 'N100') {
                const html = JSON.parse(decodeURIComponent(atob(detail.content)));
                return html;
              }
            }
            return { noData: { title: '데이터 없음', content: '데이터 없음 ' } };
          })
        );
      };
      const queue = [getData(`guides/${rowData.url.collection}`), getData(`guides/${rowData.url.policy}`)];
      const [privacy, policy] = await Promise.all(queue);
      setState({ type: 'setData', payload: { privacy: values(privacy), policy: values(policy) } });
      setLoading(false);
    }
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <NullishGuard data={[privacy || policy]}>
      <section className="admin-term-table-tr-expand">
        <Navigator tab={tab} onChange={(tab) => setState({ type: 'tab', payload: tab })} />
        {isPrivacy && <Privacy privacy={privacy} />}
        {isPolicy && <Policy policy={policy} />}
      </section>
    </NullishGuard>
  );
};

export default Expand;
