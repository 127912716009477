import React from 'react';
import { M_Table, Table } from 'common/components';
import { DndProvider } from 'react-dnd';
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CustomField, useCustomContext } from '../../context/CustomFieldContext';
import TableRow from './TableRow';
import './Direct.scss';

const newField = (appendId: number): CustomField => ({
  id: appendId,
  order: 1,
  name: '',
  type: '',
  description: '',
  required: null,
  active: true,
  isAppend: true,
  tree: null,
  error: { type: false, name: false, active: false, duplicate: false },
});

interface Props {
  edit: boolean;
}

const Direct: React.FC<Props> = ({ edit }) => {
  const { state, setState } = useCustomContext();
  const { data } = state;
  const { customFields: fieldList } = data;
  const header: M_Table.header[] = [
    { title: '', property: 'allign', width: 48, align: 'center' },
    { title: '타입', property: 'type', width: 136 },
    { title: '항목명', property: 'name', width: 220 },
    { title: '필수', property: 'required', width: 140 },
    { title: '활성', property: 'active', width: 110 },
    { title: '설명', property: 'description', width: 315, align: 'center' },
    { title: '', property: 'remove', width: 47 },
  ];

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const newField = update(fieldList, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, fieldList[dragIndex] as CustomField],
      ],
    });

    if (newField[0]) setState({ type: 'drag', payload: newField });
  };

  const appendCustomField = () => {
    let appendId = 1;
    if (fieldList.length > 0) {
      appendId = [...fieldList].sort((a, b) => b.id - a.id)[0].id + 1;
    }

    setState({ type: 'setData', payload: { customFields: [...fieldList, newField(appendId)] } });
  };

  const removeHandler = (index: number) => {
    setState({ type: 'setData', payload: { customFields: fieldList.filter((_, idx) => idx !== index) } });
  };

  return (
    <div id="admin-manage-customfield-setting-direct">
      <div className="header">
        <div className="title subtitle">직접 등록 항목</div>
      </div>
      <div className="content">
        <DndProvider backend={HTML5Backend}>
          <Table data={edit ? fieldList : fieldList.filter((field) => field.active)} header={header}>
            <Table.Head>{({ value }) => value.title}</Table.Head>
            <Table.Body>
              {({ index }) => {
                const field = edit ? fieldList[index] : fieldList.filter((field) => field.active)[index];
                return (
                  <TableRow
                    id={field.order}
                    key={field.id}
                    index={index}
                    edit={edit}
                    field={field}
                    moveCard={moveCard}
                    removeHandler={removeHandler}
                  />
                );
              }}
            </Table.Body>
          </Table>
          {edit && (
            <div className="custom__no__data" onClick={appendCustomField}>
              <i className="custom__no__data__icon" />
              추가
            </div>
          )}
        </DndProvider>
      </div>
    </div>
  );
};

export default Direct;
