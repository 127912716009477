/* eslint-disable react/no-danger */
import React, { useEffect, useReducer, useContext } from 'react';
import { produce } from 'immer';
import { NullishGuard } from 'hoc';
import { Button, Alert, TextArea } from 'common/components';
import { api, process } from 'common/functions';
import { ContentContext } from 'layout/root/content/Content';
import type { Data as RowData } from '../CtPrivacyTable';
import './Expand.scss';

interface Props {
  rowData: RowData;
}

type Action = { type: 'setData'; payload: string };
export interface State {
  data: string | null;
}

const initialState: State = { data: null };
const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break;
    }
  });
};

const Expand: React.FC<Props> = ({ rowData }) => {
  const { setLoading } = useContext(ContentContext);
  const { set } = Alert.Context();
  const [state, setState] = useReducer(reducer, initialState);
  const { data } = state;

  useEffect(() => {
    const getExpandData = async () => {
      setLoading(true);
      process(
        api.reqData({ url: `notices/pp/${rowData.version}` }),
        api.get.request,
        api.fullFilled(({ response }) => {
          if (response) {
            const { detail } = response.data;
            const html = decodeURIComponent(atob(detail.content));
            setState({ type: 'setData', payload: html });
          }
          setLoading(false);
        })
      );
    };
    getExpandData();
  }, []);

  return (
    <NullishGuard data={[data]}>
      {([data]) => (
        <section className="root-term-privacy-table-tr-expand">
          <div className="term-content">
            <div className="term-title">약관 내용</div>
            <div className="inner-html" dangerouslySetInnerHTML={{ __html: data }} />
          </div>

          <div className="term-content">
            <div className="term-title">
              약관 HTML
              <Button
                text="HTML 복사하기"
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(data);
                    set({ success: 'HTML 복사가 완료되었습니다.' });
                  } catch {
                    set({ fail: 'HTML 복사는 실패되었습니다.' });
                  }
                }}
              />
            </div>
            <TextArea readonly className="html-textarea" value={data} styles={{ width: '100%', height: 136 }} />
          </div>
        </section>
      )}
    </NullishGuard>
  );
};

export default Expand;
