import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 0;
`;

type Props = {
  st?: React.CSSProperties;
  id?: string;
  className?: string;
  onClick?: (e: React.UIEvent) => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const InputCheck = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#363636', ...st }} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 20 20" fill="none">
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m 3.479,10 5,5 8.334,-10"
          id="path1276"
        />
      </svg>
    </Div>
  );
};

export const Lock = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#cccccc', ...st }} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
        <g id="g1524" transform="translate(-215,-196)" fill="none">
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m 220.636,210.346 c -1.846,-1.311 -3.305,-3.014 -4.131,-4.09 -0.23,-0.297 -0.355,-0.663 -0.355,-1.039 0,-0.376 0.125,-0.742 0.355,-1.039 1.494,-1.949 5.076,-5.962 9.258,-5.962 1.875,0 3.629,0.807 5.129,1.874"
            id="path1516"
          />
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m 227.894,203.104 c -0.277,-0.283 -0.608,-0.507 -0.973,-0.661 -0.365,-0.154 -0.758,-0.234 -1.153,-0.235 -0.395,-0.001 -0.788,0.075 -1.155,0.226 -0.365,0.15 -0.698,0.373 -0.979,0.652 -0.281,0.279 -0.502,0.613 -0.652,0.979 -0.151,0.366 -0.229,0.758 -0.227,1.154 0.002,0.396 0.082,0.789 0.235,1.153 0.153,0.365 0.378,0.696 0.661,0.974"
            id="path1518"
          />
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m 217.763,213.217 16,-16"
            id="path1520"
          />
          <path
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m 223.763,211.921 c 0.649,0.193 1.322,0.293 2,0.296 4.182,0 7.764,-4.014 9.257,-5.962 0.231,-0.298 0.356,-0.663 0.355,-1.039 0,-0.376 -0.126,-0.742 -0.356,-1.039 -0.524,-0.685 -1.088,-1.339 -1.686,-1.96"
            id="path1522"
          />
        </g>
      </svg>
    </Div>
  );
};

export const UnLock = (props: Props) => {
  const { st, ...rest } = props;
  return (
    <Div style={{ color: '#cccccc', ...st }} {...rest}>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
        <g id="g4447" transform="translate(-1,-3)" fill="none">
          <path
            d="m 21.257,10.962 c 0.474,0.62 0.474,1.457 0,2.076 C 19.764,14.987 16.182,19 12,19 7.81801,19 4.23601,14.987 2.74301,13.038 2.51239,12.7411 2.38721,12.3759 2.38721,12 c 0,-0.3759 0.12518,-0.7411 0.3558,-1.038 C 4.23601,9.013 7.81801,5 12,5 c 4.182,0 7.764,4.013 9.257,5.962 z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            id="path4435"
          />
          <path
            d="m 12,15 c 1.6569,0 3,-1.3431 3,-3 0,-1.6569 -1.3431,-3 -3,-3 -1.6569,0 -3,1.3431 -3,3 0,1.6569 1.3431,3 3,3 z"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            id="path4437"
          />
        </g>
      </svg>
    </Div>
  );
};
