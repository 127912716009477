import React, { Dispatch } from 'react';
import classNames from 'classnames';
import { Input, Switch } from 'common/components';
import type { Data, Action } from '../Expand';
import './Addons.scss';

interface Props {
  data: Data;
  edit: boolean;
  setState: Dispatch<Action>;
}

const Addons = ({ edit, data, setState }: Props) => {
  const { module, config } = data;
  const attrs = {
    type: 'number',
    min: '1',
    max: '100',
    styles: { width: 54, height: 22 },
    rules: [{ min: 1, max: 100 }],
  };

  return (
    <article className="root-page-client-expand-addons">
      <h5>부가 기능 설정</h5>
      <div className="addons-container">
        <div className="addons-item">
          <p className="extras-title">고객 신원 확인 결과</p>
          <div className="category">
            <div className="cate-title">
              이메일 발송
              <Switch
                disabled={!edit}
                className={classNames({ readonly: !edit })}
                value={config.email_notification}
                onChange={(value) => {
                  setState({ type: 'config', payload: { email_notification: !!value } });
                }}
              />
            </div>
          </div>
          <div className="category">
            <div className="cate-title">
              SMS 발송
              <Switch
                disabled={!edit}
                className={classNames({ readonly: !edit })}
                value={config.sms_notification}
                onChange={(value) => {
                  setState({ type: 'config', payload: { sms_notification: !!value } });
                }}
              />
            </div>
          </div>
        </div>
        <div className="addons-item">
          <p className="extras-title">이어하기</p>
          <div className="category">
            <div className="cate-title">
              이어하기 사용
              <Switch
                disabled={!edit}
                className={classNames({ readonly: !edit })}
                value={config.allow_reuse}
                onChange={(value) => {
                  setState({ type: 'config', payload: { allow_reuse: !!value } });
                }}
              />
            </div>
          </div>
          {config.allow_reuse && (
            <div className="category reuse">
              <Input
                {...attrs}
                readonly={!edit}
                value={config.reuse_limit_minute / 1440}
                onChange={(e) => {
                  let { value } = e.target;
                  if (Number(value) < 1) value = '1';
                  if (Number(value) > 100) value = '100';
                  setState({ type: 'config', payload: { reuse_limit_minute: Number(value) * 1440 } });
                }}
              />
              일 <span style={{ marginLeft: 'auto' }}>이후 모든 정보 만료</span>
            </div>
          )}
        </div>
        <div className="addons-item">
          <p className="extras-title">테마 설정</p>
          <div className="category">
            <div className="cate-title">
              커스텀 테마 사용
              <Switch
                className={classNames({ readonly: !edit })}
                disabled={!edit}
                value={config.allow_custom_theme}
                onChange={(value) => {
                  setState({ type: 'config', payload: { allow_custom_theme: !!value } });
                }}
              />
            </div>
          </div>
        </div>
        <div className="addons-item">
          <p className="extras-title">신분증 얼굴 검출</p>
          <div className="category">
            <div className="cate-title">
              신분증 얼굴 검출 사용
              <Switch
                className={classNames({ readonly: !edit })}
                disabled={!module.id_card_ocr || !edit}
                value={config.use_idcard_face_detect}
                onChange={(value) => {
                  setState({ type: 'config', payload: { use_idcard_face_detect: !!value } });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Addons;
