import React, { Dispatch } from 'react';
import { Table, Controller } from 'common/components';
import classNames from 'classnames';
import type { State, Action } from './CtTermsTable';
import Expand from './expand/Expand';
import './TermsTable.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
}

const TermsTable: React.FC<Props> = ({ state, setState }): JSX.Element => {
  const { header, data } = state;

  return (
    <section id="admin-terms-table">
      <Controller title="약관 가이드" />
      <Table data={data} header={header}>
        <Table.Head>{({ value }) => value.title}</Table.Head>
        <Table.Body>
          {({ index }) => {
            const rowData = data[index];
            return (
              <Table.Row key={rowData.id} styles={{ height: rowData.expand ? 'auto' : 72 }}>
                <Table.Tr index={index} style={{ height: 72 }}>
                  {({ value, property }) => {
                    if (property === 'expand')
                      return (
                        <i
                          className={classNames('expand-icon', { expanded: rowData.expand })}
                          onClick={() => setState({ type: 'expand', payload: index })}
                        />
                      );
                    return value;
                  }}
                </Table.Tr>
                {rowData.expand && <Expand rowData={rowData} />}
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
    </section>
  );
};

export default TermsTable;
