import classNames from 'classnames';
import { NullishGuard } from 'hoc';
import { produce } from 'immer';
import React, { useReducer } from 'react';
import { Link, useParams } from 'react-router-dom';
import './SignUpPage.scss';
import Agreement from './steps/Agreement';
import Client from './steps/Client';
import Information from './steps/Information';

export type Action = { type: 'toggle' } | { type: 'step'; payload: number } | { type: 'customer_id'; payload: number };

export interface State {
  step: number;
  toggle: boolean;
  customer_id: null | number;
}

const initialState: State = { step: 1, toggle: true, customer_id: null };
const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch (action.type) {
      case 'toggle': draft['toggle'] = !state.toggle; break;
      case 'step': draft['step'] = action.payload; break;
      case 'customer_id': draft['customer_id'] = action.payload; break;
    }
  });
};

const SignUpPage: React.FC = () => {
  const { role } = useParams();
  const [state, setState] = useReducer(reducer, initialState);

  return (
    <div id="kyc-sign-up-page">
      <div className="sign-up-content">
        <i className="logo" />
        <NullishGuard data={[state.step !== 4]}>
          <div className="step-field">
            <div className="step-informaion">
              <span className="title">{state.step === 1 ? '약관 동의' : '정보 입력'}</span>
              <div className="steps">
                {[1, 2, 3].map((el) => (
                  <i key={el} className={classNames({ active: state.step === el })}>
                    {el}
                  </i>
                ))}
              </div>
            </div>
            {state.step === 1 && <Agreement state={state} setState={setState} />}
            {state.step === 2 && <Client setState={setState} />}
            {state.step === 3 && <Information state={state} setState={setState} />}
          </div>
        </NullishGuard>

        <NullishGuard data={[state.step === 4]}>
          <div className="success-field">
            <div className="success-icon">
              <i />
            </div>
            <p className="notice-text">신청이 완료되었습니다.</p>
            <p className="description">입력하신 이메일을 통해 승인 여부를 안내드리겠습니다.</p>

            <Link to={`/${role}`} className="done">
              확인
            </Link>
          </div>
        </NullishGuard>
      </div>
    </div>
  );
};

export default SignUpPage;
