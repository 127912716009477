import React, { useState, Dispatch } from 'react';
import { Validator, Input } from 'common/components';
import { modal, Layer } from 'layout';
import { Admin } from 'models';
import { authActions } from 'store/actions/admin';
import { useDispatch } from 'common/hooks/admin';
import type { Action } from '../Modal_ModifyMy';

interface Props {
  setState: Dispatch<Action>;
  my: Admin.Auth.my;
  closeHandler: () => void;
}

const PwCheck: React.FC<Props> = ({ closeHandler, setState, my }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const submitHandler = async () => {
    setLoading(true);

    const { payload: errorMessage } = await dispatch(authActions.pwCheck(password));
    if (errorMessage) {
      setError(errorMessage);
      setLoading(false);
    } else {
      setState({ type: 'step', payload: 1 });
      setState({ type: 'password', payload: password });
    }
  };

  return (
    <Validator.Provider form onSubmit={submitHandler}>
      <modal.Header align="center">비밀번호 확인</modal.Header>
      <modal.Content>
        <div className="content">
          <p>
            <span>{my.name}</span>님의 <br />
            안전한 정보 보호를 위하여 비밀번호를 재확인 합니다.
          </p>
          <Layer styles={{ marginTop: 16 }}>
            <Input
              className="basic"
              type="password"
              error={error}
              onChange={(e) => {
                if (error) setError('');
                setPassword(e.target.value);
              }}
            />
          </Layer>
        </div>
      </modal.Content>
      <modal.Footer loading={loading} disabled={!password} closeHandler={closeHandler} />
    </Validator.Provider>
  );
};

export default PwCheck;
