import React, { Dispatch, useContext, useState } from 'react';
import classNames from 'classnames';
import { Alert, Button, Popover, Radio, Switch } from 'common/components';
import { api, process } from 'common/functions';
import { ContentContext } from 'layout/admin/content/Content';
import styled, { css } from 'styled-components';
import ChipPlus from './color-chip/dynamic-chip/chip-plus/ChipPlus';
import FontCustomChip from './fontColor/customChip/CustomChip';
import ChipList from './color-chip/static-chip/ChipList';
import Preview from './color-chip/theme-preview/Preview';
import { Action, State } from '../ThemeSettingContent';
import Logo from './logo/Logo';
import './ThemeSetting.scss';

type Styles = {
  primaryColor: string;
};
const PreviewColor = styled.span<Styles>`
  display: inline-block;
  width: 80px;
  height: 32px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: 16px;
  ${({ primaryColor }) =>
    css`
      background-color: ${primaryColor};
    `}
`;

export interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const ThemeSetting = ({ state, setState, loadHandler }: Props) => {
  const { set } = Alert.Context();
  const { edit, data, defaultData } = state;
  const { setLoading } = useContext(ContentContext);
  const [previewColor, setPreviewColor] = useState(data.use_custom_theme ? data.theme_color.color : '#373eff');
  const [radio, setRadio] = useState<0 | 1>(data.use_custom_theme && data.theme_color.fontColor !== '' ? 1 : 0);

  const isDirty =
    data.theme_color.color !== defaultData.theme_color.color ||
    data.theme_color.fontColor !== defaultData.theme_color.fontColor ||
    data.custom_colors !== defaultData.custom_colors ||
    data.custom_logo !== defaultData.custom_logo ||
    data.use_custom_logo !== defaultData.use_custom_logo ||
    data.use_custom_theme !== defaultData.use_custom_theme;
  const customLogoUpload = data.custom_logo !== 'custom';

  const submitHandler = async () => {
    setState({ type: 'setEdit', payload: false });
    const theme_color = [data.theme_color.color.toString(), data.theme_color.fontColor.toString()].join(',');

    const params = {
      custom_colors: JSON.stringify(state.data['custom_colors'].map((el) => el.color)),
      custom_logo: state.data['custom_logo'].toString(),
      theme_color,
      use_custom_logo: state.data['use_custom_logo'].toString(),
      use_custom_theme: state.data['use_custom_theme'].toString(),
    };

    setLoading(true);
    process(
      api.reqData({ url: 'configs', data: params }),
      api.put.request,
      api.fullFilled(({ error }) => {
        setPreviewColor(data.use_custom_theme ? data.theme_color.color : '#373eff');
        setLoading(false);
        set({ success: '테마 설정이 완료되었습니다.' });
        loadHandler();
        if (!error) setState({ type: 'setDefaultData', payload: { ...state.data } });
      })
    );
  };

  const handleFontColorChange = (value: 0 | 1) => {
    setRadio(() => value);
    if (value === 0) {
      setState({ type: 'setFontColor', payload: { fontColor: '' } });
    } else {
      setState({ type: 'setFontColor', payload: { fontColor: data.theme_color.fontColor || '#ffffff' } });
    }
  };

  return (
    <div className={classNames('admin-manage-theme-setting', { edit })}>
      <div className="theme-title">
        <h4>테마 설정</h4>
        <div className="button-group">
          {!edit ? (
            <Button text="편집" onClick={() => setState({ type: 'setEdit', payload: true })} />
          ) : (
            <>
              <Button
                text="취소"
                onClick={() => {
                  setRadio(defaultData.use_custom_theme && defaultData.theme_color.fontColor !== '' ? 1 : 0);
                  setState({ type: 'setData', payload: defaultData });
                  setState({ type: 'setEdit', payload: false });
                }}
              />

              <Button text="완료" disabled={!isDirty || !customLogoUpload} onClick={submitHandler} />
            </>
          )}
        </div>
      </div>
      <article>
        <div className="use-custom-theme">
          <div>
            <h4>사용 여부</h4>
            <div>고객의 ekyc 화면에 아래 설정한 테마의 적용 여부를 결정합니다.</div>
          </div>
          <Switch
            className={classNames({ readonly: !edit })}
            disabled={!edit}
            value={data.use_custom_theme}
            onChange={(value) => setState({ type: 'setData', payload: { ...data, use_custom_theme: !!value } })}
          />
        </div>
      </article>
      <div>
        <section>
          <Logo edit={edit} datas={data} setState={setState} />
          <div id="admin-manage-theme-color">
            <h4>{!edit && '현재 '}색상</h4>
            {!edit && (
              <div>
                <PreviewColor primaryColor={previewColor} />
                <span className="color-code">{previewColor.toUpperCase()}</span>
              </div>
            )}
          </div>

          {edit && (
            <div className="theme-palette">
              <div className="palette-item">
                <h4>기본 색상</h4>
                <ChipList data={data} setState={setState} />
              </div>

              <div className="palette-item">
                <h4>
                  사용자 지정
                  <Popover
                    position="right center"
                    clickOutside
                    content={() => (
                      <div id="admin-theme-setting-popover-content" className="description">
                        사용자 지정 색상은 최대 5개까지 표시됩니다.
                      </div>
                    )}
                  >
                    <i className="info-icon" />
                  </Popover>
                </h4>
                <ChipPlus setState={setState} data={data} />
              </div>

              <div className="palette-item">
                <h4>글자 색상</h4>
                <Radio.Group value={radio} styles={{ fontSize: 16 }} onChange={handleFontColorChange}>
                  <Radio.Item text="기본 색상" value={0}>
                    <Popover
                      position="right center"
                      clickOutside
                      content={() => (
                        <div id="admin-theme-setting-font-color-popover-content" className="description">
                          컬러테마에서 설정하신 색상에 맞추어 자동으로 흰색 또는 검은색으로 적용됩니다.
                        </div>
                      )}
                    >
                      <i className="info-icon font" />
                    </Popover>
                  </Radio.Item>
                  <Radio.Item text="사용자 지정" value={1}>
                    {radio === 1 ? <FontCustomChip setState={setState} data={data} /> : <></>}
                  </Radio.Item>
                </Radio.Group>
              </div>
            </div>
          )}
        </section>
        <section>
          <div className={classNames('theme-controller', { active: edit })}>
            <div className="theme-preview">
              <Preview data={data} />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ThemeSetting;
