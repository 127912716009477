import classNames from 'classnames';
import { Controller, M_Select, M_Table, Pagination, Search, Select, Table } from 'common/components';
import { isNumber } from 'lodash';
import type * as M from 'models';
import React, { Dispatch } from 'react';
import './AdminTable.scss';
import type { Action, State } from './CtAdminTable';
import Active from './active/Active';
import Title from './controller/Title';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
  isLoadable: boolean;
}

const ReviewerTable: React.FC<Props> = ({ state, setState, loadHandler, isLoadable }): JSX.Element => {
  const { header, data, searchKey, pagination, customer_id } = state;

  const handlers = {
    refresh: loadHandler,
    order: (order: M_Table.sortPayload): void => setState({ type: 'order', payload: order }),
    search: (searchText: string) => setState({ type: 'searchText', payload: Symbol(searchText) }),
    searchKey: (payload: M_Select.item): void => setState({ type: 'searchKey', payload }),
    customerId: (selected: M_Select.item | null): void => setState({ type: 'customer_id', payload: selected }),
    pagination: (payload: M.Pagination): void => setState({ type: 'pagination', payload }),
  };

  return (
    <section id="root-admin-table" className={classNames({ active: customer_id !== null })}>
      <Controller title={<Title state={state} handlers={handlers} />}>
        <Select
          styles={{ optionsHeight: 'auto' }}
          onChange={handlers.searchKey}
          disabled={!isNumber(state.customer_id?.value)}
          selected={searchKey}
          options={[
            { title: '선택', value: 'all' },
            { title: '아이디', value: 'username' },
            { title: '이름', value: 'name' },
            { title: '전화번호', value: 'phone_number' },
            { title: '이메일', value: 'email' },
          ]}
        />
        <Search submitHandler={handlers.search} disabled={!isLoadable || searchKey.value === 'all'} />
      </Controller>
      <Table
        data={data}
        header={header}
        config={{
          order: { sort: state.order, onClick: handlers.order },
        }}
      >
        <Table.Head>{({ value }) => value.title}</Table.Head>
        <Table.Body>
          {({ index }) => {
            const rowData = data[index];
            return (
              <Table.Row key={rowData.id} styles={{ height: 72 }}>
                <Table.Tr index={index}>
                  {({ value, property }) => {
                    if (property === 'active') {
                      return (
                        <div className="root-page-admin-table-active">
                          <Active index={index} state={state} setState={setState} refresh={handlers.refresh} />
                        </div>
                      );
                    }
                    return value || '-';
                  }}
                </Table.Tr>
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
      <div className="table__context__page__gear">
        <Pagination disabled={!isLoadable} pagination={pagination} onChange={handlers.pagination} />
      </div>
    </section>
  );
};

export default ReviewerTable;
