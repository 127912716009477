import React, { Dispatch, useState } from 'react';
import { modal } from 'layout';
import { Alert, Input } from 'common/components';
import { api, process } from 'common/functions';
import { Action, State } from '../../CtSubscribeSetting';
import './AccountModal.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
  closeHandler: () => void;
}

const AccountModal: React.FC<Props> = ({ state, setState, loadHandler, closeHandler }) => {
  const { allow_change_account, max_change_account } = state.vas;
  const [count, setCount] = useState<number>(max_change_account);
  const { set } = Alert.Context();

  const submitHandler = () => {
    process(
      api.reqData({
        url: 'configs',
        data: { allow_change_account: String(!allow_change_account), max_change_account: String(count) },
      }),
      api.put.request,
      api.fullFilled(({ response }) => {
        loadHandler();
        closeHandler();
        set({ success: `계좌 변경이 ${!allow_change_account ? '설정' : '중지'}되었습니다.` });
        setState({
          type: 'allowChangeAccount',
          payload: { allow_change_account: !allow_change_account, max_change_account: count },
        });
        return response;
      })
    );
    closeHandler();
  };
  return (
    <div id="account__modal__wrapper">
      <modal.Container styles={{ width: 500 }}>
        <modal.Header align="center">계좌 변경</modal.Header>
        <modal.Content>
          <div className="status-container">
            <div className="status">{allow_change_account ? '사용중' : '사용 안 함'}</div>
            <div className="icon" />
            <div className="status ok">{allow_change_account ? '사용 안 함' : '사용'}</div>
          </div>
          <hr />
          <div className="detail">
            {!allow_change_account ? (
              <>
                1원 송금 시 계좌 변경이 가능한 횟수를 설정해 주세요.
                <br />
                최소 1회, 최대 99회까지 설정 가능합니다.
              </>
            ) : (
              <div style={{ marginBottom: -24 }}>1원 송금 시 계좌 변경 기능을 미사용하시겠습니까?</div>
            )}
          </div>
          {!allow_change_account && (
            <div className="count">
              <Input
                type="number"
                min="1"
                max="99"
                rules={[{ min: 1, max: 99 }]}
                value={count}
                onChange={(e) => setCount(Number(e.target.value))}
              />
              회
            </div>
          )}
        </modal.Content>
        <modal.Footer
          submitText={allow_change_account ? '사용 안 함' : '사용'}
          loading={false}
          submitHandler={submitHandler}
          closeHandler={closeHandler}
        />
      </modal.Container>
    </div>
  );
};

export default AccountModal;
