/* eslint-disable react/no-danger */
import React, { useEffect, useReducer, useContext } from 'react';
import { produce } from 'immer';
import { Button, Alert, TextArea, Modal } from 'common/components';
import { api, process } from 'common/functions';
import { NullishGuard } from 'hoc';
import { values } from 'lodash';
import { ContentContext } from 'layout/root/content/Content';
import type { Data as RowData } from '../CtPrivacyTable';
import ModalDeletePrivacy from './modal/Modal_DeletePrivacy';
import ModalModifyPrivacy from './modal/Modal_ModifyPrivacy';
import './Expand.scss';

export type Data = { title: string; content: string };
type Action = { type: 'setData'; payload: Data[] };
export interface State {
  data: Data[] | null;
}

const initialState: State = { data: null };
const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break;
    }
  });
};

interface Props {
  rowData: RowData;
  handlers: { refresh: () => void };
}

const Expand: React.FC<Props> = ({ rowData, handlers }) => {
  const { setLoading } = useContext(ContentContext);
  const { set } = Alert.Context();
  const [state, setState] = useReducer(reducer, initialState);
  const { data } = state;

  const getExpandData = async (type?: 'update') => {
    if (!type) setLoading(true);
    process(
      api.reqData({ url: `guides/${rowData.key}` }),
      api.get.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { detail } = response.data;
          const html = JSON.parse(decodeURIComponent(atob(detail.content)));
          setState({ type: 'setData', payload: values(html) });
        }
        if (!type) setLoading(false);
      })
    );
  };

  useEffect(() => {
    getExpandData();
  }, []);

  return (
    <NullishGuard data={[data]}>
      {([data]) => (
        <section className="root-guidance-privacy-table-tr-expand">
          <div id="guidance-privacy">
            <h3 className="term-subject">약관 내용</h3>
            {data.map((el) => {
              return (
                <React.Fragment key={el.title}>
                  <div className="term-content">
                    <div className="term-title">{el.title}</div>
                    <div className="inner-html" dangerouslySetInnerHTML={{ __html: el.content }} />
                  </div>
                </React.Fragment>
              );
            })}
            <h3 className="term-subject">약관 HTML</h3>
            {data.map((el) => {
              return (
                <React.Fragment key={el.title}>
                  <div className="term-content">
                    <div className="term-title">
                      {el.title} HTML
                      <Button
                        text="HTML 복사하기"
                        onClick={async () => {
                          try {
                            await navigator.clipboard.writeText(el.content);
                            set({ success: 'HTML 복사가 완료되었습니다.' });
                          } catch {
                            set({ fail: 'HTML 복사는 실패되었습니다.' });
                          }
                        }}
                      />
                    </div>
                    <TextArea
                      readonly
                      className="html-textarea"
                      value={el.content}
                      styles={{ width: '100%', height: 136 }}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div className="expand-utils">
            <Modal
              content={({ closeHandler }) => (
                <ModalDeletePrivacy closeHandler={closeHandler} rowData={rowData} refresh={handlers.refresh} />
              )}
            >
              <Button text="삭제" />
            </Modal>
            <Modal
              content={({ closeHandler }) => (
                <ModalModifyPrivacy
                  closeHandler={closeHandler}
                  data={data}
                  rowData={rowData}
                  getExpandData={getExpandData}
                />
              )}
            >
              <Button text="편집" />
            </Modal>
          </div>
        </section>
      )}
    </NullishGuard>
  );
};

export default Expand;
