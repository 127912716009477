/* eslint-disable import/extensions */
import React from 'react';
import { debounce } from 'lodash';
import { pipe, process } from './process';
import { api } from './api';

export { pipe, process, api };
export { margin, padding } from './style';
export * as time from './time';

interface props {
  target: HTMLElement | HTMLElement[] | null;
  toggle: boolean;
  close: () => void;
}
interface N_props extends props {
  target: HTMLElement | null;
}

const closeHandler = (
  setToggle: () => void,
  target: HTMLElement | HTMLElement[] | null,
  eTarget: EventTarget | null
): void => {
  if (target) {
    if (Array.isArray(target)) {
      for (const t of target as HTMLElement[]) if (t.contains(eTarget as Node)) return;
    } else if ((target as HTMLElement).contains(eTarget as Node)) {
      return;
    }
  }
  setToggle();
};

export const delayed = debounce(async (fn: () => void) => fn(), 500);

export const ClickOutsideEffect = ({ target, toggle, close }: props): void => {
  React.useEffect(() => {
    const clickOutsideHandler = (e: MouseEvent) => closeHandler(close, target, e.target);
    document.addEventListener('mousedown', clickOutsideHandler);
    return (): void => document.removeEventListener('mousedown', clickOutsideHandler);
  }, [target, close, toggle]);
};

export const MouseHoverEffect = ({ target, toggle, close }: N_props): void => {
  React.useEffect(() => {
    const mouseHoverHandler = (e: MouseEvent) => {
      console.log('mousehoverhandler', close, target, e.target);
      // closeHandler(close, target, e.target);
      close();
    };
    console.log('mousehovereffect effect', { target, close, toggle });
    if (target) target.addEventListener('mouseenter', mouseHoverHandler);
    return (): void => {
      if (target) target.removeEventListener('mouseenter', mouseHoverHandler);
    };
  }, [target, close, toggle]);
};

// 공용 api
export const findCustomer = (payload: { customer_name: string }): Promise<{ id: number; error: string }> => {
  return process(
    api.reqData({ url: 'find/customer', data: payload }),
    api.post.request,
    api.fullFilled(({ response }) => {
      const result = { id: -1, error: '' };
      if (response) {
        const { data } = response;
        // prettier-ignore
        switch(data.api_response.result_code) {
          case 'N100': result.id = data.customer_id; break;
          default: result.error = String.raw`'N100' 이외의 다른 성공 조건에 대한 처리 없음`
        }
      }
      return result;
    })
  );
};

// 이메일 중복 확인
export const checkEmail = (params: {
  customer_id: number;
  value: string;
}): Promise<{ message: string; check: boolean }> => {
  return process(
    api.reqData({ url: 'check/email', data: params }),
    api.post.request,
    api.fullFilled(({ response, error }) => {
      let result = { duplicate: false, message: '', checked: false };
      if (response) {
        const { result_code } = response.data.api_response;
        if (result_code === 'F606')
          result = { duplicate: true, message: '이미 사용 중인 이메일 입니다.', checked: false };
        if (result_code === 'N100') result = { duplicate: true, message: '사용 가능한 이메일입니다.', checked: true };
      }

      if (error) {
        const { result_code } = error.data.api_response;
        if (result_code === 'E605') result = { duplicate: true, message: '입력 형식이 올바르지 않습니다.', checked: false }; // prettier-ignore
        if (result_code === 'E531') result = {  duplicate: true, message: '이메일을 입력해 주세요.', checked: false }; // prettier-ignore
      }

      return result;
    })
  );
};

export const generatePassword = (params: { length: number; number?: boolean; symbol?: boolean }): string => {
  const { length, number, symbol } = params;
  const uppers = 'ABCDEFGHJKMNPQRSTUVWXYZ';
  const lowers = 'abcdefghjkmnpqrstuvwxyz';
  const numbers = '123456789';
  const specials = '!@#$%^&';

  const result = [];
  const idxLows = [];
  const idxNums = [];

  for (let i = 0; i < length; i += 1) {
    result[i] = uppers[Math.floor(Math.random() * uppers.length)];
  }
  for (let i = 0; i < length / 2; i += 1) {
    idxLows.push(Math.floor(Math.random() * length));
  }
  idxLows.forEach((item) => {
    result[item] = lowers[Math.floor(Math.random() * lowers.length)];
  });
  if (number) {
    for (let i = 0; i < length / 4; i += 1) {
      idxNums.push(Math.floor(Math.random() * length));
    }
    idxNums.forEach((item) => {
      result[item] = numbers[Math.floor(Math.random() * numbers.length)];
    });
  }
  if (symbol) result[Math.floor(Math.random() * length)] = specials[Math.floor(Math.random() * specials.length)];
  return result.join('');
};
