import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { Button, Switch, Modal } from 'common/components';
import { EddRa } from '../../../context/model.type';
import { useEddConext } from '../../../context/EddContext';
import { addRaData } from '../../settings/util';
import RiskItem from '../../settings/RiskItem';
import RiskAddItem from '../../settings/RiskAddItem';
import './CustomerRiskDetail.scss';

const RiskContent = styled.div`
  transition: max-height 0.3s ease, padding 0.3s ease;
  border-radius: 8px;
}}
`;

function CustomerRiskDetail() {
  const { edit, use_ra, state, setState, deleteHandler } = useEddConext();
  const { customer } = state.data;

  const addRaItem = (item: EddRa.Customer) => {
    setState({ type: 'customer', payload: [...customer, item] });
  };

  const updateRaItem = (item: EddRa.Customer) => {
    const nextData = customer.map((el) => (el.id === item.id ? item : el));
    setState({ type: 'customer', payload: nextData });
  };

  const removeRaItem = (item: EddRa.Customer) => {
    const removed = customer.filter((el) => el.id !== item.id);
    deleteHandler({ category: 'customer', id: item.id });
    setState({ type: 'customer', payload: removed });
  };

  const moveRaItem = (dragIndex: number, destIndex: number) => {
    const nextOrderedRa = update(customer, {
      $splice: [
        [dragIndex, 1],
        [destIndex, 0, customer[dragIndex]],
      ],
    });
    setState({ type: 'customer', payload: nextOrderedRa });
  };

  const totalScore = customer.reduce((acc, cur) => acc + cur.score, 0);
  const availableScore = 100 - totalScore;

  return (
    <article className="edd-setting-customer-risk-detail">
      <div className="title">
        <i onClick={() => setState({ type: 'detailPage', payload: 'none' })} />
        <h4>고객 위험</h4>
        <div className="score">
          {use_ra && (
            <>
              <div>
                <span className={classNames({ hide: !edit })}>부여 점수</span>
                <span>{totalScore}점</span>
              </div>
              <div>
                {edit && (
                  <>
                    <span>남은 점수</span>
                    <span className={classNames({ error: availableScore !== 0 })}>{availableScore}점</span>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <RiskContent>
        <div className="item-container">
          <DndProvider backend={HTML5Backend}>
            <div className="item-list">
              {customer.length &&
                customer.map((item, index) => {
                  return (
                    <React.Fragment key={item.id}>
                      <RiskItem
                        item={item}
                        index={index}
                        edit={edit}
                        active={item.active}
                        use_ra={use_ra}
                        updateRaItem={updateRaItem}
                        removeRaItem={removeRaItem}
                        moveRaItem={moveRaItem}
                        activeComp={
                          <Switch
                            value={item.active}
                            onChange={(value) => {
                              updateRaItem({ ...item, active: !!value, score: !value ? 0 : item.score });
                            }}
                          />
                        }
                      />
                    </React.Fragment>
                  );
                })}
            </div>
          </DndProvider>
          {edit && (
            <Modal
              content={({ closeHandler }) => (
                <RiskAddItem
                  item={addRaData(customer.length, 'customer')}
                  addRaItem={addRaItem}
                  raItemNames={customer.map((el) => el.name)}
                  closeHandler={closeHandler}
                />
              )}
            >
              <Button className="item-append" type="button">
                <>
                  <i />
                  항목 추가하기
                </>
              </Button>
            </Modal>
          )}
        </div>
      </RiskContent>
    </article>
  );
}

export default CustomerRiskDetail;
