import { produce } from 'immer';
import React, { useEffect, useReducer } from 'react';
import SubscribeTable from './SubscribeSetting';
import type { State as PropState, VAS } from '../SubscribeContent';

export type Data = {
  // info: Info;
  manual_review: boolean;
  vas: VAS;
};

export type Action =
  | { type: 'setData'; payload: Data }
  | { type: 'manual'; payload: boolean }
  | { type: 'message'; payload: string }
  | { type: 'useCustom'; payload: boolean }
  | { type: 'allowAutoApprove'; payload: boolean }
  | { type: 'useOcrDriverDate'; payload: boolean }
  | {
      type: 'allowChangeAccount';
      payload: {
        allow_change_account: boolean;
        max_change_account: number;
      };
    }
  | { type: 'put' }
  | { type: 'vas'; payload: AtLeastOne<VAS> }
  | { type: 'kDriver'; payload: boolean }
  | { type: 'disabled'; payload: { manual: boolean; custom: boolean } };

export interface State {
  manual_review: false;
  vas: VAS;
  defaultVas: VAS;
  loading: boolean;
  disabled: {
    manual: boolean;
    custom: boolean;
  };
  kDriver: boolean;
}

const initVas: VAS = {
  identification: false,
  email_notification: false,
  sms_notification: false,
  use_custom_end_message: false,
  custom_end_message: '',
  allow_auto_approve: true,
  /* 신분증 인증 */
  use_ocr_driver_date: true,
  trust_driver_date: true,
  use_ocr_driver_expired_date: true,
  trust_driver_expired_date: true,
  /* 계좌 인증 */
  max_change_account: 1,
  allow_change_account: true,
  allow_account_subcode: false,
  use_account_subcode: false,
  account_subcode_position: 'left',
  account_subcode: '',
};

const initialState: State = {
  vas: initVas,
  defaultVas: initVas,
  manual_review: false,
  loading: true,
  disabled: {
    manual: false,
    custom: true,
  },
  kDriver: false,
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'vas': draft['vas'] = {...state.vas, ...action.payload}; break;
      case 'put': draft['defaultVas'] = draft.vas; break; 
      case 'disabled': draft['disabled'] = {...action.payload}; break; 
      case 'kDriver': draft['kDriver'] = action.payload; break; 
      case 'message': draft['vas']['custom_end_message'] = action.payload; break;
      case 'useCustom': draft['vas']['use_custom_end_message'] = action.payload; break;
      case 'allowAutoApprove': draft['vas']['allow_auto_approve'] = action.payload; break;
      case 'useOcrDriverDate': draft['vas']['use_ocr_driver_date'] = action.payload; break;
      case 'allowChangeAccount': 
        draft['vas']['allow_change_account'] = action.payload.allow_change_account;
        draft['vas']['max_change_account'] = action.payload.max_change_account;
      break;
    }
  });
};

interface Props {
  state: PropState;
  loadHandler: () => void;
}
const CtSubscribeTable: React.FC<Props> = ({ state: propState, loadHandler }) => {
  const { vas, module, kDriver } = propState.data;
  const { disabled } = propState;

  const initialize = (init: State) => ({ ...init, vas, defaultVas: vas, disabled, kDriver });
  const [state, setState] = useReducer(reducer, initialState, initialize);

  useEffect(() => {
    setState({ type: 'vas', payload: vas });
    setState({ type: 'disabled', payload: disabled });
    setState({ type: 'kDriver', payload: kDriver });
    setState({ type: 'put' });
  }, [vas, disabled, kDriver]);

  return <SubscribeTable state={state} setState={setState} loadHandler={loadHandler} module={module} />;
};

export default CtSubscribeTable;
