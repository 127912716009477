import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api, process } from 'common/functions';
import { RootState } from 'store/reducers/admin/configStore';
import { Admin } from 'models';
import { NavigateFunction } from 'react-router-dom';

type State = Admin.Auth.state;
type Data = Admin.Auth.data;

const initialState: State = { data: null, my: null };

export const authSlice = createSlice({
  name: 'admin/auth/slice',
  initialState,
  reducers: {
    setData: (state, { payload }: PayloadAction<Data>) => {
      if (!state['data']) state['data'] = payload;
      localStorage.setItem('ADMIN_TOKEN', JSON.stringify(payload));
    },
    init: (state) => {
      state['data'] = null;
    },
    setUser: (state, { payload }) => {
      state['my'] = payload;
    },
    unMount: (state) => {
      state['data'] = null;
      state['my'] = null;
    },
  },
});

const my = createAsyncThunk('admin/auth/mypage', (_, { dispatch }) => {
  process(
    api.reqData({ url: 'mypage' }),
    api.get.request,
    api.fullFilled(({ response }) => {
      if (response) dispatch(authSlice.actions.setUser(response.data.account));
    })
  );
});

const logout = createAsyncThunk('admin/auth/logout', (payload: { navigate: NavigateFunction }, { dispatch }) => {
  localStorage.removeItem('ADMIN_TOKEN');
  payload.navigate('/admin');
  dispatch(authSlice.actions.unMount());
});

// 로그인 인증시 에러 메세지 케이스
const errorMessage = (response: { result_code: string; result_message: string }, pwCheck?: boolean) => {
  // prettier-ignore
  switch (response.result_code) {
    case 'F301': return pwCheck ? '비밀번호를 확인해 주세요.' : '아이디 혹은 비밀번호를 확인해 주세요.';
    case 'F302': return '비활성화된 아이디입니다.';
    case 'F304': return '비활성화된 아이디입니다.';
    case 'F303': return '비활성화된 고객사입니다.';
    case 'E580': return '데이터 베이스가 유효하지 않습니다.';
    default: return response.result_message === '접근 권한이 없음' ? '아이디 혹은 비밀번호를 확인해 주세요.' : response.result_message
  }
};

const pwCheck = createAsyncThunk('admin/auth/pwCheck', (password: string, { dispatch, getState }) => {
  if (!password) return '비밀번호를 입력하세요';
  const { auth } = getState() as RootState;

  if (auth && auth.data) {
    const { customer_id, username } = auth.data;
    return process(
      api.reqData({ url: 'sign-in', data: { customer_id, username, password } }),
      api.post.request,
      api.fullFilled(({ response, error }) => {
        if (response) dispatch(authSlice.actions.setData({ ...response.data, customer_id }));
        if (error) return errorMessage(error.data.api_response, true);
        return '';
      })
    );
  }
  return '사용자 정보가 존재하지 않습니다.';
});

const login = createAsyncThunk(
  'admin/auth/login',
  (payload: { customer_id: number; username: string; password: string; navigate: NavigateFunction }, { dispatch }) => {
    const { customer_id, username, password, navigate } = payload;
    if (!username) return '아이디를 입력하세요.';
    if (!password) return '비밀번호를 입력하세요.';
    return process(
      api.reqData({ url: 'sign-in', data: payload }),
      api.post.request,
      api.fullFilled(({ response, error }) => {
        if (response) {
          const { role, is_temp_password } = response.data;
          if (role !== 0) return '아이디 혹은 비밀번호를 확인해 주세요.';
          dispatch(authSlice.actions.setData({ ...response.data, customer_id: payload.customer_id }));
          if (is_temp_password) {
            navigate('/admin/temp-pass-modify/admin', { state: { customer_id, username, password } });
          } else navigate('/admin/reviewer-management');
        }
        if (error) return errorMessage(error.data.api_response);
        return '';
      })
    );
  }
);

export const authActions = { ...authSlice.actions, login, my, logout, pwCheck };
