import { useQuery } from '@tanstack/react-query';
import { getCustomerAdmins, getCustomers } from 'common/api';
import { KycLoading, M_Select, M_Table } from 'common/components';
import { api } from 'common/functions';
import { useApiCancel } from 'common/hooks';
import { produce } from 'immer';
import { isNumber } from 'lodash';
import type { Order, Pagination } from 'models';
import React, { useReducer } from 'react';
import AdminTable from './AdminTable';

const initPagination = { info: { current: 1, total: 10 }, format: { limit: 20, offset: 0 } };
export type Data = typeof getCustomerAdmins.data;

export type Action =
  | { type: 'order'; payload: Order }
  | { type: 'setData'; payload: Data[] }
  | { type: 'searchText'; payload: symbol }
  | { type: 'searchKey'; payload: M_Select.item }
  | { type: 'init'; payload?: string }
  | { type: 'pagination'; payload: Pagination }
  | { type: 'expand'; payload: number }
  | { type: 'customer_id'; payload: M_Select.item | null }
  | { type: 'customers'; payload: M_Select.item[] };

export interface State {
  data: Data[];
  header: M_Table.header[];
  searchKey: M_Select.item;
  searchText: symbol;
  order: Order;
  pagination: Pagination;
  customer_id: M_Select.item | null;
  customers: M_Select.item[];
}

const initialState: State = {
  data: [],
  searchKey: { title: '선택', value: 'all' },
  order: { id: 'DESC' },
  searchText: Symbol(''),
  pagination: { ...initPagination },
  customer_id: null,
  customers: [],
  header: [
    { title: '아이디', property: 'username', flex: 2, align: 'center', order: true },
    { title: '이름', property: 'name', flex: 2, align: 'center', order: true },
    { title: '전화번호', property: 'phone_number', flex: 2, align: 'center', order: true },
    { title: '이메일', property: 'email', flex: 2, align: 'center', order: true },
    { title: '활성여부', property: 'active', flex: 2 },
  ],
};

// prettier-ignore
const reducer = (state: State, action: Action): State => {
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break;
      case 'order': draft['order'] = action.payload; break;
      case 'searchText': 
        draft['searchText'] = action.payload; 
        draft.pagination.format['offset'] = 0;
        draft.pagination.info['current'] = 1;
        break;
      case 'searchKey': 
        draft['searchKey'] = action.payload; 
        if(action.payload.value==='all')draft['searchText'] =  Symbol('')
        draft.pagination.format['offset'] = 0;
        draft.pagination.info['current'] = 1;
        break;
      case 'customer_id': draft['customer_id'] = action.payload; break;
      case 'pagination': draft['pagination'] = action.payload; break;
      case 'customers': draft['customers'] = action.payload; break;
    }
  });
};

const CtAdminTable: React.FC = () => {
  const [state, setState] = useReducer(reducer, initialState);
  useApiCancel([`customers/${state.customer_id}/admins`]);

  const sort = api.order(state.order);
  const params = {
    start: state.pagination.format.offset,
    count: state.pagination.format.limit,
    keyword_field: state.searchKey.value,
    keyword: state.searchText.description?.trim(),
    order_field: sort.key,
    order: sort.value,
  };

  const isLoadable = isNumber(state.customer_id?.value);
  const { refetch, isFetching } = useQuery(
    [state.customer_id?.value, state.order, state.searchText, state.pagination.info.current, state.searchKey],
    () => getCustomerAdmins(state.customer_id?.value, params),
    {
      enabled: isLoadable,
      onSuccess: (response) => {
        setState({ type: 'setData', payload: response.data.list });
      },
    }
  );

  useQuery([], () => getCustomers(), {
    select: (response) => {
      return response.data.list.map(({ name, id }: typeof getCustomers.data) => ({
        title: name,
        value: id,
      }));
    },
    onSuccess: (payload) => {
      setState({ type: 'customers', payload });
    },
  });

  return (
    <>
      {isFetching && <KycLoading />}
      <AdminTable state={state} setState={setState} loadHandler={refetch} isLoadable={isLoadable} />
    </>
  );
};

export default CtAdminTable;
