import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { api, process } from 'common/functions';
import { M_Select } from 'common/components';
import { ContentContext } from 'layout/admin/content/Content';
import RaWeightProvider from '../context/WeightContext';
import Promotion from '../promotion/Promotion';
import Forbidden from '../forbidden/Forbidden';
import RealScoreContent from './RealScoreContent';
import './RealScoreContainer.scss';

type Data = {
  module: { edd_field: boolean };
  configs: {
    allow_ra: boolean;
    use_edd: boolean;
    use_ra: boolean;
    use_custom_field: boolean;
    edd_countries_mode: number;
  };
  template: M_Select.item;
};
export type State = {
  edit: boolean;
  data: Data;
  defaultData: Data;
};

export type Action =
  | { type: 'setEdit'; payload: boolean }
  | { type: 'setData'; payload: Partial<Data> }
  | { type: 'setDefaultData'; payload: Partial<Data> };

const initialData = {
  module: { edd_field: false },
  configs: { allow_ra: false, use_edd: false, use_ra: false, use_custom_field: false, edd_countries_mode: 1 },
  template: { title: '기본 템플릿 설정', value: 'default' },
};
const initialState: State = {
  edit: false,
  data: { ...initialData },
  defaultData: { ...initialData },
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'setEdit': draft['edit'] = action.payload; break;
      case 'setData': draft['data'] = { ...draft.data, ...action.payload }; break;
      case 'setDefaultData': draft['defaultData'] = { ...draft.defaultData, ...action.payload }; break;
    }
  });
};
const get = (url: string) => {
  return process(
    api.reqData({ url }),
    api.get.request,
    api.fullFilled(({ response }) => {
      if (response?.data.list) return response?.data.list;
      if (response?.data.module) return response?.data.module;
      return undefined;
    })
  );
};

type ConfigsKey = keyof Data['configs'];
type ResponseConfig = { key: ConfigsKey; value: boolean | number };
const parseConfigs = (list: ResponseConfig[]) => {
  return list.reduce((acc, cur) => {
    if (Object.keys(initialData.configs).includes(cur.key)) {
      return { ...acc, [cur.key]: cur.value };
    }
    return acc;
  }, initialData.configs);
};

function RealScoreContainer() {
  const { setLoading } = useContext(ContentContext);
  const [state, setState] = useReducer(reducer, initialState);
  const { module, configs } = state.defaultData;

  const loadHandler = async () => {
    setLoading(true);

    const [list, module] = await Promise.all([get('configs'), get('modules')]);

    const configs = parseConfigs(list);
    setState({ type: 'setData', payload: { module, configs } });
    setState({ type: 'setDefaultData', payload: { module, configs } });

    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <div id="admin-manage-ra-realscore-container">
      {module.edd_field && configs.allow_ra ? (
        <>
          <div className="header">
            <h3>위험별 점수 및 실제반영 점수</h3>
          </div>
          {configs.use_edd && configs.use_ra ? (
            <RaWeightProvider>
              <RealScoreContent edd_countries_mode={configs.edd_countries_mode} />
            </RaWeightProvider>
          ) : (
            <>
              <Forbidden
                content={
                  <>
                    <p>RA 기능을 활성화 하신 후</p>
                    <p>실제반영 점수를 확인하실 수 있습니다</p>
                  </>
                }
              />
            </>
          )}
        </>
      ) : (
        <Promotion />
      )}
    </div>
  );
}

export default RealScoreContainer;
