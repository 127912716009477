import React, { Dispatch } from 'react';
import './Manual.scss';
import { Button, Modal, Popover } from 'common/components';
import { Action, State } from '../CtSubscribeSetting';
import ManualModal from './modal/ManualModal';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const Manual = ({ state, setState, loadHandler }: Props) => {
  const { allow_auto_approve } = state.vas;
  return (
    <section id="subscribe-management-setting-manual">
      <div className="header">
        <div className="title">자동심사 여부</div>
        <Popover
          position="right center"
          clickOutside
          content={() => (
            <div id="admin-subscribe-manual-popover-content" className="description">
              자동심사를 사용하지 않으면 모든 고객 신원 확인심사는 수동으로 진행합니다.
            </div>
          )}
        >
          <i className="title-info" />
        </Popover>
      </div>
      <div className="content">
        <div className="label">{allow_auto_approve ? '사용 중' : '사용 안 함'} </div>
      </div>
      <div className="button-group">
        <Modal
          content={({ closeHandler }) => (
            <ManualModal state={state} setState={setState} loadHandler={loadHandler} closeHandler={closeHandler} />
          )}
        >
          <Button className="submit" text={allow_auto_approve ? '중지' : '사용'} />
        </Modal>
      </div>
      {state.disabled.manual && <div className="subscribe-setting-disabled" />}
    </section>
  );
};

export default Manual;
