import React, { useReducer } from 'react';
import { Input, Validator, TextArea, Alert } from 'common/components';
import { modal, Layer } from 'layout';
import { produce } from 'immer';
import classNames from 'classnames';
import { api, process } from 'common/functions';
import { excutions } from 'common/validations';
import './Modal_RegistPrivacy.scss';

type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'content'; payload: string }
  | { type: 'version'; payload: Partial<{ value: string; message: string; check: boolean }> };

interface State {
  loading: boolean;
  version: { value: string; message: string; check: boolean };
  content: string;
}

const initialState: State = {
  loading: false,
  version: { value: '', message: '', check: false },
  content: '',
};

const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch (action.type) {
      case 'loading': draft['loading'] = action.payload; break;
      case 'version': draft['version'] = { ...draft.version, ...action.payload }; break;
      case 'content': draft['content'] = action.payload ; break;
    }
  });
};

interface Props {
  closeHandler: () => void;
  refresh: () => void;
}

const ModalRegistPrivacy: React.FC<Props> = ({ closeHandler, refresh }) => {
  const { set } = Alert.Context();
  const [state, setState] = useReducer(reducer, initialState);

  // 버전 중복 확인
  const dupCheckHandler = () => {
    process(
      api.reqData({ url: 'notices/pp/check/version', data: { version: state.version.value } }),
      api.post.request,
      api.fullFilled(({ response, error }) => {
        if (response) {
          const { is_available } = response.data;
          let payload = { message: '', check: false };
          if (!is_available) payload = { message: '이미 등록된 버전입니다.', check: false };
          if (is_available) payload = { message: '등록 가능한 버전 입니다.', check: true };
          setState({ type: 'version', payload });
        }

        if (error) {
          const { result_code, result_message } = error.data.api_response;
          if (result_code === 'E535') setState({ type: 'version', payload: { message: result_message, check: false } }); // prettier-ignore
          if (result_code === 'E531') setState({ type: 'version', payload: { message: '버전을 입력하세요.', check: false } }); // prettier-ignore
        }
      })
    );
  };

  const submitHandler = async () => {
    // 버전 중복 체크
    if (!state.version.check) {
      setState({ type: 'version', payload: { message: '중복여부를 확인하세요.' } });
      return;
    }

    setState({ type: 'loading', payload: true });
    const params = { version: state.version.value, content: btoa(encodeURIComponent(state.content)) };
    process(
      api.reqData({ url: 'notices/pp', data: params }),
      api.post.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { result_code } = response.data.api_response;
          if (result_code === 'N100') {
            closeHandler();
            set({ success: '개인정보처리방침 개정이 완료되었습니다.' });
            refresh();
          }
        }
      })
    );
  };

  const 중복체크안함 = state.version.value && !state.version.check && !state.version.message;
  const 중복확인메세지 = state.version.message;
  const error = (중복확인메세지 && !state.version.check) || 중복체크안함;

  const noDotAtTheEnd = excutions.noDotAtTheEnd.regExp(state.version.value);
  const versionKey = excutions.versionKey.regExp(state.version.value);

  const orderedErrorMessage = () => {
    if (versionKey) return '영문 소문자, 숫자, 특수문자(.)만 사용 가능합니다.';
    if (noDotAtTheEnd) return '입력 형식이 올바르지 않습니다.';
    if (중복체크안함) return '중복여부를 확인하세요.';
    if (중복확인메세지) return 중복확인메세지;
    return '';
  };

  return (
    <Validator.Provider onSubmit={submitHandler}>
      <modal.Container id="root-term-privacy-regist" styles={{ width: 720 }}>
        <modal.Header>개인정보처리방침 개정</modal.Header>
        <modal.Content>
          <modal.Heading>버전</modal.Heading>
          <Validator.Provider onSubmit={dupCheckHandler}>
            <div className="duplicate-check">
              <Input
                placeholder="버전을 입력하세요"
                rules={[{ maxLength: 8 }]}
                maxLength={8}
                validType="realtime"
                className={classNames('basic', { error })}
                value={state.version.value}
                onChange={(e) => {
                  setState({ type: 'version', payload: { value: e.target.value, message: '', check: false } });
                }}
              />
              <Validator.Submit text="중복확인" />
              <div className={classNames('api-check-message', { error: !state.version.check })}>
                {orderedErrorMessage()}
              </div>
            </div>
          </Validator.Provider>

          <modal.Heading>약관 내용 (HTML)</modal.Heading>
          <Layer styles={{ marginTop: 8, marginBottom: 20 }}>
            <TextArea
              placeholder="약관 내용 (HTML)을 입력하세요."
              styles={{ width: '100%', height: 384 }}
              rules={['required']}
              value={state.content}
              onChange={(e) => setState({ type: 'content', payload: e.target.value })}
            />
          </Layer>
        </modal.Content>
        <modal.Footer
          submitText="등록"
          loading={state.loading}
          closeHandler={closeHandler}
          disabled={!state.version.check || !state.content}
        />
      </modal.Container>
    </Validator.Provider>
  );
};

export default ModalRegistPrivacy;
