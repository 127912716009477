import React, { Dispatch } from 'react';
import { Table, Pagination, Controller } from 'common/components';
import type * as M from 'models';
import { time } from 'common/functions';
import type { State, Action } from './CtSettingsTableTable';
import Sensitive from './sensitive/Sensitive';
import './SettingsTable.scss';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}

const SettingsTable: React.FC<Props> = ({ state, setState, loadHandler }): JSX.Element => {
  const { loading, header, data, pagination } = state;

  const handlers = {
    pagination: (payload: M.Pagination): void => setState({ type: 'pagination', payload }),
  };

  return (
    <section id="admin-settings-table">
      <Controller title="심사 설정" />
      <Sensitive loadHandler={loadHandler} />
      <Table loading={loading} data={data} header={header}>
        <Table.Head>{({ value }) => value.title}</Table.Head>
        <Table.Body>
          {({ index }) => {
            return (
              <Table.Row key={data[index].id} styles={{ height: 72 }}>
                <Table.Tr index={index}>
                  {({ value, property }) => {
                    if (property === 'request_time') {
                      const epoch = new Date(value).getTime();
                      return time.timeFormatFromUTCEpoch(epoch, 2);
                    }
                    if (property === 'level_after') return <span className="level_after">{value}</span>;
                    return value || '-';
                  }}
                </Table.Tr>
              </Table.Row>
            );
          }}
        </Table.Body>
      </Table>
      <div className="table__context__page__gear">
        <Pagination pagination={pagination} onChange={handlers.pagination} />
      </div>
    </section>
  );
};

export default SettingsTable;
