import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, Popover, Switch, Validator } from 'common/components';
import { ModalPortal } from 'common/components/module/lib/modal/ModalPortal';
import { useCustomContext } from '../context/CustomFieldContext';
import Modal_CustomFieldActive from './modal/Modal_CustomFieldActive';
import Header from './header/Header';
import CustomFieldDirect from './direct/Direct';
import './Custom.scss';

const Custom = () => {
  const { state, setState, configSubmitHandler, submitHandler } = useCustomContext();
  const { configs, defaultConfigs, data } = state;
  const [configEdit, setConfigEdit] = useState(false);
  const [edit, setEdit] = useState(false);
  const [toggle, setToggle] = useState(false);

  const onSubmit = () => {
    submitHandler();
    setEdit(false);
  };

  return (
    <>
      <section className="customfield-config-controller">
        <div className={classNames('controller', { active: configs.use_custom_field, edit: configEdit })}>
          <div>
            <h4>추가 설문 활성화</h4>
            <Popover
              position="right center"
              clickOutside
              content={() => (
                <div id="admin-manage-customfield-popover-content" className="description">
                  고객 신원 확인의 정확도를 높이기 위한 추가 절차이며 자금 출처, 주소, 증명서 등을 요청할 수 있습니다.
                </div>
              )}
            >
              <i className="title-info" />
            </Popover>
            <div>고객의 ekyc 인증 단계에 아래 설정한 항목의 적용 여부를 결정합니다.</div>
          </div>
          <Switch
            className={classNames({ readonly: !configEdit })}
            disabled={!configEdit}
            value={configs.use_custom_field}
            onChange={(value) => {
              setState({ type: 'configs', payload: { use_custom_field: !!value } });

              if (value && !data.customFields.filter((cf) => cf.active).length) {
                setToggle(true);
              }
            }}
          />
        </div>
        <div className="button-group">
          {configEdit ? (
            <>
              <Button
                text="취소"
                onClick={() => {
                  setState({ type: 'configs', payload: { use_custom_field: defaultConfigs.use_custom_field } });
                  setConfigEdit(false);
                }}
              />
              <Button
                text="저장"
                disabled={configs.use_custom_field === defaultConfigs.use_custom_field}
                onClick={() => {
                  configSubmitHandler();
                  setConfigEdit(false);
                }}
              />
            </>
          ) : (
            !edit && <Button text="편집" onClick={() => setConfigEdit(true)} />
          )}
        </div>
      </section>
      <section id="admin-manage-customfield-setting">
        <Validator.Provider onSubmit={onSubmit}>
          <Header configEdit={configEdit} edit={edit} setEdit={setEdit} />
          <div className={classNames('customfield-wrapper', { active: edit })}>
            <CustomFieldDirect edit={edit} />
          </div>
        </Validator.Provider>
      </section>
      {toggle && (
        <ModalPortal setToggle={() => setToggle(false)}>
          {({ closeHandler }) => (
            <Modal_CustomFieldActive
              closeHandler={() => {
                setState({ type: 'configs', payload: { use_custom_field: false } });
                closeHandler();
              }}
            />
          )}
        </ModalPortal>
      )}
    </>
  );
};

export default Custom;
