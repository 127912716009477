import React, { Dispatch } from 'react';
import { Action, State } from '../CtSubscribeSetting';
import Manual from '../manual/Manual';
import DriverIssueDate from '../driver/issueDate/DriverIssueDate';
import DriverExpiryDate from '../driver/expiryDate/DriverExpiryDate';

interface Props {
  state: State;
  setState: Dispatch<Action>;
  loadHandler: () => void;
}
const IdcardSetting = ({ state, setState, loadHandler }: Props) => {
  const { kDriver } = state;
  return (
    <>
      <h2 className="section-title">신분증 인증</h2>
      <div className="row">
        <Manual state={state} setState={setState} loadHandler={loadHandler} />
      </div>
      {kDriver && (
        <>
          <div className="row">
            <DriverIssueDate state={state} loadHandler={loadHandler} />
          </div>
          <div className="row">
            <DriverExpiryDate state={state} loadHandler={loadHandler} />
          </div>
        </>
      )}
    </>
  );
};

export default IdcardSetting;
