import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { ContentContext } from 'layout/admin/content/Content';
import { Alert } from 'common/components';
import { api, process } from 'common/functions';
import CriterionSetting from './settings/CriterionSetting';
import CriterionLogsTable from './logs/CriterionLogsTable';

type Data = {
  threshold: [number, number];
};
type RaThresholdLogsType = {
  id: number;
  admin_id: number;
  before: [number, number];
  after: [number, number];
  created_at?: string;
  user_id: string;
  user_name: string;
};
export type State = {
  edit: boolean;
  data: Data;
  defaultData: Data;
  thresholdLogs: RaThresholdLogsType[];
};

export type Action =
  | { type: 'edit'; payload: boolean }
  | { type: 'data'; payload: Data }
  | { type: 'defaultData'; payload: Data }
  | { type: 'thresholdLogs'; payload: RaThresholdLogsType[] };

const initialData: Data = {
  threshold: [30, 70],
};
const initialState: State = {
  edit: false,
  data: { ...initialData },
  defaultData: { ...initialData },
  thresholdLogs: [],
};

const reducer = (state: State, action: Action) => {
  return produce(state, (draft) => {
    // prettier-ignore
    switch (action.type) {
      case 'edit': draft['edit'] = action.payload; break;
      case 'data': draft['data'] = action.payload; break;
      case 'defaultData': draft['defaultData'] = action.payload; break;
      case 'thresholdLogs': draft['thresholdLogs'] = action.payload; break;
    }
  });
};

const get = (url: string) => {
  return process(
    api.reqData({ url }),
    api.get.request,
    api.fullFilled(({ response }) => {
      if (response?.data.value) return response?.data.value;
      if (response?.data.list) return response?.data.list;
      return undefined;
    })
  );
};

const put = (url: string, data: { value: [number, number] }) => {
  return process(api.reqData({ url, data }), api.put.request);
};

function CtCriterionContent() {
  const { setLoading } = useContext(ContentContext);
  const { set } = Alert.Context();

  const [state, setState] = useReducer(reducer, initialState);

  const loadHandler = async () => {
    setLoading(true);

    const [threshold, thresholdLogs] = await Promise.all([
      get('ra_threshold'),
      get('ra_threshold/logs?start=0&count=99'),
    ]);

    setState({ type: 'data', payload: { threshold } });
    setState({ type: 'defaultData', payload: { threshold } });
    setState({ type: 'thresholdLogs', payload: thresholdLogs });

    setLoading(false);
  };

  useEffect(() => {
    loadHandler();
  }, []);

  const submitHandler = async () => {
    setLoading(true);

    await put('ra_threshold', { value: state.data.threshold });

    set({ success: '평가 기준이 적용되었습니다.' });
    loadHandler();
    setLoading(false);
  };

  return (
    <>
      <CriterionSetting state={state} setState={setState} submitHandler={submitHandler} />
      <CriterionLogsTable state={state} />
    </>
  );
}

export default CtCriterionContent;
