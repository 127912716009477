import React from 'react';
import { RaResult as IRaResult } from '../../../Expand';

function RaResult({ ra_result, isExpired }: { ra_result: IRaResult; isExpired: boolean }) {
  const { ra_grade, ra_score, customer_score, svc_score, country_score, } = ra_result; // prettier-ignore

  const raGradeMap = { low: '저위험', middle: '중위험', high: '고위험' } as const;

  return (
    <article className="reviewer-result-ra-result">
      <h4>RA (고객 위험도 평가)</h4>
      <ul className="info-section">
        <li>
          <h4>위험 평가 결과</h4>
          <span>{isExpired ? '만료됨' : `${ra_score}점`}</span>
          <span>{isExpired ? '만료됨' : raGradeMap[ra_grade]}</span>
        </li>
        <li>
          <h4>고객 위험</h4>
          <span>{isExpired ? '만료됨' : `${customer_score}점`}</span>
        </li>
        <li>
          <h4>상품 및 서비스 위험</h4>
          <span>{isExpired ? '만료됨' : `${svc_score}점`}</span>
        </li>
        <li>
          <h4>국가 위험</h4>
          <span>{isExpired ? '만료됨' : `${country_score}점`}</span>
        </li>
      </ul>
    </article>
  );
}

export default RaResult;
