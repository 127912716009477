import React, { useReducer } from 'react';
import { Input, Validator, TextArea, Alert } from 'common/components';
import { modal, Layer } from 'layout';
import { produce } from 'immer';
import { api, process } from 'common/functions';
import classNames from 'classnames';
import { excutions } from 'common/validations';
import './Modal_RegistPrivacy.scss';

type Data = { title: string; content: string };
type Action =
  | { type: 'loading'; payload: boolean }
  | { type: 'key'; payload: Partial<{ value: string; message: string; check: boolean }> }
  | { type: 'add' }
  | { type: 'remove'; payload: number }
  | { type: 'title'; payload: { index: number; value: string } }
  | { type: 'content'; payload: { index: number; value: string } };

interface State {
  loading: boolean;
  key: { value: string; message: string; check: boolean };
  data: Data[];
}

const initialState: State = {
  loading: false,
  key: { value: '', message: '', check: false },
  data: [{ title: '', content: '' }],
};

const reducer = (state: State, action: Action) => {
  // prettier-ignore
  return produce(state, (draft) => {
    switch (action.type) {
      case 'loading': draft['loading'] = action.payload; break;
      case 'key': draft['key'] = { ...draft.key, ...action.payload }; break;
      case 'add': draft['data'].push({title: '', content: ''}); break;
      case 'remove': draft['data'].splice(action.payload, 1); break;
      case 'title': draft.data[action.payload.index]['title'] = action.payload.value; break;
      case 'content': draft.data[action.payload.index]['content'] = action.payload.value; break;
    }
  });
};

interface Props {
  closeHandler: () => void;
  refresh: () => void;
}

const ModalRegistPrivacy: React.FC<Props> = ({ closeHandler, refresh }) => {
  const { set } = Alert.Context();
  const [state, setState] = useReducer(reducer, initialState);
  const { data } = state;

  // key 중복 확인
  const dupCheckHandler = () => {
    if (!state.key.value.includes('guide-pa')) {
      setState({
        type: 'key',
        payload: { message: "입력값에 'guide-pa' 가 포함되어야 합니다.", check: false },
      });
      return;
    }

    process(
      api.reqData({ url: 'guides/check/key', data: { key: state.key.value } }),
      api.post.request,
      api.fullFilled(({ response, error }) => {
        if (response) {
          const { is_available } = response.data;
          let payload = { message: '', check: false };
          if (!is_available) payload = { message: '이미 등록된 약관 Key 입니다.', check: false };
          if (is_available) payload = { message: '등록 가능한 약관 Key 입니다.', check: true };
          setState({ type: 'key', payload });
        }

        if (error) {
          const { result_code } = error.data.api_response;
          if (result_code === 'E531') setState({ type: 'key', payload: { message: '약관 Key를 입력하세요', check: false } }); // prettier-ignore
        }
      })
    );
  };

  const submitHandler = async () => {
    // 버전 중복 체크
    if (!state.key.check) {
      setState({ type: 'key', payload: { message: '중복여부를 확인하세요.' } });
      return;
    }

    setState({ type: 'loading', payload: true });
    const params = { key: state.key.value, content: btoa(encodeURIComponent(JSON.stringify(data))) };
    process(
      api.reqData({ url: 'guides', data: params }),
      api.post.request,
      api.fullFilled(({ response }) => {
        if (response) {
          const { result_code } = response.data.api_response;
          if (result_code === 'N100') {
            closeHandler();
            set({ success: '약관 가이드 등록이 완료되었습니다.' });
            refresh();
          }
        }
      })
    );
  };

  const isEmpty = data.some((el) => !el.title.length || !el.content.length);
  const 중복체크안함 = state.key.value && !state.key.check && !state.key.message;
  const 중복확인메세지 = state.key.message;
  const error = (중복확인메세지 && !state.key.check) || 중복체크안함;

  const termsKey = excutions.termsKey.regExp(state.key.value);
  const orderedErrorMessage = () => {
    if (termsKey) return '영문 소문자, 숫자, 특수문자(-)만 사용 가능합니다.';
    if (중복체크안함) return '중복여부를 확인하세요.';
    if (중복확인메세지) return 중복확인메세지;
    return '';
  };

  return (
    <Validator.Provider onSubmit={submitHandler}>
      <modal.Container id="root-guidance-privacy-regist" styles={{ width: 720, maxHeight: 600 }}>
        <modal.Header>약관 등록</modal.Header>
        <modal.Content>
          <modal.Heading>약관 Key</modal.Heading>
          <Validator.Provider onSubmit={dupCheckHandler}>
            <div className="duplicate-check">
              <Input
                placeholder="약관 Key를 입력하세요"
                rules={[{ maxLength: 50 }]}
                validType="realtime"
                maxLength={50}
                className={classNames('basic', { error })}
                value={state.key.value}
                onChange={(e) => {
                  setState({ type: 'key', payload: { value: e.target.value, message: '', check: false } });
                }}
              />
              <Validator.Submit text="중복확인" />
              <div className={classNames('api-check-message', { error: !state.key.check })}>
                {orderedErrorMessage()}
              </div>
            </div>
          </Validator.Provider>

          <modal.Heading>약관 내용 (HTML)</modal.Heading>
          {data.map((el, i) => {
            const showAdd = data.length - 1 === i;
            const showRemove = data.length > 1;
            const multiple = showAdd && showRemove;
            return (
              <Layer key={i} styles={{ marginTop: 8, marginBottom: 20 }} className="terms-layer">
                <div className="content">
                  <Input
                    placeholder="제목을 입력하세요."
                    className="basic"
                    validType="realtime"
                    rules={['required', { maxLength: 100 }]}
                    value={el.title}
                    styles={{ width: '100%' }}
                    onChange={(e) => setState({ type: 'title', payload: { index: i, value: e.target.value } })}
                  />
                  <TextArea
                    placeholder="약관 내용은 HTML 형태로 입력하세요."
                    styles={{ width: '100%', height: 274 }}
                    value={el.content}
                    rules={['required']}
                    onChange={(e) => setState({ type: 'content', payload: { index: i, value: e.target.value } })}
                  />
                </div>
                <div className="utils">
                  {showAdd && (
                    <i className={classNames('add', { multiple })} onClick={() => setState({ type: 'add' })} />
                  )}
                  {showRemove && (
                    <i
                      className={classNames('remove', { multiple })}
                      onClick={() => setState({ type: 'remove', payload: i })}
                    />
                  )}
                </div>
              </Layer>
            );
          })}
        </modal.Content>
        <modal.Footer
          submitText="완료"
          loading={state.loading}
          closeHandler={closeHandler}
          disabled={!state.key.check || isEmpty}
        />
      </modal.Container>
    </Validator.Provider>
  );
};

export default ModalRegistPrivacy;
