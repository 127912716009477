import { configureStore, combineReducers } from '@reduxjs/toolkit';
import * as model from './index';

const reducer = combineReducers({
  auth: model.authSlice.reducer,
});

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
