import React, { useState } from 'react';
import classNames from 'classnames';
import { Accordion, Button, Input, Modal } from 'common/components';
import { EddRa } from '../../../context/model.type';
import Modal_RaNation from '../modal/Modal_RaNation';

interface Props {
  item: EddRa.Country[];
  use_ra: boolean;
  edd_country_mode: number;
  edit: boolean;
  updateRaItem: (item: EddRa.Country[]) => void;
}
function NationRiskItem(props: Props) {
  const { item, use_ra, edd_country_mode, edit, updateRaItem } = props;
  const [expand, setExpand] = useState(false);

  const handleScoreChange = (nation: EddRa.Country) => {
    const nextItem = item.map((el) => (el.id === nation.id ? nation : el));
    updateRaItem(nextItem);
  };

  const submitHandler = (nations: EddRa.Country[]) => {
    updateRaItem(nations);
  };

  return (
    <div className={classNames('accordion__section', { expand })}>
      <Accordion
        active={expand}
        onClick={() => setExpand((prev) => !prev)}
        title={
          <>
            <div className="item__title">
              <div>
                <b>국적</b>
              </div>
              <div />
              <div className={classNames('max__score', { hide: !use_ra })}>
                <span>최대 점수</span>
                <div>100점</div>
              </div>
            </div>
          </>
        }
      >
        <div className="item__subtree header">
          <span>선택지</span>
        </div>
        <div className="item__subtree header">
          <span>국가명</span>
          <span className={classNames({ hide: !use_ra })}>점수</span>
        </div>
        {item.map((nation) => {
          const { id, iso_code3, name_kr, score, warn_labels } = nation;

          return (
            <div className="item__subtree" key={`${id}_${iso_code3}`}>
              <div className="info">
                <span className="name">{name_kr}</span>
                <div className="warn__labels">
                  {warn_labels.map((label, index) => (
                    <span key={`${label}_${index}`}>{label}</span>
                  ))}
                </div>
              </div>
              <span className={classNames('score', { hide: !use_ra })}>
                {edit ? (
                  <>
                    <Input
                      type="text"
                      className="basic"
                      styles={{ width: 60 }}
                      value={score}
                      onChange={(e) => {
                        const { value } = e.target;
                        let numValue = Number(value.replace(/[^\d]/g, ''));
                        numValue = numValue > 100 ? 100 : numValue;
                        handleScoreChange({ ...nation, score: numValue });
                      }}
                    />
                    점
                  </>
                ) : (
                  <>{score}점</>
                )}
              </span>
            </div>
          );
        })}
      </Accordion>
      {edd_country_mode === 2 && edit && expand ? (
        <div className="item__subtree footer">
          <Modal
            content={({ closeHandler }) => (
              <Modal_RaNation
                closeHandler={closeHandler}
                submitHandler={submitHandler}
                eddCategory="country"
                item={item}
              />
            )}
          >
            <Button type="button" text="선택지 설정" />
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default NationRiskItem;
