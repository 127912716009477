import { useQuery } from '@tanstack/react-query';
import { getCustomers } from 'common/api';
import { KycLoading, M_Select, M_Table } from 'common/components';
import { useApiCancel } from 'common/hooks';
import { produce } from 'immer';
import type { Pagination } from 'models';
import React, { useReducer } from 'react';
import ClientTable from './ClientTable';

const initPagination = { info: { current: 1, total: 10 }, format: { limit: 20, offset: 0 } };
export type Data = typeof getCustomers.data;

export type Action =
  | { type: 'setData'; payload: Data[] }
  | { type: 'searchKey'; payload: M_Select.item }
  | { type: 'searchText'; payload: symbol }
  | { type: 'init'; payload?: string }
  | { type: 'pagination'; payload: Pagination }
  | { type: 'expand'; payload: number };

export interface State {
  data: Data[];
  header: M_Table.header[];
  searchKey: M_Select.item;
  searchText: symbol;
  pagination: Pagination;
}

const initialState: State = {
  data: [],
  searchKey: { title: '선택', value: 'all' },
  searchText: Symbol(''),
  pagination: { ...initPagination },
  header: [
    { title: '아이디', property: 'id', align: 'center' },
    { title: '고객사명', property: 'name', flex: 2, align: 'center' },
    { title: '전화번호', property: 'phone_number', align: 'center' },
    { title: '구분', property: 'industry', align: 'center' },
    { title: '구매형태', property: 'purchase_type', align: 'center' },
    { title: '활성여부', property: 'active', align: 'center' },
    { title: '상세보기', property: 'expand', align: 'center' },
  ],
};

const init = (draft: State, value?: string) => {
  if (value !== undefined) draft['searchText'] = Symbol('');
};

// prettier-ignore
const reducer = (state: State, action: Action): State => {
  return produce(state, (draft) => {
    switch(action.type) {
      case 'setData': draft['data'] = action.payload; break
      case 'searchKey': 
        draft['searchKey'] = action.payload;
        if(action.payload.value === 'all') draft['searchText'] = Symbol('')
        draft.pagination.format['offset'] = 0;
        draft.pagination.info['current'] = 1;
        break;
      case 'searchText': 
        draft['searchText'] = action.payload; 
        draft.pagination.format['offset'] = 0;
        draft.pagination.info['current'] = 1;
        break;
      case 'init': init(draft, action.payload); break;
      case 'expand': draft.data[action.payload]['expand'] = !draft.data[action.payload].expand; break;
      case 'pagination': draft['pagination'] = action.payload; break;
    }
  });
};

const CtClientTable: React.FC = () => {
  useApiCancel(['customers']);
  const [state, setState] = useReducer(reducer, initialState);

  const params = {
    start: state.pagination.format.offset,
    count: state.pagination.format.limit,
    [state.searchKey.value]: state.searchText.description?.trim(),
  };

  const { refetch, isLoading } = useQuery(
    [state.searchText, state.pagination.info.current, state.searchKey],
    () => getCustomers(params),
    {
      select: (response) => response.data.list,
      onSuccess: (list) => {
        const payload = list.map((el: Omit<Data, 'expand'>) => ({ ...el, expand: false }));
        setState({ type: 'setData', payload });
      },
    }
  );

  return (
    <>
      {isLoading && <KycLoading />}
      <ClientTable state={state} setState={setState} loadHandler={refetch} />
    </>
  );
};

export default CtClientTable;
