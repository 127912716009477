import classNames from 'classnames';
import React from 'react';
import type { Account as IACcount, Histories } from '../../Expand';
import './Account.scss';

interface Props {
  content: IACcount;
  histories: Histories;
  isExpired: boolean;
}

const Account: React.FC<Props> = ({ content, histories, isExpired }) => {
  const { verified, mod_account_holder, account_holder, finance_company, account_number } = content;
  const { accounts } = histories;
  return (
    <div id="reviewer-review-table-expand-content-account">
      <ul className="info-section">
        <li>
          <h4>1원 계좌 인증</h4>
          <p className={classNames({ warning: !verified })}>{verified ? '확인' : '실패'}</p>
        </li>
        <li>
          <h4>예금주 수정 여부</h4>
          <p className={classNames({ warning: mod_account_holder })}>{mod_account_holder ? 'YES' : 'NO'}</p>
        </li>
        {isExpired ? (
          <li>
            <p className="id-expired">저장 기간 만료로 인해 개인 정보가 삭제되었습니다.</p>
          </li>
        ) : (
          <>
            <li>
              <h4>예금주명</h4>
              <p className={classNames({ warning: mod_account_holder })}>{mod_account_holder || account_holder}</p>
            </li>
            <li>
              <h4>고객명</h4>
              <p>{account_holder || '디폴트'}</p>
            </li>
            <li>
              <h4>금융사명</h4>
              <p>{finance_company || '디폴트'}</p>
            </li>
            <li>
              <h4>계좌번호</h4>
              <p>{account_number || '110-454-012345'}</p>
            </li>
          </>
        )}
        <li>
          <h4>계좌 변경 여부</h4>
          <p className={classNames({ warning: accounts.length })}>
            {accounts.length ? `YES (${accounts.length}회)` : 'NO'}
          </p>
        </li>
      </ul>

      <article />
    </div>
  );
};

export default Account;
