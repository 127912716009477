import React from 'react';
import classNames from 'classnames';
import { useEddConext } from '../../../context/EddContext';
import { checkActiveRaScore } from '../util';
import './CustomerRisk.scss';

interface Props {
  use_ra: boolean;
}
function CustomerRisk({ use_ra }: Props) {
  const { edit, state, setState } = useEddConext();
  const { customer } = state.data;

  const totalScore = customer.reduce((acc, cur) => acc + cur.score, 0);
  const availableScore = 100 - totalScore;

  const checkScore = checkActiveRaScore(customer);

  const error = use_ra && (availableScore !== 0 || !checkScore);

  return (
    <article className={classNames('edd-setting-customer-risk', { error })}>
      <div className="title" onClick={() => setState({ type: 'detailPage', payload: 'customer' })}>
        <h4>고객 위험</h4>
        <div className="score">
          {use_ra && (
            <>
              <div>
                <span className={classNames({ hide: !edit })}>부여 점수</span>
                <span>{totalScore}점</span>
              </div>
              <div>
                {edit && (
                  <>
                    <span>남은 점수</span>
                    <span className={classNames({ error: availableScore !== 0 })}>{availableScore}점</span>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <i />
      </div>
    </article>
  );
}

export default CustomerRisk;
