import React, { useState } from 'react';
import { modal } from 'layout';
import { Alert, Radio } from 'common/components';
import { api, process } from 'common/functions';
import { State } from '../../../CtSubscribeSetting';
import './Modal_DriverIssueDate.scss';

interface Props {
  state: State;
  loadHandler: () => void;
  closeHandler: () => void;
  mode: string;
}

const ModalDriverIssueDate: React.FC<Props> = ({ state, loadHandler, closeHandler, mode }) => {
  const { use_ocr_driver_date, trust_driver_date } = state.vas;
  const { set } = Alert.Context();
  const [step, setStep] = useState(mode === 'edit' ? 2 : 1);
  const [loading, setLoading] = useState(false);
  const [trustDriverDate, setTrustDriverDate] = useState(trust_driver_date);

  const submitHandler = () => {
    const data = {
      use_ocr_driver_date: String(!use_ocr_driver_date),
      trust_driver_date: String(mode === 'stop' ? true : trustDriverDate),
    };
    setLoading(true);
    process(
      api.reqData({ url: 'configs', data }),
      api.put.request,
      api.fullFilled(() => {
        loadHandler();
        closeHandler();
        set({ success: `운전면허증 발급일자가 ${!use_ocr_driver_date ? '중지' : '설정'}되었습니다.` });
      })
    );
  };

  const editHandler = () => {
    setLoading(true);
    process(
      api.reqData({ url: 'configs', data: { trust_driver_date: String(trustDriverDate) } }),
      api.put.request,
      api.fullFilled(() => {
        loadHandler();
        closeHandler();
        set({ success: `운전면허증 발급일자가 설정 되었습니다.` });
      })
    );
  };

  return (
    <div id="idcarddriver__modal__wrapper">
      <modal.Container styles={{ width: step === 2 ? 650 : 500 }}>
        {step === 1 && (
          <>
            <modal.Header align="center">운전면허 발급일자</modal.Header>
            <modal.Content>
              <div className="status-container">
                <div className="status">{use_ocr_driver_date ? '사용 안 함' : '사용중'}</div>
                <div className="icon" />
                <div className="status ok">{use_ocr_driver_date ? '사용' : '사용 안 함'}</div>
              </div>
              <hr />
              <div className="detail">
                운전면허증 발급일자를 {use_ocr_driver_date ? '사용' : '미사용'}하시겠습니까?
                <br />
                사용 여부를 전환할 경우 서비스에 바로 반영됩니다.
              </div>
            </modal.Content>
            <modal.Footer
              submitText={use_ocr_driver_date ? '사용' : '사용 안 함'}
              loading={loading}
              submitHandler={() => {
                use_ocr_driver_date ? setStep(2) : submitHandler();
              }}
              closeHandler={closeHandler}
            />
          </>
        )}

        {step === 2 && (
          <>
            <modal.Header align="center">심사 방법 선택</modal.Header>
            <div>
              <b>운전면허증 발급일자 사용</b>시, 심사 방식을 상세하게 설정할 수 있습니다.
            </div>
            <modal.Content>
              <div className="status-container step2">
                <Radio.Group className="asdf" value={trustDriverDate} onChange={(value) => setTrustDriverDate(value)}>
                  <Radio.Item text="자동심사로 사용하기" value>
                    <div>
                      <p>
                        OCR결과를 무시하고 <b>사용자 입력 값으로 발급일자를 변경</b>합니다.
                      </p>
                      <p>
                        이후 <b>*기본 프로세스</b>로 동작합니다.
                      </p>
                    </div>
                  </Radio.Item>
                  <Radio.Item text="수동심사로 사용하기" value={false}>
                    <div className="flex-container">
                      <div className="flex-item">
                        <div>
                          OCR결과와 사용자 입력값이 <b>다른</b> 경우
                        </div>
                        <div>
                          <b>수동 심사</b>
                        </div>
                      </div>
                      <div className="flex-item">
                        <div>
                          OCR결과와 사용자 입력값이 <b>같은</b> 경우
                        </div>
                        <div>
                          <b>*기본 프로세스</b>
                        </div>
                      </div>
                    </div>
                  </Radio.Item>
                </Radio.Group>

                <div className="description">
                  <div>
                    <h4>*기본 프로세스</h4>
                  </div>
                  <div className="grid-container">
                    <div className="grid-item header">
                      진위확인 <b>성공</b>
                    </div>
                    <div className="grid-item">
                      신분증 정보 <b>수정 없음</b>
                    </div>
                    <div className="grid-item success">자동 승인</div>
                    <div className="grid-item">
                      신분증 정보 <b>수정 있음</b>
                    </div>
                    <div className="grid-item">
                      <b>수동 심사 대상</b>
                    </div>
                    <div className="grid-item header">
                      진위확인 <b>3회 이상 실패</b>
                    </div>
                    <div className="grid-item failed">자동 거부</div>
                  </div>
                  <div>* 신분증 정보 (이름, 주민등록번호, 운전면허번호)</div>
                  <p>※ 운전면허 발급일자는 정부기관(경찰청) 진위확인에 사용되지 않습니다.</p>
                </div>
              </div>
            </modal.Content>
            <modal.Footer
              submitText="확인"
              loading={loading}
              submitHandler={mode === 'edit' ? editHandler : submitHandler}
              closeHandler={closeHandler}
            />
          </>
        )}
      </modal.Container>
    </div>
  );
};

export default ModalDriverIssueDate;
